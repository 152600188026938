import { api, reducerUtil, history } from 'base-client';

import errorsActions from 'components/Errors/actions';

import detailsData from '../reducerData';
import uuid from 'uuid/v4';
import _ from 'lodash';

import analyticsActions from '../../Analytics/actions';
import productsSearchActions from 'components/ProductSearch/actions';
import { projectSubmittal } from 'components/Notifications/constants';
import notificationActions from 'components/Notifications/actions';

import projectDetailData from '../reducerData';
import searchData from 'components/ProductSearch/reducerData';
import { sortList } from '../utils';

import { searchActions } from 'shared-features-client';

const loadSavedProducts = ({ projectId, restart }) => async (dispatch, getState) => {
  if (!projectId) return;

  let state = getState();

  // get the page information
  const { page: currentPage = 0 } =
    reducerUtil.getSlice(projectDetailData, projectDetailData.pagination, state) || {};
  const page = restart ? 1 : currentPage + 1;
  const limit = 100;

  // get the querystring
  const {
    location: { search: queryJson }
  } = history;

  const query = queryJson ? JSON.parse(decodeURIComponent(trimQuery(queryJson))) : {};
  const defaultSortQuery = { sortBy: sortList[0].sortBy, sortDir: sortList[0].sortDir };

  const apiParams = {
    ...defaultSortQuery,
    ...query
  };

  const fetchId = uuid();
  dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.fetchId, fetchId));

  try {
    const apiEndpoint = `projects/${projectId}/products?order[${apiParams.sortBy}]=${apiParams.sortDir}&page=${page}&limit=${limit}`;

    const { projectProducts: products = [], pagination, order } = await dispatch(
      api.actions.get(apiEndpoint, true)
    );

    // check that this is the correct fetch
    state = getState();
    if (fetchId !== reducerUtil.getSlice(projectDetailData, projectDetailData.fetchId, state))
      return;
    // get current product info if not restarting
    const currentList =
      (!restart && reducerUtil.getSlice(projectDetailData, projectDetailData.products, state)) ||
      [];

    //get tenant name from theme.
    const deletedText = 'This product\'s manufacturer has removed this product listing.';

    //check if the product has been deleted then add text to describe it.
    const brandedSites = await dispatch(api.actions.get('brandedSites/?limit=500'));
    const brandedSitesMap = _.keyBy(brandedSites, 'tenant_id');
    dispatch(reducerUtil.setSlice(searchData, searchData.brandedSites, brandedSitesMap));

    const filteredList = products
      .map(({ product = {}, product_id: id, name }) => {
        if (product && product.manufacturer_id) {
          return { ...product };
        } else {
          return {
            id,
            name,
            description: deletedText,
            isDeleted: true
          };
        }
      })
      .filter(product => !!brandedSitesMap[product.manufacturer_id]);
    // if products is using in product details page, call formatProducts function to get callouts/assets
    const formattedProducts = dispatch(
      productsSearchActions.formatProducts(filteredList, brandedSitesMap)
    );
    dispatch(
      reducerUtil.setSlice(projectDetailData, projectDetailData.products, [
        ...currentList,
        ...formattedProducts
      ])
    );

    dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.pagination, pagination));
    // update the search parameters
    dispatch(
      reducerUtil.setSlice(projectDetailData, projectDetailData.order, {
        sortBy: order[0][0],
        sortDir: order[0][1]
      })
    );
    // allow another search
    dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.fetchId, undefined));
  } catch (error) {
    dispatch(errorsActions.error(error));
  }
};

const loadNextPage = ({ projectId, forSubmittal }) => dispatch =>
  dispatch(loadSavedProducts({ projectId, forSubmittal }));

const setQuery = (projectId, order) => dispatch => {
  dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.order, order));
  history.push(`/projects/${projectId}?${encodeURIComponent(JSON.stringify(order))}`);
  return dispatch(loadSavedProducts({ projectId, restart: true }));
};

const setSort = (projectId, name) => (dispatch, getState) => {
  const state = getState();
  const order = reducerUtil.getSlice(projectDetailData, projectDetailData.order, state);
  const sortInfo = sortList.find(item => item.name === name) || sortList[0];
  const { sortBy, sortDir } = sortInfo;
  return dispatch(setQuery(projectId, { ...order, sortBy, sortDir }));
};

const trimQuery = query => {
  if (typeof query !== 'string') return query;
  while (query.charAt(0) === '?') query = query.substr(1);
  return query;
};

const selectProduct = product => (dispatch, getState) => {
  const state = getState();
  const selectedProducts =
    reducerUtil.getSlice(projectDetailData, projectDetailData.selectedProducts, state) || [];
  return dispatch(
    reducerUtil.setSlice(projectDetailData, projectDetailData.selectedProducts, [
      ...selectedProducts,
      product
    ])
  );
};

const deselectProduct = product => (dispatch, getState) => {
  const state = getState();
  const selectedProducts =
    reducerUtil.getSlice(projectDetailData, projectDetailData.selectedProducts, state) || [];
  const newList = selectedProducts.filter(({ id }) => id !== product.id);
  return dispatch(
    reducerUtil.setSlice(
      projectDetailData,
      projectDetailData.selectedProducts,
      newList.length > 0 ? newList : undefined
    )
  );
};

const clearSelectedProducts = () => dispatch =>
  dispatch(reducerUtil.setSlice(projectDetailData, projectDetailData.selectedProducts, undefined));

const removeProductsFromProject = (id, name) => async (dispatch, getState) => {
  const state = getState();

  const userId = dispatch(analyticsActions.getUserId());
  const selectedProducts =
    reducerUtil.getSlice(projectDetailData, projectDetailData.selectedProducts, state) || [];
  const productIds = selectedProducts.map(({ id }) => id);

  try {
    await dispatch(
      api.actions.delete(
        `projects/${id}/products`,
        { user_id: userId, product_ids: productIds },
        true
      )
    );

    const notification = [
      {
        id: `update-project-${uuid()}`,
        type: projectSubmittal.PRODUCT_REMOVED,
        payloads: {
          project: {
            id,
            name
          },
          products: selectedProducts
        }
      }
    ];
    dispatch(notificationActions.sendNotifications(notification));
    //refresh the data for project details page.
    dispatch(
      reducerUtil.setSlice(projectDetailData, projectDetailData.selectedProducts, undefined)
    );
    dispatch(loadSavedProducts({ projectId: id, restart: true }));
  } catch (error) {
    dispatch(errorsActions.error(error));
  }
};

const getProject = id => async (dispatch, getState) => {
  dispatch(clearProjectsDetailsData());

  let state = getState();

  try {
    let participants;
    let project = {};
    Promise.all([
      (project = await dispatch(api.actions.get(`projects/${id}`, true))),
      (participants = await dispatch(api.actions.get(`projects/${id}/participants`, true)))
    ]).then(() => {
      ['contractor', 'general_contractor', 'architect', 'distributor'].forEach(o => {
        project[o] = participants.projectParticipants.find(p => p.participant_type === o);
      });
      dispatch(reducerUtil.setSlice(detailsData, detailsData.generalData, project));
    });
  } catch (error) {
    dispatch(errorsActions.error(error));
  }
};

const clearProjectsDetailsData = () => dispatch => {
  dispatch(reducerUtil.setSlice(detailsData, detailsData.products, undefined));
  dispatch(reducerUtil.setSlice(detailsData, detailsData.selectedProducts, undefined));
};

const goToSubmittal = id => async () => {
  history.push(`/proposal/${id}`);
};

const goToProjectEdit = id => async () => {
  history.push(`/projects/${id}/edit`);
};

export default {
  getProject,
  goToSubmittal,
  goToProjectEdit,
  clearProjectsDetailsData,
  loadSavedProducts,
  loadNextPage,
  setSort,
  selectProduct,
  deselectProduct,
  clearSelectedProducts,
  removeProductsFromProject
};
