import styled from 'styled-components';

const Input = styled.input`
  display: block;
  width: 100%;
  height: 3rem;
  border: 1px solid
    ${props => {
      if (props.hasError) return 'red';
      if (props.disabled) return 'grey';
      return 'darkgrey';
    }};
  background: ${props => (props.disabled ? 'grey' : null)};
  cursor: ${props => (props.disabled ? 'not-allowed' : null)};
  border-radius: 2px;
  appearance: none;
  font-size: 1.4rem;
  color: ${props => (props.disabled ? 'grey' : 'darkgrey')};
  padding: 0.5rem 0.8rem;
  line-height: 5;
  &:focus {
    outline: none;
    border-color: ${props => 'blue'};
    box-shadow: 0 0 3px rgba(10, 123, 228, 0.5);
  }
`;

export default Input;
