import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from './actions';
import useDebounce from 'utils/useDebounce';

const SearchIcon = styled.div`
  width: ${props => (props.small ? '1.2rem' : '1.5rem')};
  height: ${props => (props.small ? '1.2rem' : '1.5rem')};
  border-radius: 100%;
  border: 2px solid ${props => (props.light ? props.theme.grey2 : props.theme.grey4)};
  position: relative;
  top: ${props => (props.small ? '0' : '-0.2rem')};
  &:after {
    content: '';
    position: absolute;
    width: 0.2rem;
    height: ${props => (props.small ? '0.6rem' : '0.8rem')};
    background: ${props => (props.light ? props.theme.grey2 : props.theme.grey4)};
    transform: rotate(-45deg);
    right: ${props => (props.small ? '-0.3rem' : '-0.2rem')};
    top: ${props => (props.small ? '0.7rem' : '0.9rem')};
  }
`;

export const HeaderSearch = styled.div`
  display: flex;
  margin-left: 2rem;
  height: 3rem;
  position: relative;
`;

export const SearchForm = styled.div`
  width: 35rem;
`;

export const SearchInput = styled.input`
  height: 3rem;
  width: 100%;
  appearance: none;
  border: 1px solid ${props => props.theme.grey2};
  border-radius: 3px;
  padding: 0.5rem 3rem 0.7rem 1rem;
  font: inherit;
  font-size: 1.4rem;
  caret-color: ${props => props.theme.accentColor};
  &:focus {
    outline: 0;
    border-color: ${props => props.theme.accentColor};
  }
`;

export const SearchSubmit = styled.a`
  position: absolute;
  right: 1rem;
  top: 0.7rem;
  cursor: pointer;
`;

const SearchField = ({ query, dispatch }) => {
  const [text, setText] = useState(query && query.queryString);
  const [prevText, setPrevText] = useState();

  const debouncedText = useDebounce(text, 500);
  useEffect(() => {
    if (text !== prevText) {
      dispatch(actions.setQueryString(debouncedText));
    }
  }, [debouncedText, prevText]);

  useEffect(() => {
    if (!query || query === {}) {
      setText();
      return;
    }
    const { queryString } = query;
    if (!!queryString === !!prevText) return;
    setText(query.queryString);
    setPrevText(query.queryString);
  }, [query, prevText]);

  const handleChange = event => {
    setText(event.target.value);
  };

  return (
    <HeaderSearch className="tutorial-step-2">
      <SearchForm>
        <SearchInput
          placeholder="What are you looking for?"
          {...{ onChange: event => handleChange(event), value: text }}
        />
        <SearchSubmit>
          <SearchIcon small />
        </SearchSubmit>
      </SearchForm>
    </HeaderSearch>
  );
};

export default withRouter(connect()(SearchField));
