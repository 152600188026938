import React, { useState } from 'react';
import styled from 'styled-components';

import Modal from './Modal';
import Button from './Button';

const StyledContent = styled.div`
  font-size: 1.4rem;
  min-width: 35rem;
  h3 {
    padding-right: 2rem;
  }
  .body {
    font-size: 1.4rem;
    padding: 0 0 2rem;
  }
  .footer {
    padding: 0 0 2rem;
    button {
      &:first-child {
        margin-right: 3rem;
      }
    }
  }
`;

const ConfirmModal = ({ btnCustomStyles, onConfirm, title, messages, confirmBtnText }) => {
  const [state, setState] = useState({ isOpen: false });

  const { isOpen } = state;

  const closeModal = () => setState({ isOpen: false });

  const modalProps = {
    title,
    open: isOpen,
    onClose: closeModal
  };

  const handleOnConfirm = () => {
    onConfirm && onConfirm();
    closeModal();
  };

  const defaultBtnStyles = {
    btnLink: true
  };

  let btnStyles;

  if (!btnCustomStyles) {
    btnStyles = {
      ...defaultBtnStyles
    };
  } else {
    btnStyles = {
      ...defaultBtnStyles,
      ...btnCustomStyles
    };
  }

  return (
    <React.Fragment>
      <Button {...btnStyles} onClick={() => setState({ isOpen: true })}>
        {confirmBtnText}
      </Button>
      <Modal {...modalProps}>
        <StyledContent>
          <div className="body">{messages}</div>
          <div className="footer">
            <Button btnLink onClick={closeModal}>
              Cancel
            </Button>
            <Button danger noUpperCase fontWeight="500" onClick={handleOnConfirm}>
              {confirmBtnText}
            </Button>
          </div>
        </StyledContent>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmModal;
