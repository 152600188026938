import React from 'react';

const ConcoraLogo = () => (
  <svg
    width="338"
    height="100"
    viewBox="0 0 338 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="338" height="100" fill="white" fillOpacity="0.0" />
    <g clipPath="url(#clip0)">
      <path
        d="M106.854 42.8398C107.631 41.8546 108.606 41.0673 109.759 40.5022C110.907 39.9371 112.282 39.6522 113.845 39.6522C115.452 39.6522 116.847 39.9371 118 40.5022C119.163 41.0721 120.292 41.927 121.353 43.033L121.599 43.289L126.608 38.72L126.362 38.4544C124.793 36.7543 122.921 35.4696 120.793 34.6389C118.675 33.813 116.427 33.3928 114.111 33.3928C111.616 33.3928 109.324 33.813 107.293 34.6389C105.257 35.4696 103.491 36.6384 102.034 38.1163C100.582 39.5942 99.4332 41.3861 98.6275 43.4436C97.8265 45.4962 97.4164 47.7807 97.4164 50.2343C97.4164 52.6878 97.8217 54.9578 98.6275 56.9911C99.4332 59.0293 100.591 60.8018 102.068 62.2556C103.544 63.7142 105.325 64.8589 107.356 65.6654C109.387 66.472 111.66 66.8777 114.106 66.8777C116.417 66.8777 118.642 66.4913 120.716 65.7331C122.801 64.9699 124.658 63.6949 126.226 61.9465L126.453 61.6905L121.725 56.9573L121.469 57.2471C120.494 58.3483 119.423 59.1983 118.284 59.7683C117.155 60.3333 115.771 60.6183 114.164 60.6183C112.683 60.6183 111.336 60.3527 110.164 59.8262C108.987 59.2998 107.964 58.5656 107.124 57.6383C106.28 56.711 105.619 55.6098 105.156 54.3589C104.692 53.1128 104.456 51.7701 104.456 50.3647C104.456 48.9544 104.659 47.5827 105.064 46.2883C105.469 44.9891 106.072 43.83 106.854 42.8398Z"
        fill="#273B51"
      />
      <path
        d="M157.796 38.2371C156.276 36.7157 154.467 35.5034 152.412 34.634C150.356 33.7598 148.113 33.3203 145.753 33.3203C143.389 33.3203 141.15 33.7647 139.095 34.634C137.044 35.5034 135.23 36.7157 133.71 38.2371C132.19 39.7584 130.979 41.5455 130.111 43.5547C129.237 45.5687 128.798 47.7662 128.798 50.0942C128.798 52.4173 129.242 54.6294 130.111 56.6627C130.979 58.696 132.195 60.4879 133.71 61.9851C135.225 63.4824 137.034 64.6801 139.09 65.5543C141.145 66.4285 143.384 66.868 145.748 66.868C148.113 66.868 150.351 66.4237 152.407 65.5543C154.462 64.685 156.272 63.4824 157.787 61.9851C159.307 60.4879 160.518 58.696 161.386 56.6627C162.259 54.6294 162.699 52.4173 162.699 50.0942C162.699 47.7711 162.255 45.5687 161.386 43.5547C160.527 41.5503 159.316 39.7584 157.796 38.2371ZM145.758 60.6086C144.233 60.6086 142.839 60.343 141.623 59.8166C140.402 59.2901 139.355 58.5656 138.521 57.6625C137.681 56.7593 137.02 55.6484 136.557 54.3589C136.089 53.0645 135.852 51.6349 135.852 50.099C135.852 48.568 136.089 47.1335 136.557 45.8391C137.02 44.5496 137.681 43.4387 138.521 42.5356C139.36 41.6324 140.402 40.9079 141.623 40.3815C142.844 39.855 144.238 39.5894 145.758 39.5894C147.278 39.5894 148.672 39.855 149.893 40.3815C151.114 40.9079 152.156 41.6324 153 42.5356C153.84 43.4387 154.501 44.5496 154.964 45.8391C155.432 47.1335 155.669 48.568 155.669 50.099C155.669 51.6301 155.432 53.0645 154.964 54.3589C154.501 55.6484 153.84 56.7593 153 57.6625C152.161 58.5656 151.119 59.2901 149.898 59.8166C148.672 60.3382 147.283 60.6086 145.758 60.6086Z"
        fill="#273B51"
      />
      <path
        d="M192.985 36.252C191.919 35.2522 190.655 34.5085 189.231 34.0303C187.817 33.557 186.331 33.3203 184.802 33.3203C182.336 33.3203 180.131 33.8564 178.24 34.9142C176.734 35.7594 175.567 36.8219 174.761 38.0922V34.1124H168.112V66.0808H174.761V49.6305C174.761 47.8773 174.978 46.3366 175.403 45.0664C175.823 43.8058 176.416 42.7529 177.169 41.9367C177.917 41.1253 178.8 40.5264 179.794 40.1497C180.802 39.7729 181.912 39.5797 183.089 39.5797C183.967 39.5797 184.826 39.7198 185.641 39.9903C186.442 40.2559 187.161 40.6954 187.774 41.2895C188.392 41.8884 188.893 42.6853 189.27 43.6609C189.646 44.6462 189.839 45.8585 189.839 47.2543V66.0711H196.488V45.6121C196.488 43.5112 196.17 41.6565 195.537 40.1062C194.91 38.5462 194.047 37.2518 192.985 36.252Z"
        fill="#273B51"
      />
      <path
        d="M210.842 42.8398C211.619 41.8546 212.594 41.0673 213.747 40.5022C214.895 39.9371 216.27 39.6522 217.834 39.6522C219.44 39.6522 220.835 39.9371 221.988 40.5022C223.151 41.0721 224.28 41.927 225.346 43.033L225.592 43.289L230.601 38.72L230.355 38.4544C228.786 36.7543 226.914 35.4696 224.787 34.6389C222.668 33.813 220.42 33.3928 218.104 33.3928C215.609 33.3928 213.318 33.813 211.286 34.6389C209.25 35.4696 207.479 36.6384 206.027 38.1163C204.575 39.5942 203.426 41.3861 202.621 43.4436C201.82 45.4962 201.409 47.7807 201.409 50.2343C201.409 52.6878 201.815 54.9578 202.621 56.9911C203.426 59.0293 204.584 60.8018 206.061 62.2556C207.537 63.7142 209.318 64.8589 211.349 65.6654C213.38 66.472 215.653 66.8777 218.099 66.8777C220.41 66.8777 222.635 66.4913 224.709 65.7331C226.794 64.9699 228.651 63.6949 230.22 61.9465L230.451 61.6905L225.723 56.9573L225.467 57.2471C224.492 58.3483 223.421 59.1983 222.282 59.7683C221.153 60.3333 219.769 60.6183 218.167 60.6183C216.685 60.6183 215.339 60.3527 214.167 59.8262C212.989 59.2998 211.967 58.5656 211.127 57.6383C210.283 56.711 209.622 55.6098 209.158 54.3589C208.695 53.1128 208.459 51.7701 208.459 50.3647C208.459 48.9544 208.661 47.5827 209.067 46.2883C209.462 44.9891 210.061 43.83 210.842 42.8398Z"
        fill="#273B51"
      />
      <path
        d="M260.824 38.2371C259.305 36.7157 257.495 35.5034 255.445 34.634C253.389 33.7598 251.146 33.3203 248.786 33.3203C246.422 33.3203 244.183 33.7647 242.128 34.634C240.077 35.5034 238.263 36.7157 236.748 38.2371C235.228 39.7584 234.017 41.5455 233.148 43.5547C232.275 45.5687 231.836 47.7662 231.836 50.0942C231.836 52.4173 232.28 54.6294 233.148 56.6627C234.017 58.696 235.228 60.4879 236.748 61.9851C238.263 63.4824 240.072 64.6801 242.128 65.5543C244.183 66.4285 246.422 66.868 248.786 66.868C251.15 66.868 253.389 66.4237 255.445 65.5543C257.5 64.685 259.309 63.4824 260.824 61.9851C262.344 60.4879 263.555 58.696 264.424 56.6627C265.297 54.6294 265.736 52.4173 265.736 50.0942C265.736 47.7711 265.292 45.5735 264.424 43.5547C263.555 41.5503 262.344 39.7584 260.824 38.2371ZM248.781 60.6086C247.257 60.6086 245.862 60.343 244.646 59.8166C243.426 59.2901 242.378 58.5656 241.539 57.6625C240.699 56.7593 240.038 55.6484 239.575 54.3589C239.107 53.0645 238.871 51.6349 238.871 50.099C238.871 48.568 239.107 47.1335 239.575 45.8391C240.038 44.5496 240.699 43.4387 241.539 42.5356C242.378 41.6324 243.421 40.9079 244.646 40.3815C245.867 39.855 247.261 39.5894 248.781 39.5894C250.301 39.5894 251.696 39.855 252.916 40.3815C254.137 40.9079 255.179 41.6324 256.019 42.5356C256.858 43.4387 257.519 44.5496 257.983 45.8391C258.451 47.1335 258.687 48.568 258.687 50.099C258.687 51.6301 258.451 53.0645 257.983 54.3589C257.519 55.6484 256.858 56.7593 256.019 57.6625C255.179 58.5656 254.132 59.2901 252.916 59.8166C251.7 60.3382 250.306 60.6086 248.781 60.6086Z"
        fill="#273B51"
      />
      <path
        d="M283.907 33.7647C282.913 34.0593 281.992 34.4602 281.157 34.9528C280.322 35.4503 279.555 36.0588 278.875 36.7591C278.465 37.1842 278.098 37.643 277.784 38.1308V34.1124H271.135V66.0808H277.784V50.0266C277.784 46.7085 278.527 44.2453 279.989 42.695C281.447 41.1543 283.598 40.3718 286.378 40.3718C286.831 40.3718 287.299 40.4153 287.762 40.4974C288.235 40.5843 288.713 40.6954 289.181 40.821L289.62 40.9417L289.914 33.8226L289.654 33.7357C288.013 33.1851 285.905 33.1754 283.907 33.7647Z"
        fill="#273B51"
      />
      <path
        d="M320.457 65.6751C320.37 64.854 320.302 63.9702 320.259 63.0573C320.215 62.159 320.196 61.1447 320.196 60.0436V44.0956C320.196 42.6949 319.931 41.3281 319.414 40.0386C318.893 38.7442 318.073 37.5802 316.987 36.5804C315.902 35.5855 314.498 34.7838 312.809 34.1993C311.13 33.6198 309.108 33.3203 306.797 33.3203C304.573 33.3203 302.319 33.7164 300.095 34.494C297.866 35.2764 295.873 36.4645 294.175 38.0294L293.905 38.2805L297.938 42.4679L298.194 42.2748C298.72 41.8787 299.27 41.4585 299.844 41.019C300.394 40.594 301.012 40.2221 301.687 39.9082C302.363 39.5894 303.125 39.3238 303.96 39.1064C304.785 38.8939 305.764 38.7876 306.865 38.7876C308.085 38.7876 309.147 38.9664 310.02 39.3238C310.889 39.6763 311.622 40.1593 312.201 40.7534C312.78 41.3523 313.219 42.0333 313.509 42.7771C313.798 43.5257 313.943 44.2791 313.943 45.0181V45.9744H309.957C307.584 45.9744 305.311 46.1627 303.212 46.5395C301.094 46.921 299.217 47.5537 297.634 48.4231C296.033 49.3021 294.749 50.4613 293.818 51.8667C292.882 53.2818 292.409 55.0592 292.409 57.1408C292.409 58.7298 292.708 60.145 293.302 61.3524C293.89 62.5599 294.71 63.5886 295.738 64.4097C296.756 65.2259 297.963 65.8441 299.318 66.2547C300.664 66.6604 302.093 66.8632 303.569 66.8632C305.977 66.8632 308.134 66.4285 309.977 65.5785C311.598 64.8299 313.041 63.5983 314.276 61.9223C314.295 63.1974 314.363 64.4773 314.469 65.7427L314.498 66.076H320.5L320.457 65.6751ZM313.938 51.0505V52.6636C313.938 53.6779 313.788 54.7163 313.489 55.7595C313.195 56.7834 312.708 57.7301 312.042 58.5656C311.376 59.3964 310.488 60.087 309.403 60.6086C308.317 61.1303 306.976 61.3959 305.412 61.3959C303.429 61.3959 301.847 61.0143 300.703 60.2705C299.593 59.5461 299.058 58.3724 299.058 56.6868C299.058 55.5422 299.4 54.6342 300.11 53.9097C300.833 53.1611 301.784 52.5719 302.937 52.1517C304.11 51.7267 305.432 51.432 306.869 51.2823C308.327 51.1326 309.764 51.0553 311.144 51.0553H313.938V51.0505Z"
        fill="#273B51"
      />
      <path
        d="M56.4618 73.2627C54.363 73.7988 53.0892 75.9335 53.6247 78.0393C54.0783 79.8167 55.6754 81 57.4268 81C57.7453 81 58.0734 80.9614 58.3967 80.8792C72.6593 77.2521 82.29 64.883 82.512 50.0024H74.6617C74.4398 61.2268 67.1878 70.5387 56.4618 73.2627Z"
        fill="#65C5B5"
      />
      <path
        d="M41.625 80.8792C41.9483 80.9614 42.2715 81 42.5948 81C44.3463 81 45.9434 79.8167 46.3969 78.0393C46.9325 75.9384 45.6587 73.7988 43.5598 73.2627C32.8338 70.5387 25.5819 61.2268 25.3647 50.0024H17.5097C17.7268 64.883 27.3575 77.2521 41.625 80.8792Z"
        fill="#CC625E"
      />
      <path
        d="M49.8564 35.9187C42.0882 35.9187 35.7915 42.2216 35.7915 49.9976C35.7915 57.7735 42.0882 64.0764 49.8564 64.0764C57.6246 64.0764 63.9213 57.7735 63.9213 49.9976C63.9213 42.2216 57.6246 35.9187 49.8564 35.9187Z"
        fill="#273B51"
      />
      <path
        d="M76.9295 31.9052C72.6159 25.4478 66.4399 21.0285 59.0673 19.1256C56.9685 18.5846 54.8262 19.8452 54.2858 21.9462C53.7454 24.0471 55.0047 26.1916 57.1036 26.7325C69.8994 30.0409 74.6665 42.4438 74.6665 49.4808C74.6665 49.6547 74.6617 49.8237 74.6569 49.9976H82.5072C82.512 49.8237 82.5168 49.6547 82.5168 49.4808C82.5168 43.6271 80.4807 37.2228 76.9295 31.9052Z"
        fill="#EB9E4E"
      />
      <path
        d="M42.9132 26.7328C45.0121 26.1919 46.2763 24.0474 45.731 21.9465C45.1906 19.8455 43.0483 18.5849 40.9495 19.1259C33.5769 21.0336 27.4009 25.4529 23.0873 31.9055C19.5361 37.2231 17.5 43.6274 17.5 49.4859C17.5 49.6598 17.5096 49.8289 17.5096 50.0027H25.3599C25.3551 49.8289 25.3503 49.6598 25.3503 49.4859C25.3503 42.4441 30.1174 30.0412 42.9132 26.7328Z"
        fill="#4A81C2"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="303" height="62" fill="white" transform="translate(17.5 19)" />
      </clipPath>
    </defs>
  </svg>
);

export default ConcoraLogo;
