import React from 'react';
import { withRouter } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';

import { sbBranding, getGlobals } from '../../global-styles';

const Library = ({ children }) => {
  const GlobalStyles = createGlobalStyle`${getGlobals()}`;

  return (
    <React.Fragment>
      <Favicon url={'concora=favicon.png'} />
      <Helmet>
        <title>{'Concora Library'}</title>
      </Helmet>
      <GlobalStyles />
      <ThemeProvider theme={{ ...sbBranding }}>{children}</ThemeProvider>
    </React.Fragment>
  );
};

export default withRouter(Library);
