import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DisplayLink from '../../shared/DisplayLink';

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  color: ${props => props.theme.grey3};
  a {
    transition: color 0.1s ease;
    cursor: pointer;
    position: relative;
    font-size: 1.4rem;
    font-weight: 500;
    color: ${props => props.theme.grey3};
    &:hover {
      color: inherit;
    }
  }
`;

const MenuList = styled.ul`
  display: flex;
  list-style: none;
  position: relative;
  li:last-child {
    a {
      margin-right: 0;
    }
  }
  .active {
    color: inherit;
    font-weight: 700;
  }
`;
const PrettyLink = styled(DisplayLink)``;

const MenuItem = styled.li`
  margin-right: 4rem;
`;

const HeaderLinksContainer = ({ activeIndex, ...props }) => {
  return (
    <Container {...props}>
      <MenuList>
        <MenuItem key={0}>
          <PrettyLink {...{ url: '/products', activeIndex }}>{'Search Products'}</PrettyLink>
        </MenuItem>
        <MenuItem key={1}>
          <PrettyLink {...{ url: '/manufacturers', activeIndex }}>
            {'Search Manufacturers'}
          </PrettyLink>
        </MenuItem>
        <MenuItem key={2}>
          <PrettyLink {...{ url: '/spaces', activeIndex }}>{'Concora Spaces'}</PrettyLink>
        </MenuItem>
      </MenuList>
    </Container>
  );
};

export default HeaderLinksContainer;
