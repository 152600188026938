import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import HeaderAssets from 'shared/HeaderAssets';

const Image = styled.img`
  max-width: 90%;
  width: auto;
  height: auto;
  display: block;
  max-height: 23rem;
`;

const ProductLink = styled(Link)`
  transition: color 0.1s ease;
  word-break: break-word;
  &:hover {
    color: ${props => props.theme.linkColor || props.theme.blue};
  }
`;

class Product extends React.Component {
  render() {
    const { id, thumbnail, name, assets = [] } = this.props;
    return (
      <div className="product-compare-product" {...this.props}>
        <Image src={thumbnail} alt={name} />
        <h4>
          <ProductLink to={`/products/${id}`}>{name}</ProductLink>
        </h4>
        <HeaderAssets assets={assets} />
      </div>
    );
  }
}

export default Product;
