import React from 'react';

import { addComma } from 'utils/numbers';

import ProjectTabGroup from './ProjectTabGroup';
import ProjectTabContent from './ProjectTabContent';

const TabAdditionalInformation = ({
  occupant_type,
  owner_type,
  owner_occupied,
  purchase_order,
  project_budget,
  project_size,
  project_type,
  project_scope,
  project_phase,
  ...props
}) => {
  return (
    <ProjectTabContent {...props}>
      <ProjectTabGroup>
        <strong>Project Budget</strong>
        <span>{project_budget ? `$${addComma(project_budget)}` : 'N/A'}</span>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <strong>Project Size</strong>
        <span>{project_size ? `${addComma(project_size)} sq. ft` : 'N/A'}</span>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <strong>Project Type</strong>
        <span>{project_type || 'N/A'}</span>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <strong>Owner Type</strong>
        <span>{owner_type || 'N/A'}</span>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <strong>Occupant Type</strong>
        <span>{occupant_type || 'N/A'}</span>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <strong>Project Scope</strong>
        <span>{project_scope || 'N/A'}</span>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <strong>Project Phase</strong>
        <span>{project_phase || 'N/A'}</span>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <strong>Owner Occupied</strong>
        <span>{owner_occupied ? 'Yes' : 'No' || 'N/A'}</span>
      </ProjectTabGroup>
    </ProjectTabContent>
  );
};

export default TabAdditionalInformation;
