import React from 'react';
import styled, { css } from 'styled-components';

const TooltipContainer = styled.div`
  overflow: visible;
  z-index:9999
  position: absolute;
  border-radius: 3px;
  padding: 6px 6px 8px;
  font-size: 1.2rem;
  font-weight: 600;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  background: ${props => (props.bgColor ? props.bgColor : props.theme.grey6)};
  color: ${props => (props.color ? props.color : props.theme.white)};
  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background: ${props => (props.bgColor ? props.bgColor : props.theme.grey6)};
    position: absolute;
    left: calc(50% - 4px);
    bottom: -3px;
    border-radius: 2px;
    transform: rotate(45deg);
  }
  span {
    border-bottom: none;
  }
  ${props => {
    if (!props.position) {
      //by default: position is top
      return css`
        top: -4rem;
        left: -5rem;
        right: -5rem;
      `;
    } else if (props.position === 'bottom') {
      return css`
        bottom: -4rem;
        left: -5rem;
        right: -5rem;
        &:before {
          top: -3px;
        }
      `;
    }
  }}
`;

const Tooltip = ({ children, ...props }) => (
  <TooltipContainer className="tooltip" {...props}>
    {children}
  </TooltipContainer>
);

export default Tooltip;
