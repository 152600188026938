import React, { Component } from 'react';
import styled from 'styled-components';

import SelectedTag from './SelectedTag';
import SortContainer from './SortContainer';

import { connect } from 'react-redux';
import { reducerUtil } from 'base-client';

import searchActions from '../actions';
import searchData from '../reducerData';

const Container = styled.div`
  margin: 2rem 0;
  z-index: 1;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 125rem;
  margin: auto;
`;

const AppliedFilters = styled.div`
  font-size: 1.4rem;
  font-family: ${props => props.theme.fontAlt};
  margin-right: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

const RightActions = styled.div`
  margin-left: auto;
  margin-top: -0.3rem;
  display: flex;
  button {
    text-transform: none;
    margin-left: 1rem;
  }
`;

const FilteredCount = styled.div`
  font-weight: 800;
  width: 25rem;
  margin-top: 0.3rem;
  span {
    font-style: italic;
    font-weight: 500;
  }
`;

const SelectedFilters = styled.div`
  flex: 1;
`;

const ClearAll = styled.a`
  color: ${props => props.theme.accentColor};
  white-space: nowrap;
  &:hover,
  &:focus {
    color: ${props => props.theme.blueDarkest};
  }
`;

class ActionsBarContainer extends Component {
  render() {
    const { selectedFilters, filterData, totalHits, dispatch } = this.props;
    const filters = [];
    const clearAllFilters = () => dispatch(searchActions.clearAllFilters());

    if (selectedFilters) {
      selectedFilters.forEach(({ attribute, facets }) => {
        if (facets) {
          const { name, single_select_facet } =
            filterData.find(item => item.attribute === attribute) || {};
          if (!single_select_facet) {
            facets.forEach(facet => {
              filters.push({
                key: `${attribute}${facet}`,
                name,
                facet,
                onClick: () => dispatch(searchActions.removeFilter({ attribute, facets: [facet] }))
              });
            });
          }
        }
      });
    }

    return (
      <Container className="action-bar">
        <Wrapper>
          <AppliedFilters>
            <FilteredCount>Showing {totalHits} products </FilteredCount>
            <SelectedFilters>
              {filters.map(({ key, ...filter }) => (
                <SelectedTag key={key} {...filter} />
              ))}
              {filters.length > 1 ? (
                // eslint-disable-next-line no-script-url
                <ClearAll href="javascript:;" onClick={clearAllFilters}>
                  Clear All
                </ClearAll>
              ) : (
                ''
              )}
            </SelectedFilters>
          </AppliedFilters>

          <RightActions>
            <SortContainer />
          </RightActions>
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { filters: selectedFilters } =
    reducerUtil.getSlice(searchData, searchData.query, state) || {};
  const { pagination, filters } = reducerUtil.getSlice(searchData, searchData.meta, state) || {};
  const { totalHits } = pagination || {};
  return {
    selectedFilters,
    filterData: filters,
    totalHits
  };
};

export default connect(mapStateToProps)(ActionsBarContainer);
