import React from 'react';
import styled from 'styled-components';

const Tag = styled.span`
  font-size: 1.4rem;
  padding: 0.2rem 0.5rem 0.3rem;
  display: inline-block;
  border: 1px solid ${props => props.theme.grey2};
  background: ${props => props.theme.grey0};
  border-radius: 3px;
  margin: 0 1rem 1rem 0;
  color: ${props => props.theme.grey3};
  cursor: pointer;
  transition: background 0.2s ease;
  &:hover,
  &:focus {
    background: ${props => props.theme.grey1};
  }
  &:first-of-type {
    margin-left: 0;
  }
`;

const RemoveIcon = styled.span`
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 1rem;
  position: relative;
  font-size: 1.2rem;
  line-height: 0.8;
  top: -2px;
  text-align: center;
  background: ${props => props.theme.grey3};
  color: white;
  margin-left: 0.5rem;
`;

const SelectedTag = ({ name, facet, ...props }) => {
  return (
    <Tag>
      {name}: {facet} <RemoveIcon {...props}>x</RemoveIcon>
    </Tag>
  );
};

export default SelectedTag;
