export default {
  education: [
    {
      SmartBIMSpaceLayoutID: 1,
      SmartBIMProjectSpaceID: 16,
      LayoutName: 'Art Room 5-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Art_Room_5-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Art_Room_5-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Art_Room_5-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Art_Room_5-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Art_Room_5-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:21 -0400',
      UpdateDate: null,
      SearchDescription: 'School Art Classroom for Grades 5-8',
      category: 'Art Room',
      manufacturers: [
        {
          name: 'PPG Architectural Glass',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/PPG_Glass.jpg'
        },
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 2,
      SmartBIMProjectSpaceID: 16,
      LayoutName: 'Art Room K-5',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Art_Room_K-5.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Art_Room_K-5.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Art_Room_K-5.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Art_Room_K-5_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Art_Room_K-5_3D.jpg',
      CreateDate: '2009-08-28 09:19:21 -0400',
      UpdateDate: null,
      SearchDescription: 'School Art Classroom for Grades K-5',
      category: 'Art Room',
      manufacturers: [
        {
          name: 'PPG Architectural Glass',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/PPG_Glass.jpg'
        },
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 3,
      SmartBIMProjectSpaceID: 16,
      LayoutName: 'Art Room Combined K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Art_Room_Combined_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Art_Room_Combined_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Art_Room_Combined_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Art_Room_Combined_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Art_Room_Combined_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:21 -0400',
      UpdateDate: null,
      SearchDescription: 'School Art Classroom for Grades K-8',
      category: 'Art Room',
      manufacturers: [
        {
          name: 'PPG Architectural Glass',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/PPG_Glass.jpg'
        },
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 4,
      SmartBIMProjectSpaceID: 17,
      LayoutName: 'Classroom K-2',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Classroom_K-2.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Classroom_K-2.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Classroom_K-2.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Classroom_K-2_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Classroom_K-2_3D.jpg',
      CreateDate: '2009-08-28 09:19:22 -0400',
      UpdateDate: null,
      SearchDescription: 'School Classroom for Grades K-2',
      category: 'Classroom',
      manufacturers: [
        {
          name: 'PPG Architectural Glass',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/PPG_Glass.jpg'
        },
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 5,
      SmartBIMProjectSpaceID: 17,
      LayoutName: 'Classroom K-5',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Classroom_K-5.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Classroom_K-5.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Classroom_K-5.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Classroom_K-5_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Classroom_K-5_3D.jpg',
      CreateDate: '2009-08-28 09:19:22 -0400',
      UpdateDate: null,
      SearchDescription: 'School Classroom for Grades K-5',
      category: 'Classroom',
      manufacturers: [
        {
          name: 'PPG Architectural Glass',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/PPG_Glass.jpg'
        },
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 6,
      SmartBIMProjectSpaceID: 18,
      LayoutName: 'Corridor',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Corridor.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Corridor.rvt',
      DwfURL: 'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Corridor.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Corridor_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Corridor_3D.jpg',
      CreateDate: '2009-08-28 09:19:22 -0400',
      UpdateDate: null,
      SearchDescription: 'School Corridor',
      category: 'Circulation Space',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'AMVIC',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Amvic_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 7,
      SmartBIMProjectSpaceID: 19,
      LayoutName: 'Gym',
      ImageURL: 'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Gym.jpg',
      RevitURL: 'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Gym.rvt',
      DwfURL: 'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Gym.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Gym_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Gym_3D.jpg',
      CreateDate: '2009-08-28 09:19:22 -0400',
      UpdateDate: null,
      SearchDescription: 'School Physical Education Gym',
      category: 'Physical Education',
      manufacturers: [
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 8,
      SmartBIMProjectSpaceID: 20,
      LayoutName: 'Home Education 9-12',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Home_Education_9-12.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Home_Education_9-12.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Home_Education_9-12.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Home_Education_9-12_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Home_Education_9-12_3D.jpg',
      CreateDate: '2009-08-28 09:19:22 -0400',
      UpdateDate: null,
      SearchDescription: 'School Technical Education Home Education for Grades 9-12',
      category: 'Technical Education',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 9,
      SmartBIMProjectSpaceID: 21,
      LayoutName: 'Kiln-Ceramic Storage',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Kiln-Ceramic_Storage.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Kiln-Ceramic_Storage.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Kiln-Ceramic_Storage.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Kiln-Ceramic_Storage_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Kiln-Ceramic_Storage_3D.jpg',
      CreateDate: '2009-08-28 09:19:22 -0400',
      UpdateDate: null,
      SearchDescription: 'School Kiln-Ceramic Storage',
      category: 'Storage',
      manufacturers: [
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 10,
      SmartBIMProjectSpaceID: 22,
      LayoutName: 'Locker-Room',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Locker-Room.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Locker-Room.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Locker-Room.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Locker-Room_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Locker-Room_3D.jpg',
      CreateDate: '2009-08-28 09:19:22 -0400',
      UpdateDate: null,
      SearchDescription: 'School Locker Room',
      category: 'Locker-Room',
      manufacturers: [
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 11,
      SmartBIMProjectSpaceID: 23,
      LayoutName: 'MultiPurpose Stage',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_MultiPurpose_Stage.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_MultiPurpose_Stage.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_MultiPurpose_Stage.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_MultiPurpose_Stage_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_MultiPurpose_Stage_3D.jpg',
      CreateDate: '2009-08-28 09:19:22 -0400',
      UpdateDate: null,
      SearchDescription: 'School Assembly Space with Multi-Purpose Stage',
      category: 'Assembly Space',
      manufacturers: [
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 12,
      SmartBIMProjectSpaceID: 24,
      LayoutName: 'Restroom-Multi',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Restroom-Multi.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Restroom-Multi.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Restroom-Multi.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Restroom-Multi_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Restroom-Multi_3D.jpg',
      CreateDate: '2009-08-28 09:19:22 -0400',
      UpdateDate: null,
      SearchDescription: 'School Restroom for Multiple Occupancy',
      category: 'Restroom',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 13,
      SmartBIMProjectSpaceID: 24,
      LayoutName: 'Restroom-Single',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Restroom-Single.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Restroom-Single.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Restroom-Single.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Restroom-Single_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Restroom-Single_3D.jpg',
      CreateDate: '2009-08-28 09:19:22 -0400',
      UpdateDate: null,
      SearchDescription: 'School Restroom for Single Occupancy',
      category: 'Restroom',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 14,
      SmartBIMProjectSpaceID: 21,
      LayoutName: 'Storage Room',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Storage_Room.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Storage_Room.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Storage_Room.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Storage_Room_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Storage_Room_3D.jpg',
      CreateDate: '2009-08-28 09:19:23 -0400',
      UpdateDate: null,
      SearchDescription: 'School Storage Room',
      category: 'Storage',
      manufacturers: [
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 15,
      SmartBIMProjectSpaceID: 25,
      LayoutName: 'Janitorial Closet',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Janitorial_Closet.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Janitorial_Closet.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Janitorial_Closet.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Janitorial_Closet_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Janitorial_Closet_3D.jpg',
      CreateDate: '2009-08-28 09:19:23 -0400',
      UpdateDate: null,
      SearchDescription: 'School Operations and Maintenance Janitorial Closet',
      category: 'Operations/Maintenance',
      manufacturers: [
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 16,
      SmartBIMProjectSpaceID: 26,
      LayoutName: 'Administration Office K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Administration_Office_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Administration_Office_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Administration_Office_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Administration_Office_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Administration_Office_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:23 -0400',
      UpdateDate: null,
      SearchDescription: 'School Administration Office for Grades K-8',
      category: 'Administrative',
      manufacturers: [
        {
          name: 'PPG Architectural Glass',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/PPG_Glass.jpg'
        },
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 17,
      SmartBIMProjectSpaceID: 27,
      LayoutName: 'Audio Visual Room K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Audio_Visual_Room_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Audio_Visual_Room_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Audio_Visual_Room_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Audio_Visual_Room_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Audio_Visual_Room_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:23 -0400',
      UpdateDate: null,
      SearchDescription: 'School Library/Media Center Specialty Audio Visual Room for Grades K-8',
      category: 'Library/Media Center Specialty',
      manufacturers: [
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 18,
      SmartBIMProjectSpaceID: 28,
      LayoutName: 'Computer Room K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Computer_Room_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Computer_Room_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Computer_Room_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Computer_Room_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Computer_Room_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:23 -0400',
      UpdateDate: null,
      SearchDescription: 'School Computer Lab for Grades K-8',
      category: 'Computer Lab',
      manufacturers: [
        {
          name: 'PPG Architectural Glass',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/PPG_Glass.jpg'
        },
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 19,
      SmartBIMProjectSpaceID: 26,
      LayoutName: 'Conference Room K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Conference_Room_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Conference_Room_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Conference_Room_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Conference_Room_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Conference_Room_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:23 -0400',
      UpdateDate: null,
      SearchDescription: 'School Administrative Conference Room for Grades K-8',
      category: 'Administrative',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 20,
      SmartBIMProjectSpaceID: 29,
      LayoutName: 'Kitchen Serving K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Kitchen_Serving_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Kitchen_Serving_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Kitchen_Serving_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Kitchen_Serving_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Kitchen_Serving_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:23 -0400',
      UpdateDate: null,
      SearchDescription: 'School Food Service Serving for Grades K-8',
      category: 'Food Service',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 21,
      SmartBIMProjectSpaceID: 25,
      LayoutName: 'Loading Dock',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Loading_Dock.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Loading_Dock.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Loading_Dock.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Loading_Dock_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Loading_Dock_3D.jpg',
      CreateDate: '2009-08-28 09:19:23 -0400',
      UpdateDate: null,
      SearchDescription: 'School Operations and Maintenance Loading Dock',
      category: 'Operations/Maintenance',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 22,
      SmartBIMProjectSpaceID: 27,
      LayoutName: 'Media Center K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Media_Center_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Media_Center_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Media_Center_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Media_Center_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Media_Center_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:24 -0400',
      UpdateDate: null,
      SearchDescription: 'School Library amd Media Center for Grades K-8',
      category: 'Library/Media Center Specialty',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 23,
      SmartBIMProjectSpaceID: 19,
      LayoutName: 'Playground K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Playground_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Playground_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Playground_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Playground_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Playground_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:24 -0400',
      UpdateDate: null,
      SearchDescription: 'School Physical Education Playground for Grades K-8',
      category: 'Physical Education',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 24,
      SmartBIMProjectSpaceID: 30,
      LayoutName: 'Science Classroom K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Science_Classroom_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Science_Classroom_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Science_Classroom_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Science_Classroom_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Science_Classroom_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:24 -0400',
      UpdateDate: null,
      SearchDescription: 'School Science Classroom for Grades K-8',
      category: 'Science Specialty',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 25,
      SmartBIMProjectSpaceID: 31,
      LayoutName: 'Special Education K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Special_Education_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Special_Education_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Special_Education_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Special_Education_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Special_Education_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:24 -0400',
      UpdateDate: null,
      SearchDescription: 'School Special Education for Grades K-8',
      category: 'Special Education Specialty',
      manufacturers: [
        {
          name: 'PPG Architectural Glass',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/PPG_Glass.jpg'
        },
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 26,
      SmartBIMProjectSpaceID: 18,
      LayoutName: 'Stair',
      ImageURL: 'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Stair.jpg',
      RevitURL: 'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Stair.rvt',
      DwfURL: 'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Stair.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Stair_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Stair_3D.jpg',
      CreateDate: '2009-08-28 09:19:24 -0400',
      UpdateDate: null,
      SearchDescription: 'School Stairs',
      category: 'Circulation Space',
      manufacturers: [
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'AMVIC',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Amvic_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 27,
      SmartBIMProjectSpaceID: 29,
      LayoutName: 'Student  Dining K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Student_ Dining_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Student_ Dining_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Student_ Dining_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Student_ Dining_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Student_ Dining_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:24 -0400',
      UpdateDate: null,
      SearchDescription: 'School Food Service Student  Dining for Grades K-8',
      category: 'Food Service',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 28,
      SmartBIMProjectSpaceID: 26,
      LayoutName: 'Waiting Room K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Waiting_Room_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Waiting_Room_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Waiting_Room_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Waiting_Room_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Waiting_Room_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:24 -0400',
      UpdateDate: null,
      SearchDescription: 'School Administrative Waiting Room for Grades K-8',
      category: 'Administrative',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'PPG Architectural Finishes',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/ppg.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 29,
      SmartBIMProjectSpaceID: 26,
      LayoutName: 'Workroom Storage K-8',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/Images/Schools_Workroom_Storage_K-8.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/RVTs/Schools_Workroom_Storage_K-8.rvt',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/DWFs/Schools_Workroom_Storage_K-8.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/FPs/Schools_Workroom_Storage_K-8_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Schools/3Ds/Schools_Workroom_Storage_K-8_3D.jpg',
      CreateDate: '2009-08-28 09:19:24 -0400',
      UpdateDate: null,
      SearchDescription: 'School Administrative Workroom Storage for Grades K-8',
      category: 'Administrative',
      manufacturers: [
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    }
  ],
  healthcare: [
    {
      SmartBIMSpaceLayoutID: 30,
      SmartBIMProjectSpaceID: 32,
      LayoutName: 'Nurse Station',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Nurse_Station_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Nurse_Station_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Nurse_Station_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Nurse_Station_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Nurse_Station_Reed_3D.jpg',
      CreateDate: '2009-11-05 09:26:47 -0500',
      UpdateDate: null,
      SearchDescription: 'Medical Nurse Station',
      category: 'Nurse Station',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 31,
      SmartBIMProjectSpaceID: 33,
      LayoutName: 'Pharmacy',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Pharmacy_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Pharmacy_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Pharmacy_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Pharmacy_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Pharmacy_Reed_3D.jpg',
      CreateDate: '2009-11-05 09:26:47 -0500',
      UpdateDate: null,
      SearchDescription: 'Medical Pharmacy',
      category: 'Pharmacy',
      manufacturers: [
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 32,
      SmartBIMProjectSpaceID: 34,
      LayoutName: 'Physical Therapy Gym',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Physical_Therapy_Gym_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Physical_Therapy_Gym_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Physical_Therapy_Gym_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Physical_Therapy_Gym_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Physical_Therapy_Gym_Reed_3D.jpg',
      CreateDate: '2009-11-05 09:26:47 -0500',
      UpdateDate: null,
      SearchDescription: 'Medical Physical Therapy Gym',
      category: 'Physical Therapy Gym',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 33,
      SmartBIMProjectSpaceID: 35,
      LayoutName: 'Surgical Room',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Surgical_Room_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Surgical_Room_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Surgical_Room_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Surgical_Room_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Surgical_Room_Reed_3D.jpg',
      CreateDate: '2009-11-05 09:26:47 -0500',
      UpdateDate: null,
      SearchDescription: 'Medical Surgical Room',
      category: 'Surgical Room',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 34,
      SmartBIMProjectSpaceID: 36,
      LayoutName: 'On Call Dormitory',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_On_Call_Dormitory.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_On_Call_Dormitory.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_On_Call_Dormitory.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_On_Call_Dormitory_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_On_Call_Dormitory_3D.jpg',
      CreateDate: '2009-11-05 09:26:47 -0500',
      UpdateDate: null,
      SearchDescription: 'Medical On Call Dormitory',
      category: 'On Call Dormitory',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 35,
      SmartBIMProjectSpaceID: 37,
      LayoutName: 'Behavioral Patient Care Unit',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Behavioral_Patient_Care_Unit_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Behavioral_Patient_Care_Unit_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Behavioral_Patient_Care_Unit_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Behavioral_Patient_Care_Unit_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Behavioral_Patient_Care_Unit_Reed_3D.jpg',
      CreateDate: '2010-06-08 15:00:09 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Behavioral Patient Care Unit',
      category: 'Behavioral Patient Care Unit',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 36,
      SmartBIMProjectSpaceID: 38,
      LayoutName: 'Chemotherapy Center',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Chemotherapy_Center_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Chemotherapy_Center_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Chemotherapy_Center_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Chemotherapy_Center_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Chemotherapy_Center_Reed_3D.jpg',
      CreateDate: '2010-06-08 15:24:22 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Chemotherapy Center',
      category: 'Chemotherapy Center',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 37,
      SmartBIMProjectSpaceID: 39,
      LayoutName: 'Densitometry',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Densitometry_Room_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Densitometry_Room_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Densitometry_Room_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Densitometry_Room_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Densitometry_Room_Reed_3D.jpg',
      CreateDate: '2010-06-08 15:28:42 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Densitometry',
      category: 'Densitometry',
      manufacturers: [
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 38,
      SmartBIMProjectSpaceID: 40,
      LayoutName: 'Dental Service Room',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Dental_Service_Room_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Dental_Service_Room_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Dental_Service_Room_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Dental_Service_Room_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Dental_Service_Room_Reed_3D.jpg',
      CreateDate: '2010-06-08 15:32:10 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Dental Service Room',
      category: 'Dental Service Room',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 39,
      SmartBIMProjectSpaceID: 41,
      LayoutName: 'Dental XRAY Room',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Dental_XRAY_Room_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Dental_XRAY_Room_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Dental_XRAY_Room_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Dental_XRAY_Room_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Dental_XRAY_Room_Reed_3D.jpg',
      CreateDate: '2010-06-08 15:35:47 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Dental XRAY Room',
      category: 'Dental XRAY Room',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 40,
      SmartBIMProjectSpaceID: 42,
      LayoutName: 'Dialysis Center',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Dialysis_Center_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Dialysis_Center_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Dialysis_Center_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Dialysis_Center_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Dialysis_Center_Reed_3D.jpg',
      CreateDate: '2010-06-08 15:40:17 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Dialysis Center',
      category: 'Dialysis Center',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 41,
      SmartBIMProjectSpaceID: 43,
      LayoutName: 'Dock',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Dock_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Dock_Reed.zip',
      DwfURL: 'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Dock_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Dock_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Dock_Reed_3D.jpg',
      CreateDate: '2010-06-08 15:42:34 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Dock',
      category: 'Dock',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 42,
      SmartBIMProjectSpaceID: 44,
      LayoutName: 'Exam Room',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Exam_Room_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Exam_Room_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Exam_Room_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Exam_Room_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Exam_Room_Reed_3D.jpg',
      CreateDate: '2010-06-08 15:44:20 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Exam Room',
      category: 'Exam Room',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'AMVIC',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Amvic_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 43,
      SmartBIMProjectSpaceID: 45,
      LayoutName: 'CT Room',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_CT_Room_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_CT_Room_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_CT_Room_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_CT_Room_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_CT_Room_Reed_3D.jpg',
      CreateDate: '2010-06-08 15:47:30 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical CT Room',
      category: 'CT Room',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 44,
      SmartBIMProjectSpaceID: 46,
      LayoutName: 'MRI Room',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_MRI_Room_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_MRI_Room_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_MRI_Room_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_MRI_Room_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_MRI_Room_Reed_3D.jpg',
      CreateDate: '2010-06-10 01:33:36 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical MRI Room',
      category: 'MRI Room',
      manufacturers: [
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 45,
      SmartBIMProjectSpaceID: 47,
      LayoutName: 'Patient Room',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Patient_Room_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Patient_Room_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Patient_Room_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Patient_Room_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Patient_Room_Reed_3D.jpg',
      CreateDate: '2010-06-10 01:35:31 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Patient Room',
      category: 'Patient Room',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 46,
      SmartBIMProjectSpaceID: 48,
      LayoutName: 'Patient Room Transitional',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Patient_Room_Transitional_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Patient_Room_Transitional_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Patient_Room_Transitional_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Patient_Room_Transitional_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Patient_Room_Transitional_Reed_3D.jpg',
      CreateDate: '2010-06-10 01:37:13 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Patient Room Transitional',
      category: 'Patient Room Transitional',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 47,
      SmartBIMProjectSpaceID: 49,
      LayoutName: 'Waiting Room',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Waiting_Room_Reed.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Waiting_Room_Reed.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Waiting_Room_Reed.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Waiting_Room_Reed_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Waiting_Room_Reed_3D.jpg',
      CreateDate: '2010-06-10 07:56:11 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Waiting Room',
      category: 'Waiting Room',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 48,
      SmartBIMProjectSpaceID: 50,
      LayoutName: 'Exterior',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/Images/Medical_Exterior.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/RVTs/Medical_Exterior.zip',
      DwfURL: 'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/DWFs/Medical_Exterior.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/FPs/Medical_Exterior_FP.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/Medical/3Ds/Medical_Exterior_3D.jpg',
      CreateDate: '2010-06-10 07:57:25 -0400',
      UpdateDate: null,
      SearchDescription: 'Medical Exterior',
      category: 'Exterior',
      manufacturers: [
        {
          name: 'Guardian Industries Corp',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Guardian_Logo.gif'
        },
        {
          name: 'Inpro',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/InPro_Logo.jpg'
        },
        {
          name: 'Georgia-Pacific Gypsum LLC',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/georgiapacific.jpg'
        },
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        },
        {
          name: 'AMVIC',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Amvic_Logo.jpg'
        },
        {
          name: 'DERBIGUM Americas, Inc.',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Derbigum_Logo.jpg'
        }
      ]
    }
  ],
  residential: [
    {
      SmartBIMSpaceLayoutID: 49,
      SmartBIMProjectSpaceID: 51,
      LayoutName: 'Bathroom Double-Sink',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/Images/Single-Family_Bathroom_Double-Sink_SMARTBIM.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/RVTs/Single-Family_Bathroom_Double-Sink_SMARTBIM.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/DWFs/Single-Family_Bathroom_Double-Sink_SMARTBIM.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/FPs/Single-Family_Bathroom_Double-Sink_SMARTBIM.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/3Ds/Single-Family_Bathroom_Double-Sink_SMARTBIM-3D.jpg',
      CreateDate: '2011-05-12 09:18:56 -0400',
      UpdateDate: null,
      SearchDescription: 'Single Family Residential Bathroom with Double-Sink',
      category: 'Bathroom Double-Sink',
      manufacturers: [
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 50,
      SmartBIMProjectSpaceID: 52,
      LayoutName: 'Bathroom Jack-Jill',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/Images/Single-Family_Bathroom_Jack-Jill_SMARTBIM.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/RVTs/Single-Family_Bathroom_Jack-Jill_SMARTBIM.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/DWFs/Single-Family_Bathroom_Jack-Jill_SMARTBIM.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/FPs/Single-Family_Bathroom_Jack-Jill_SMARTBIM.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/3Ds/Single-Family_Bathroom_Jack-Jill_SMARTBIM-3D.jpg',
      CreateDate: '2011-05-12 09:34:44 -0400',
      UpdateDate: null,
      SearchDescription: 'Single Family Residential Bathroom Jack-Jill',
      category: 'Bathroom Jack-Jill',
      manufacturers: [
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 51,
      SmartBIMProjectSpaceID: 53,
      LayoutName: 'Bathroom',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/Images/Single-Family_Bathroom_SMARTBIM.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/RVTs/Single-Family_Bathroom_SMARTBIM.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/DWFs/Single-Family_Bathroom_SMARTBIM.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/FPs/Single-Family_Bathroom_SMARTBIM.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/3Ds/Single-Family_Bathroom_SMARTBIM-3D.jpg',
      CreateDate: '2011-05-12 09:42:50 -0400',
      UpdateDate: null,
      SearchDescription: 'Single Family Residential Bathroom',
      category: 'Bathroom',
      manufacturers: [
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 52,
      SmartBIMProjectSpaceID: 54,
      LayoutName: 'Bedroom',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/Images/Single-Family_Bedroom_SMARTBIM.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/RVTs/Single-Family_Bedroom_SMARTBIM.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/DWFs/Single-Family_Bedroom_SMARTBIM.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/FPs/Single-Family_Bedroom_SMARTBIM.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/3Ds/Single-Family_Bedroom_SMARTBIM-3D.jpg',
      CreateDate: '2011-05-12 09:49:37 -0400',
      UpdateDate: null,
      SearchDescription: 'Single Family Residential Bedroom',
      category: 'Bedroom',
      manufacturers: []
    },
    {
      SmartBIMSpaceLayoutID: 53,
      SmartBIMProjectSpaceID: 55,
      LayoutName: 'Kitchen W-Island',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/Images/Single-Family_Kitchen_W-Island_SMARTBIM.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/RVTs/Single-Family_Kitchen_W-Island_SMARTBIM.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/DWFs/Single-Family_Kitchen_W-Island_SMARTBIM.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/FPs/Single-Family_Kitchen_W-Island_SMARTBIM.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/3Ds/Single-Family_Kitchen_W-Island_SMARTBIM-3D.jpg',
      CreateDate: '2011-05-12 10:17:32 -0400',
      UpdateDate: null,
      SearchDescription: 'Single Family Residential Kitchen with Island',
      category: 'Kitchen W-Island',
      manufacturers: [
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 54,
      SmartBIMProjectSpaceID: 56,
      LayoutName: 'Office',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/Images/Single-Family_Office_SMARTBIM.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/RVTs/Single-Family_Office_SMARTBIM.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/DWFs/Single-Family_Office_SMARTBIM.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/FPs/Single-Family_Office_SMARTBIM.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/3Ds/Single-Family_Office_SMARTBIM-3D.jpg',
      CreateDate: '2011-05-12 10:31:35 -0400',
      UpdateDate: null,
      SearchDescription: 'Single Family Residential Office',
      category: 'Office',
      manufacturers: [
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 55,
      SmartBIMProjectSpaceID: 57,
      LayoutName: 'Residential Garage',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/Images/Single-Family_Residential_Garage_SMARTBIM.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/RVTs/Single-Family_Residential_Garage_SMARTBIM.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/DWFs/Single-Family_Residential_Garage_SMARTBIM.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/FPs/Single-Family_Residential_Garage_SMARTBIM.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/3Ds/Single-Family_Residential_Garage_SMARTBIM-3D.jpg',
      CreateDate: '2011-05-12 10:43:06 -0400',
      UpdateDate: null,
      SearchDescription: 'Single Family Residential Garage',
      category: 'Residential Garage',
      manufacturers: [
        {
          name: 'Armacell',
          image:
            'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/Armacell_Logo.jpg'
        }
      ]
    },
    {
      SmartBIMSpaceLayoutID: 56,
      SmartBIMProjectSpaceID: 58,
      LayoutName: 'Mudroom',
      ImageURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/Images/Single-Family_Mudroom_SMARTBIM.jpg',
      RevitURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/RVTs/Single-Family_Mudroom_SMARTBIM.zip',
      DwfURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/DWFs/Single-Family_Mudroom_SMARTBIM.dwf',
      FloorPlanURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/FPs/Single-Family_Mudroom_SMARTBIM.jpg',
      RenderingURL:
        'http://spacesfiles-smartbim-com.s3.amazonaws.com/SingleFamily/3Ds/Single-Family_Mudroom_SMARTBIM-3D.jpg',
      CreateDate: '2011-05-12 10:58:07 -0400',
      UpdateDate: null,
      SearchDescription: 'Single Family Residential Mudroom',
      category: 'Mudroom',
      manufacturers: [
        {
          name: 'Kolbe & Kolbe Millwork Co., Inc.',
          image: 'http://files.smartbim.com.s3.amazonaws.com/objects/logos/bpmlogo/kolbe.gif'
        }
      ]
    }
  ]
};
