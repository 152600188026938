import uuid from 'uuid/v4';
import { reducerUtil } from 'base-client';

import reducerData from '../reducerData';

const sendNotifications = notifications => (dispatch, getState) => {
  if (!notifications) return;
  if (!Array.isArray(notifications)) notifications = [notifications];

  notifications = notifications.map(({ id: oldId, ...rest }) => {
    const id = oldId || uuid();
    const timeout = setTimeout(() => {
      dispatch(removeNotification(id));
      clearTimeout(timeout);
    }, 5000);
    return { id, timeout, ...rest };
  });

  const oldNotifications =
    reducerUtil.getSlice(reducerData, reducerData.notifications, getState()) || [];
  dispatch(
    reducerUtil.setSlice(reducerData, reducerData.notifications, [
      ...oldNotifications,
      ...notifications
    ])
  );
};

const removeNotification = id => (dispatch, getState) => {
  const notifications = reducerUtil.getSlice(reducerData, reducerData.notifications, getState());
  const toRemove = notifications.find(({ id: itemId }) => itemId === id);
  if (toRemove) {
    clearTimeout(toRemove.timeout);
    const results = notifications.filter(n => n.id !== id);
    dispatch(reducerUtil.setSlice(reducerData, reducerData.notifications, results));
  }
};

export default { sendNotifications, removeNotification };
