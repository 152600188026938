import React from 'react';
import { List } from 'react-virtualized';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProductCard from './ProductCard';

function HybridView(props) {
  const rowHeight = 240;
  const { products, toggleSelectProduct } = props;

  const HybridViewList = ({ registerChild, ...props }) => {
    return <List {...props} ref={registerChild} />;
  };

  const rowRenderer = ({ index, key, style }) => {
    if (!products) return;

    const product = products[index];
    if (!product) return;

    return <ProductCard {...{ key, style, product, toggleSelectProduct }} />;
  };

  const additionalProps = {
    rowRenderer: rowRenderer,
    rowCount: products ? products.length : 0,
    rowHeight: rowHeight,
    toggleSelectProduct
  };

  return <HybridViewList {...props} {...additionalProps} />;
}

export default withRouter(connect()(HybridView));
