import React from 'react';
import styled from 'styled-components';

import FormInput from '../../shared/FormInput';
import LinkButton from '../../shared/LinkButton';
import ButtonText from '../../shared/ButtonText';
import PoweredConcora from '../../shared/PoweredConcora';
import Body from './Body';
import Header from './Header';
import FlashMessage from './FlashMessage';
import SocialContainer from './SocialContainer';
import Footer from './Footer';
import Agreement from './Agreement';

const SignUpForm = styled.form`
  text-align: center;
`;

const StyledSignUp = styled.div`
  .login-link {
    text-align: center;
    button {
      color: ${props => props.theme.blue};
    }
  }
  .privacyPolicy {
    font-size: 1.1rem;
    text-align: left;
    flex: 0 0 65%;
    a {
      color: ${props => props.theme.blue};
    }
  }
`;

const ButtonAgreement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SignUpButton = styled(LinkButton)`
  flex: 0 0 30%;
`;

const LinkToLogin = styled.p`
  text-align: center;
  font-size: 1.2rem;
  button {
    color: ${props => props.theme.blue};
  }
`;

const SignUp = ({
  headerText,
  isSignUpReady,
  validate,
  updateForm,
  errors,
  googleLogin,
  linkedInLogin,
  onSignup,
  showSuccess,
  showError,
  message,
  toLogin,
  logoText,
  logoImageUrl
}) => (
  <StyledSignUp>

    <Header {...{ headerText }} />
    <LinkToLogin>
      Already have an account? <ButtonText onClick={toLogin}>Login</ButtonText>
    </LinkToLogin>

    {showError ? <FlashMessage hasError messageText={message} /> : null}
    {showSuccess && (
      <React.Fragment>
        <FlashMessage messageText={message} />
        Go back to <ButtonText onClick={toLogin}>login</ButtonText> page
      </React.Fragment>
    )}
    {!showSuccess && (
      <Body>
        <SignUpForm>
          <SocialContainer {...{ googleLogin, linkedInLogin, onSignup }} />

          <FormInput
            labelTitle="Email Address"
            type="email"
            field="email"
            required
            hasError={errors.email && errors.email.hasError}
            onBlur={(value, field) => validate(value, field)}
            onChange={(value, field) => updateForm(value, field)}
            errorMessage={errors.email && errors.email.hasError ? errors.email.message : ''}
          />

          <FormInput
            labelTitle="Password"
            type="password"
            field="password"
            required
            hasError={errors.password && errors.password.hasError}
            onBlur={(value, field) => validate(value, field)}
            onChange={(value, field) => updateForm(value, field)}
            errorMessage={
              errors.password && errors.password.hasError ? errors.password.message : ''
            }
          />

          <FormInput
            labelTitle="Re-enter Password"
            type="password"
            field="reEnterPassword"
            required
            hasError={errors['reEnterPassword'] && errors['reEnterPassword'].hasError}
            onBlur={(value, field) => validate(value, field)}
            onChange={(value, field) => updateForm(value, field)}
            errorMessage={
              errors['reEnterPassword'] && errors['reEnterPassword'].hasError
                ? errors['reEnterPassword'].message
                : ''
            }
          />
          <ButtonAgreement>
            <SignUpButton small marginTop="1rem" marginBottom="1rem" onClick={() => onSignup()}>
              Sign Up
            </SignUpButton>
            <p className="privacyPolicy">
              <Agreement />
            </p>
          </ButtonAgreement>
        </SignUpForm>

        <Footer>
          <PoweredConcora margin="1rem 0 0" />
        </Footer>
      </Body>
    )}
  </StyledSignUp>
);

export default SignUp;
