import { api, reducerUtil } from 'base-client';
import uuid from 'uuid/v4';
import analyticsActions from '../../Analytics/actions';
import errorsActions from '../../Errors/actions';

import { projectSubmittal } from 'components/Notifications/constants';
import notificationActions from 'components/Notifications/actions';
import detailsData from 'components/ProjectDetails/reducerData';

const updateProject = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { [detailsData.name]: form } = state.form || {};
    const { values } = form || {};

    const project = await dispatch(api.actions.patch(`projects/${values.id}`, { ...values }, true));

    await Promise.all(
      ['contractor', 'general_contractor', 'architect', 'distributor'].map(async o => {
        try {
          project[o] = await dispatch(
            api.actions.put(`projects/${values.id}/participants/${o}`, { ...values[o] }, true)
          );
        } catch (error) {
          project[o] = {};
        }
      })
    );

    dispatch(reducerUtil.setSlice(detailsData, detailsData.generalData, project));
    dispatch(
      notificationActions.sendNotifications([
        {
          id: `saved-project-changes-${uuid()}`,
          type: projectSubmittal.GENERAL,
          title: 'Saved Project Changes',
          body: 'Your project changes have been saved'
        }
      ])
    );

    // send analytics
    const { id, name } = project;
    dispatch(analyticsActions.track('projectupdate', { project_id: id, name }));
  } catch (error) {
    dispatch(errorsActions.error(error));
  }
};

const isUnique = name => async (dispatch, getState) => {
  const state = getState();
  const userId = dispatch(analyticsActions.getUserId());
  try {
    return await dispatch(
      api.actions.post(
        'projects/exists',
        {
          name
        },
        true
      )
    );
  } catch (error) {
    dispatch(errorsActions.error(error));
  }
};

export default {
  updateProject,
  isUnique
};
