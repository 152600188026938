import React from 'react';
import styled, { css } from 'styled-components';
import {
  inverseButtonStyles,
  transparentButtonStyles,
  dangerButtonStyles,
  rightArrowStyles
} from './styles/buttonStyles';

const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  letter-spacing: 0.2px;
  text-align: center;
  min-width: 7rem;
  appearance: none;
  position: relative;
  transition: color 0.1s ease-in-out, background 0.1s ease-in-out;
  padding: ${props => (props.noPadding ? '0' : props.padding || '0 1.5rem 0.1rem')};
  margin: ${props => props.margin || '0'};
  height: ${props => {
    if (props.medium) return '4rem';
    return '3rem';
  }};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '3px')};
  background-color: ${props =>
    props.bgColor
      ? props.bgColor
      : props.theme.primaryColor
        ? props.theme.primaryColor
        : props.theme.blue};
  color: ${props =>
    props.color ? props.color : props.secondary ? props.theme.grey3 : props.theme.white};
  font-family: ${props => props.theme.fontBody};
  font-size: ${props => {
    if (props.big) return '2rem';
    if (props.medium) return '1.6rem';
    if (props.small) return '1.2rem';
    return '1.4rem';
  }};
  font-weight: ${props =>
    props.normalText ? 'normal' : props.fontWeight ? props.fontWeight : '600'};
  text-transform: ${props => (props.noUpperCase ? 'none' : 'uppercase')};
  ${props => {
    return (
      props.fullWidth &&
      css`
        width: 100%;
      `
    );
  }}
  ${props => props.inverse && inverseButtonStyles}
  ${props => props.transparent && transparentButtonStyles}
  ${props => props.danger && dangerButtonStyles}
  ${props => props.rightArrow && rightArrowStyles}
  &:hover:not[disabled],
  &:focus {
    color: ${props => (props.secondary ? props.theme.grey4 : props.theme.white)};
    background: ${props => (props.secondary ? props.theme.grey2 : props.theme.blueDarkest)};
    outline: none;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledButtonText = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  font-size: ${props => {
    if (props.big) return '2rem';
    if (props.medium) return '1.6rem';
    if (props.small) return '1.2rem';
    return '1.4rem';
  }};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  text-transform: ${props => (props.upperCase ? 'uppercase' : 'none')};
  color: ${props => props.color || 'inherit'};
  height: ${props => {
    if (props.medium) return '4rem';
    return '3rem';
  }};
  ${props => {
    return (
      props.fullWidth &&
      css`
        width: 100%;
      `
    );
  }}
  &:hover {
    color: ${props => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
  }
  &.active {
    color: ${props =>
    props.activeColor
      ? props.activeColor
      : props.theme.primaryColor
        ? props.theme.primaryColor
        : props.theme.blue};
  }
`;

const Button = ({ id, btnLink, type, disabled, className, onClick, ...props }) => {
  const handleClick = event => {
    event.preventDefault();
    onClick && onClick(event);
  };

  if (btnLink)
    return (
      <StyledButtonText
        type={type || 'button'}
        onClick={handleClick}
        {...props}
        className={className}
      />
    );

  return (
    <StyledButton
      id={id}
      type={type || 'button'}
      className={disabled ? className + ' not-active' : className}
      onClick={handleClick}
      {...props}
    />
  );
};
export default Button;
