import React from 'react';
import styled from 'styled-components';

import LoginContainer from './LoginContainer';
import SignUpContainer from './SignUpContainer';

const Container = styled.div`
  margin: 8.4rem auto;
  border: 1px solid ${props => props.theme.grey2};
  border-radius: 3px;
  background: white;
  position: relative;
  max-width: 35rem;
  width: 100%;
  padding: 2rem;
`;

class LoginSignUpContainer extends React.Component {
  state = {};

  toLogin = () => this.setState({ signUp: undefined });
  toSignUp = () => this.setState({ signUp: true });

  render() {
    const { ...props } = this.props;

    const { signUp } = this.state;

    const toSignUp = this.toSignUp;
    const toLogin = this.toLogin;
    const logoText = 'Concora Library';
    const logoImageUrl = 'concora-favicon.png';

    return (
      <React.Fragment>
        <Container>
          {signUp ? (
            <SignUpContainer {...{ logoText, logoImageUrl, toLogin }} />
          ) : (
            <LoginContainer {...{ logoText, logoImageUrl, toSignUp }} />
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default LoginSignUpContainer;
