export default [
  'Construction',
  'Architecture',
  'Manufacturer',
  'Engineering',
  'Design',
  'Education',
  'Building Owner',
  'Government'
];
