import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { history } from 'base-client';
import moment from 'moment';
import 'moment-timezone';

import App from './App';
import store from './store';

// Importing before our global styles to insure we are always able to override.
import 'react-virtualized/styles.css';
import 'react-tabs/style/react-tabs.css';

// Inject global styles
import './global-styles';

// determine timezone
moment.tz.guess();

ReactDOM.render(<App {...{ store, history }} />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
