import React from 'react';
import { NavLink } from 'react-router-dom';
import ExternalLink from './ExternalLink';

const DisplayLink = ({ url, children, ...props }) => {
  return url.startsWith('/') ? (
    <NavLink to={url} {...props}>
      {children}
    </NavLink>
  ) : (
    <ExternalLink href={url} {...props}>
      {children}
    </ExternalLink>
  );
};

export default DisplayLink;
