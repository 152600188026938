import { reducerUtil, createStore } from 'base-client';
import { reducer as form } from 'redux-form';

import search from './components/ProductSearch/reducerData';
import errors from './components/Errors/reducerData';
import userAccount from './components/UserProfile/reducerData';
import projects from './components/Projects/reducerData';
import projectDetails from './components/ProjectDetails/reducerData';
import notifications from './components/Notifications/reducerData';
import productComparison from './components/ProductComparison/reducerData';

const store = createStore({
  form,
  [errors.name]: reducerUtil.create(errors),
  [search.name]: reducerUtil.create(search),
  [userAccount.name]: reducerUtil.create(userAccount),
  [projects.name]: reducerUtil.create(projects),
  [productComparison.name]: reducerUtil.create(productComparison),
  [notifications.name]: reducerUtil.create(notifications),
  [projectDetails.name]: reducerUtil.create(projectDetails)
});

export default store;
