import React, { Component } from 'react';

class ClampLines extends Component {
  constructor(props) {
    super(props);

    this.element = null;
    this.original = props.text;
    this.watch = true;
    this.lineHeight = props.lineHeight || 20;
    this.start = 0;
    this.middle = 0;
    this.end = 0;
    this.lines = props.lines;

    this.state = {
      noClamp: false,
      text: ''
    };

    // If window is undefined it means the code is executed server-side
    this.ssr = typeof window === 'undefined';

    if (!this.ssr) {
      this.debounced = this.debounce(this.action, props.delay);
    }
  }

  componentDidMount() {
    if (this.props.text && !this.ssr) {
      this.clampLines();

      if (this.watch) {
        window.addEventListener('resize', this.debounced);
      }
    }
  }

  componentWillUnmount() {
    if (!this.ssr) {
      window.removeEventListener('resize', this.debounced);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.original = this.props.text;

      this.clampLines();
    }
  }

  debounce(func, wait, immediate) {
    let timeout;

    return () => {
      const context = this,
        args = arguments;
      const later = () => {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  action = () => {
    if (this.watch) {
      this.setState({
        noClamp: false
      });
      this.clampLines();
    }
  };

  getMaxHeight = () => {
    if (this.props.lineHeight && this.props.lines) {
      return this.lineHeight * this.lines;
    }
    return 0;
  };

  clampLines = () => {
    if (!this.element) return;

    if (this.state.text) {
      this.setState({
        text: ''
      });
    }

    const maxHeight = this.props.maxHeight || this.getMaxHeight();
    if (maxHeight < this.lineHeight) return;

    this.start = 0;
    this.middle = 0;
    this.end = this.original.length;

    while (this.start <= this.end) {
      this.middle = Math.floor((this.start + this.end) / 2);
      this.element.innerText = this.original.slice(0, this.middle);
      if (this.middle === this.original.length) {
        this.setState({
          text: this.original,
          noClamp: true
        });
        return;
      }

      this.moveMarkers(maxHeight);
    }

    const truncatedText = this.original.slice(0, this.middle - 5) + this.getEllipsis();

    this.element.innerText = truncatedText;
    this.setState({
      text: truncatedText
    });
  };

  moveMarkers = maxHeight => {
    if (this.element.clientHeight <= maxHeight) {
      this.start = this.middle + 1;
    } else {
      this.end = this.middle - 1;
    }
  };

  getClassName = () => {
    return `clamp-lines ${this.props.className || ''}`;
  };

  getEllipsis = () => {
    return this.watch && !this.state.noClamp && '...';
  };

  render() {
    if (!this.props.text) {
      return null;
    }

    const { text } = this.state;
    const className = this.getClassName();

    return (
      <div
        className={className}
        ref={e => {
          this.element = e;
        }}
      >
        {text}
      </div>
    );
  }
}

export default ClampLines;
