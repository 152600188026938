import React from 'react';
import ErrorDisplay from './ErrorDisplay';

const ErrorDisplayNoConnection = () => (
  <ErrorDisplay
    header="No internet connection detected."
    content="Please check your network connection and try again."
    imageUrl="/contents/network.svg"
  />
);

export default ErrorDisplayNoConnection;
