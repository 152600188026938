import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { config, login, reducerUtil } from 'base-client';

import configMap from 'configurations/configMap';

import Button from 'shared/Button';
import Tooltip from 'shared/Tooltip';
import projectData from '../reducerData';
import projectActions from '../actions';

const Wrapper = styled.div`
  &.hasTooltip {
    position: relative;
    &:hover {
      .tooltip {
        opacity: 1;
      }
    }
  }
`;

const AddToProjectButton = ({ products, token, modalOpen, dispatch, children, ...btnProps }) => {
  return (
    <Wrapper className={!token ? 'addToProjectbtn hasTooltip' : 'addToProjectbtn'}>
      <Button
        className="project"
        disabled={!token}
        onClick={() => dispatch(projectActions.openModal(products))}
        {...btnProps}
      >
        {children || 'Add to a Project'}
      </Button>
      {!token ? <Tooltip>Login to add to a project</Tooltip> : null}
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
  modalOpen: reducerUtil.getSlice(projectData, projectData.modalOpen, state)
});

export default connect(mapStateToProps)(AddToProjectButton);
