import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { history, reducerUtil } from 'base-client';
import { NavLink, withRouter } from 'react-router-dom';
import { config } from 'base-client';
import configMap from '../../configurations/configMap';

import UserDropdown from './UserDropdown';
import HeaderLinks from './HeaderLinks';
import SearchField from '../ProductSearch/SearchField';

import logo from '../../static/logo.svg';
import ProjectButton from 'components/Projects/components/ProjectButton';

const AppHeader = styled.header`
  background: #ffffff;
  color: ${props => props.theme.grey3};
  box-shadow: 0 1px 4px ${props => props.theme.grey2};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .dropdown {
    font-size: 1.4rem;
    button {
      &:after {
        border-color: ${props => props.theme.grey3};
      }
      &:hover {
        color: inherit;
      }
    }
    .visible {
      color: ${props => props.theme.grey3};
      background: #ffffff;
      &:before {
        background: #ffffff;
      }
      a:hover {
        color: inherit;
      }
    }
  }
  &.hiddenMenu {
    .toggleMenu {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
`;

const HeaderLeft = styled.div`
  flex: 0 0 60%;
  display: flex;
  flex-direction: column;
`;

const HeaderRight = styled.div`
  flex: 0 0 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.4rem;
  & > * {
    margin-left: 2rem;
  }
`;

const NavOne = styled.div`
  display: flex;
  margin: 0.5rem 0;
`;

const NavTwo = styled.div`
  margin-top: 1rem;
`;

const LogoLink = styled(NavLink)`
  display: flex;
  align-items: center;
`;

const LogoImg = styled.img`
  max-height: 2.5rem;
`;

const HeaderContainer = ({ className, dispatch, name, activeIndex, ...props }) => {
  const projectsEnabled = dispatch(
    config.actions.getData(configMap.projects.name, configMap.projects.enabled.name)
  );
  return (
    <AppHeader className={className || ''}>
      <Wrapper>
        <HeaderLeft>
          <NavOne>
            <LogoLink to="/">
              <LogoImg src={logo} alt={name} />
            </LogoLink>
            <SearchField />
          </NavOne>
          <NavTwo className="toggleMenu">
            <HeaderLinks {...{ activeIndex }} />
          </NavTwo>
        </HeaderLeft>
        <HeaderRight>
          {projectsEnabled && <ProjectButton {...{ activeIndex }} />}
          <UserDropdown {...{ activeIndex }} />
        </HeaderRight>
      </Wrapper>
    </AppHeader>
  );
};

const mapStateToProps = state => {
  const activeIndex = history.location.pathname;
  return { activeIndex };
};

export default withRouter(connect(mapStateToProps)(HeaderContainer));
