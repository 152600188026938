import React from 'react';

import ProjectTabGroup from './ProjectTabGroup';
import ProjectTabContent from './ProjectTabContent';

const TabProjectDetails = ({ project_number, estimated_ship_date, notes, address, ...props }) => {
  const { address_line_1, address_line_2, city, state, zipcode, country } = address || {};
  return (
    <ProjectTabContent {...props}>
      <ProjectTabGroup>
        <strong>Project Number</strong>
        <span>{project_number || 'N/A'}</span>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <strong>Address Line 1</strong>
        <span>{address_line_1 || 'N/A'}</span>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <strong>Address Line 2</strong>
        <span>{address_line_2 || 'N/A'}</span>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <div className="display-inline-block pad-right-15">
          <strong>City</strong>
          <span>{city || 'N/A'}</span>
        </div>
        <div className="display-inline-block">
          <strong>State</strong>
          <span>{state || 'N/A'}</span>
        </div>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <div className="display-inline-block pad-right-15">
          <strong>Zip Code</strong>
          <span>{zipcode || 'N/A'}</span>
        </div>
        <div className="display-inline-block">
          <strong>Country</strong>
          <span>{country || 'N/A'}</span>
        </div>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <strong>Estimated Ship Date</strong>
        <span>{estimated_ship_date || 'N/A'}</span>
      </ProjectTabGroup>
      <ProjectTabGroup>
        <strong>Project Notes</strong>
        <span>{notes || 'N/A'}</span>
      </ProjectTabGroup>
    </ProjectTabContent>
  );
};

export default TabProjectDetails;
