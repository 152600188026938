import React from 'react';
import styled from 'styled-components';

import buttonStyles from './styles/buttonStyles';

export const Container = styled.button`
  ${buttonStyles} {
  }
`;

const LinkButton = ({ children, onClick, disabled, className, ...props }) => {
  const submitClick = event => {
    event.preventDefault();
    onClick(event);
  };

  return (
    <Container
      {...{ disabled }}
      {...props}
      className={disabled ? className + ' not-active' : className}
      onClick={submitClick}
    >
      {children}
    </Container>
  );
};

export default LinkButton;
