import React, { useState } from 'react';
import { Field } from 'redux-form';

import states from 'utils/staticData/states';
import countries from 'utils/staticData/countries';
import { positiveNumber, required } from 'utils/reduxFormValidator';

import { Row, Column } from 'shared-features-client';

import ReduxFormTextArea from 'shared/ReduxFormTextArea';
import FormDropdownOption from 'shared/FormDropdownOption';
import ReduxFormDropdown from 'shared/ReduxFormDropdown';
import ReduxFormInput from 'shared/ReduxFormInput';

const TabProjectEdit = ({
  form,
  pristine,
  valid,
  submitting,
  onClick,
  project_number,
  address,
  estimated_ship_date,
  notes,
  ...props
}) => {
  const [_country, setCountry] = useState(0);
  return (
    <React.Fragment>
      <Row>
        <Column span={6}>
          <Field
            name="project_number"
            type="text"
            component={ReduxFormInput}
            label="project_number"
            labelTitle="Project Number"
            validate={[positiveNumber]}
          />
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <Field
            name="address.address_line_1"
            type="text"
            component={ReduxFormInput}
            label="address_line_1"
            labelTitle="Address Line 1"
          />
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <Field
            className="full-width"
            name="address.address_line_2"
            type="text"
            component={ReduxFormInput}
            label="address_line_2"
            labelTitle="Address Line 2"
          />
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <Field
            name="address.city"
            type="text"
            component={ReduxFormInput}
            label="city"
            labelTitle="City"
          />
        </Column>
        <Column span={6}>
          <Field
            name={'address.country'}
            type="text"
            component={ReduxFormDropdown}
            labelTitle="Country"
            onChange={event => setCountry(event.target.value)}
          >
            <FormDropdownOption value={''} optionLabel={'Select a Country'} disabled />
            {countries.map(item => (
              <FormDropdownOption key={item.name} value={item.name} optionLabel={item.name} />
            ))}
          </Field>
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <Field
            name="address.zipcode"
            type="text"
            component={ReduxFormInput}
            label="zipcode"
            labelTitle="Zip Code"
            validate={[required]}
            required
          />
        </Column>
        <Column span={6}>
          {_country === 'United States' ? (
            <Field
              name={'address.state'}
              type="text"
              component={ReduxFormDropdown}
              labelTitle="State"
            >
              <FormDropdownOption value={''} optionLabel={'Select a State'} disabled />
              {states.map(item => (
                <FormDropdownOption
                  key={item.abbreviation}
                  value={item.abbreviation}
                  optionLabel={item.abbreviation}
                />
              ))}
            </Field>
          ) : (
            <Field
              name={'address.state'}
              type="text"
              component={ReduxFormInput}
              labelTitle="State/Provice/Regions"
            />
          )}
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <Field
            name="estimated_ship_date"
            type="date"
            component={ReduxFormInput}
            label="estimated_ship_date"
            labelTitle="Estimated Ship Date"
          />
        </Column>
      </Row>
      <Row>
        <Column span={12}>
          <Field
            className="full-width"
            name="notes"
            type="text"
            component={ReduxFormTextArea}
            label="notes"
            labelTitle="Project Notes"
            rows={4}
            resize="vertical"
          />
        </Column>
      </Row>
    </React.Fragment>
  );
};

export default TabProjectEdit;
