import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import ButtonText from './ButtonText';

const DropdownContainer = styled.div``;

const MenuItem = styled(ButtonText)`
  padding: 0 1.5rem 0.75rem;
  margin-right: 1rem;
  transition: color 0.5s ease;
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
  outline: none;
  &:after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    position: relative;
    top: -1px;
    right: -1.6rem;
    border: none;
    border-right: 2px solid grey;
    border-top: 2px solid grey;
    transform: rotate(135deg);
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  right: -1rem;
  top: 3.5rem;
  padding: 1rem;
  background: #fff;
  border-radius: 3px;
  border: 1px solid grey;
  font-size: 1.4rem;
  &:before {
    content: '';
    position: absolute;
    right: 1rem;
    top: -0.4rem;
    width: 1rem;
    height: 1rem;
    background: #fff;
    border-radius: 3px;
    transform: rotate(45deg);
    box-shadow: -1px -1px 0px grey;
  }
  &.visible {
    display: block;
  }
`;

function Dropdown({ text, children }) {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);


  return (
    <DropdownContainer ref={node} className="dropdown">
      <MenuItem className="dropdown-toggler" onClick={e => setOpen(!open)}>
        {text}
      </MenuItem>
      {open && (
        <DropdownContent className={open ? 'visible' : ''}>{children}</DropdownContent>
      )}
    </DropdownContainer>
  );
}

export default Dropdown;
