import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ClampLines from '../../shared/ClampLines';
import HeaderAssets from '../../shared/HeaderAssets';
import Card from '../../shared/Card';
import Checkbox from '../../shared/Checkbox';
import Callout from './Callout';
import { reducerUtil } from 'base-client';
import searchData from './reducerData';
import _ from 'lodash';

const Link = styled.a``;

const CardWrapper = styled(Card)`
  height: 100%;
  padding: 2rem;
  background: #ffffff;
`;

const Container = styled.div`
  padding: 0 0 2rem;
  height: 20rem;
  .header-assets {
    flex: 0 0 100%;
    margin-top: 0.5rem;
    position: relative;
    a {
      &:hover{
        > div{
          opacity: 1;
        }
      }
      span {
        font-size: 1.3rem;
        text-transform: none;
      }
    }
  .callout:nth-child(4) {
    margin-bottom: 0;
  }
`;
const ImageLink = styled(Link)`
  width: 20%;
  height: 100%;
  float: left;
`;

const ImageNoLink = styled.div`
  width: 20%;
  height: 100%;
  float: left;
`;

const ImageContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
`;
const Info = styled.div`
  padding: 0 2rem 0 4rem;
  width: 40%;
  float: left;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Name = styled.h2`
  font-size: 1.8rem;
  font-family: ${props => props.theme.fontAlt};
  margin: 0.3rem 0 0;
  font-weight: 800;
`;
const Description = styled.div`
  font-size: 1.4rem;
  line-height: 1.45;
  margin: 1.5rem 0;
  height: 100%;
  &.red-text {
    color:${props => props.theme.red};
  }
`;
const Specs = styled.div`
  width: 40%;
  float: left;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2rem;
`;
const AssetsTitle = styled.h3`
  letter-spacing: 0.3px;
  color: ${props => props.theme.grey3};
  position: relative;
  margin-top: 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;
/**
 * Function for creating a component representing a single item in the hybrid view.
 * @return {React.Element} Representation of a single product in hybrid view.
 */
class HybridCard extends Component {
  descRef = React.createRef();
  state = {
    descHeight: 0
  };
  componentDidMount() {
    const { current } = this.descRef;
    current && this.setState({ descHeight: current.clientHeight });
  }
  // routeChange() {
  //   const { product, brandedSites } = this.props;
  //   const { id, manufacturer_id } = product;
  //   const redirect = process.env.REACT_APP_MANUFACTURERS_REDIRECT_DOMAIN || 'concora.com';
  //   const targetDomain = brandedSites && brandedSites[manufacturer_id];
  //   if (targetDomain && targetDomain.domains[0]) {
  //     const url = `https://${targetDomain.domains[0]}.${redirect}/products/${id}`;
  //     window.open(url, '_blank');
  //   }
  // }
  render() {
    const { style, product, brandedSites, toggleSelectProduct } = this.props;

    const {
      id,
      manufacturer_id,
      name,
      callouts = [],
      description,
      thumbnail,
      checked,
      assets = []
    } = product;
    const redirect = process.env.REACT_APP_MANUFACTURERS_REDIRECT_DOMAIN || 'concora.com';
    const targetDomain = brandedSites && brandedSites[manufacturer_id];
    const url = `https://${targetDomain.domains[0]}.${redirect}/products/${id}`;
    const { descHeight } = this.state;

    return (
      <Container {...{ style }} className="tutorial-step-4">
        <CardWrapper>
          <Checkbox
            label="Select"
            id={id}
            value={id}
            checked={checked}
            className="tutorial-step-5"
            onChange={() => toggleSelectProduct(product)}
          />
          <ImageLink href={url} target="_blank" rel="noopener noreferrer">
            <ImageContainer>
              <Image src={thumbnail} />
            </ImageContainer>
          </ImageLink>
          <Info>
            <Link href={url} target="_blank" rel="noopener noreferrer">
              <Name>
                <ClampLines text={name || ''} lineHeight={23} lines={5} />
              </Name>
            </Link>
            <Description ref={this.descRef}>
              {descHeight > 0 && <ClampLines text={description || ''} maxHeight={descHeight} />}
            </Description>
          </Info>
          <Specs>
            {callouts.map(({ id, ...props }, index) => (
              <Callout key={id || index} {...props} />
            ))}
            <HeaderAssets {...{ assets }}>
              <AssetsTitle>Links</AssetsTitle>
            </HeaderAssets>
          </Specs>
        </CardWrapper>
      </Container>
    );
  }
}
const mapStateToProps = state => ({
  brandedSites: reducerUtil.getSlice(searchData, searchData.brandedSites, state)
});

export default connect(mapStateToProps)(HybridCard);
