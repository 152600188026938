import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { reducerUtil } from 'base-client';

import FormDropdown from '../../../shared/FormDropdown';
import FormDropdownOption from '../../../shared/FormDropdownOption';
import searchData from '../reducerData';
import searchActions, { querySort } from '../actions';

const Container = styled.div`
  display: flex;
  align-items: baseline;
`;

const Label = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 0.3px;
  color: #77899e;
  padding-right: 1rem;
`;

class SortContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    queryParams: PropTypes.shape({
      sortBy: PropTypes.string,
      sortDir: PropTypes.string
    }),
    sortList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        key: PropTypes.string,
        sortBy: PropTypes.string,
        sortDir: PropTypes.string
      })
    )
  };

  static defaultProps = {
    queryParams: {},
    sortList: []
  };

  handleChange = event => {
    const { dispatch } = this.props;
    const {
      target: { value }
    } = event;
    dispatch(searchActions.setSort(value));
  };

  render() {
    const { queryParams, sortList } = this.props;
    const { sortBy, sortDir } = queryParams;
    const sortType =
      sortList.find(item => item.sortBy === sortBy && item.sortDir === sortDir) || sortList[0];
    return (
      <Container>
        <Label>Sort By</Label>
        <FormDropdown noBorder value={sortType.name} onChange={this.handleChange}>
          {sortList.map(({ name }) => (
            <FormDropdownOption key={name} value={name} optionLabel={name} />
          ))}
        </FormDropdown>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryParams: reducerUtil.getSlice(searchData, searchData.query, state),
    sortList: querySort
  };
};

export default connect(mapStateToProps)(SortContainer);
