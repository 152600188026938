import React from 'react';
import styled from 'styled-components';
import { sortBy } from 'lodash';

import Card from 'shared/Card';
import { PRODUCT_HIDDEN_SECTIONS } from 'components/ProductDetails/utils';
import CellDisplay from './CellDisplay';
import { emptySymbol } from '../utils';

const Wrapper = styled.div``;
const Header = styled.h3`
  font-size: 16px;
  text-transform: uppercase;
  color: ${props => props.theme.grey3};
  font-weight: 600;
`;
const Table = styled(Card)`
  .table-cell {
    padding: 0 1rem 0 0;
  }
  &.table-3cols {
    .table-cell {
      width: calc(100% / 3);
    }
  }
  &.table-4cols {
    .table-cell {
      width: 25%;
    }
  }
  &.table-5cols {
    .table-cell {
      width: 20%;
    }
  }
`;
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  border-bottom: 1px solid ${props => props.theme.grey2};
  &:last-child {
    border-bottom: none;
  }
  .table-cell:first-child {
    padding-left: 2rem;
    font-weight: 500;
  }
`;
const Cell = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  word-break: break-word;
`;

const getProductInformation = products => {
  const results = [
    { label: 'Brand', values: products.map(({ brand }) => brand || emptySymbol), type: 'text' },
    {
      label: 'Description',
      values: products.map(({ description }) => description || emptySymbol),
      type: 'text'
    }
  ].filter(({ values }) => values && values.some(val => val && val !== emptySymbol));

  const totalProducts = products.length;

  products.map((product, index) => {
    const { attachments = [] } = product;
    const information = (product.productInfo && product.productInfo.information) || [];

    const getValue = ({ attributeType, attributeValue }) => {
      if (attributeType !== 'assetselection') return attributeValue;
      const attachment = attachments.find(({ id }) => id === attributeValue) || {};
      return attachment.downloadLocation;
    };

    information
      .filter(item => !PRODUCT_HIDDEN_SECTIONS.includes(item.attributeSection) && !!getValue(item))
      .forEach(item => {
        const { attributeName: label, attributeType: type } = item;
        const value = getValue(item);

        const existIndex = results.findIndex(({ label: title }) => title === label);
        if (existIndex < 0) {
          const values = new Array(totalProducts).fill(emptySymbol);
          values[index] = value;
          const newItem = {
            label,
            values: [...values],
            type
          };
          results.push(newItem);
        } else {
          results[existIndex].values[index] = value;
        }
      });
  });

  return sortBy(results, ['label']);
};

const ProductInformation = ({ headerText, products }) => {
  if (!products || (products && products.length < 1)) return null;

  const productsData = getProductInformation(products);
  const numberCols = products.length + 1; // +1 for label column

  return (
    <Wrapper>
      <Header>{headerText}</Header>
      <Table className={`table-${numberCols}cols`}>
        {productsData.map(item => {
          const { label, values, type } = item;
          return (
            <Row className="table-row" key={label}>
              <Cell className="table-cell" key={label}>
                {label}
              </Cell>
              {values.map((value, index) => (
                <CellDisplay {...{ value, index, type }} />
              ))}
            </Row>
          );
        })}
      </Table>
    </Wrapper>
  );
};

export default ProductInformation;
