import React from 'react';
import styled, { css } from 'styled-components';

export const LabelContainer = styled.label`
  color: ${props => 'grey'};
  font-weight: 600;
  font-size: 1.4rem;
  display: block;
  margin-bottom: 0.7rem;
  ${({ required }) =>
    required &&
    css`
      &:after {
        content: '*';
        margin-left: 0.5rem;
        color: ${props => 'red'};
      }
    `};
`;

const FormLabel = ({ required, labelTitle }) => (
  <LabelContainer {...{ required }}>{labelTitle}</LabelContainer>
);

export default FormLabel;
