import React from 'react';
import styled from 'styled-components';

import media from '../../utils/media';

import NavigationCard from './NavigationCard';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  margin: 0 -1% -3rem;
  ${media.medium`
    top: -2rem;
    margin-bottom: -2rem;
  `};
  ${media.large`
    top: -4rem;
    margin-bottom: -4rem;
  `};
`;

const Navigations = ({ navigationTiles }) => {
  const displayList =
    navigationTiles.length > 4 ? navigationTiles.slice(0, 4) : [...navigationTiles];
  return (
    <Container className="tutorial-step-1">
      {displayList.map((navigation, index) => (
        <NavigationCard key={index} {...navigation} />
      ))}
    </Container>
  );
};

export default Navigations;
