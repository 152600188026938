import React from 'react';

import { sbBranding } from 'global-styles';
import Button from 'shared/Button';
import ConfirmModal from 'shared/ConfirmModal';
import RightBlock from 'components/Projects/components/RightBlock';

const TEXTS = {
  title: 'Project Tools',
  description: 'Use these tools to edit your project data or delete this project entirely.',
  editProjectButton: 'Edit Project',
  deleteProjectButton: 'Delete Project',
  deleteMessage: 'Deleting a project cannot be undone'
};

const ProjectTools = ({ id, name, editProject, deleteProject }) => {
  const btnCustomStyles = {
    fullWidth: true,
    upperCase: true,
    medium: true,
    fontWeight: 600,
    color: sbBranding.grey3
  };

  return (
    <RightBlock title={TEXTS.title} description={TEXTS.description}>
      <Button
        medium
        fullWidth
        bgColor={sbBranding.grey7}
        color={sbBranding.grey5}
        onClick={editProject}
      >
        {TEXTS.editProjectButton}
      </Button>
      <ConfirmModal
        title={`Delete ${name}`}
        messages={TEXTS.deleteMessage}
        onConfirm={deleteProject}
        confirmBtnText={TEXTS.deleteProjectButton}
        btnCustomStyles={btnCustomStyles}
      />
    </RightBlock>
  );
};

export default ProjectTools;
