import React from 'react';
import styled from 'styled-components';

import { StyledSideContent } from 'shared/styles';
import Button from 'shared/Button';

const Header = styled.div``;

const Title = styled.h3`
  font-size: 1.8rem;
  line-height: 2.3rem;
  font-weight: bold;
  margin: 0;
  color: ${props => props.theme.grey5};
`;

const Description = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: normal;
  color: ${props => props.theme.grey5};
`;

const Body = styled.div`
  margin-top: 2rem;
  button {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledButton = styled(Button)`
  width: initial;
  border: 1px solid ${props => props.theme.purple1};
`;

const SideDetails = ({ title, description, buttons = [], children, onClick }) => (
  <StyledSideContent className="sideContent">
    <Header>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </Header>
    <Body>
      {buttons.map(({ buttonText, onClick }, index) => (
        <StyledButton
          key={index}
          onClick={() => onClick()}
          color={props => props.theme.purple1}
          bgColor="transparent"
          margin="0 2rem 0 0"
          fullWidth
          noUpperCase
        >
          {buttonText}
        </StyledButton>
      ))}
      {children}
    </Body>
  </StyledSideContent>
);

export default SideDetails;
