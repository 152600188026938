import React from 'react';
import styled from 'styled-components';

import { Row, Column } from 'shared-features-client';

import Contact from './Contact';
import ProjectTabContent from './ProjectTabContent';

const Vendor = styled.h4`
  margin: 0 0 5px;
  font-size: 1.4rem;
`;

const VendorBlock = styled(Row)`
  && {
    margin-bottom: 3rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const TabContactInformation = ({
  architect,
  contractor,
  general_contractor,
  distributor,
  ...props
}) => {
  return (
    <ProjectTabContent {...props}>
      <VendorBlock>
        <Column span={4}>
          <Vendor>Contractor Information</Vendor>
          <Contact {...(contractor || {})} />
        </Column>
        <Column span={4}>
          <Vendor>Architect Information</Vendor>
          <Contact {...(architect || {})} />
        </Column>
      </VendorBlock>
      <VendorBlock>
        <Column span={4}>
          <Vendor>Distributor Information</Vendor>
          <Contact {...(distributor || {})} />
        </Column>
        <Column span={4}>
          <Vendor>GC Information</Vendor>
          <Contact {...(general_contractor || {})} />
        </Column>
      </VendorBlock>
    </ProjectTabContent>
  );
};

export default TabContactInformation;
