import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import Button from 'shared/Button';

const FormGroup = styled.div`
  width: 100%;
  padding: 1.5rem 0 2rem;
  display: inline-block;
  width: 80%;
  &:first-child {
    padding-top: 0;
  }
  &.options {
    label {
      &:first-child {
        margin-right: 2rem;
      }
    }
  }
`;

const ErrorMessage = styled.span`
  color: ${props => props.theme.red};
  margin-top: 0.5rem;
  display: block;
  width: 100%;
  font-size: 1.3rem;
`;

const selectCustomStyles = { menu: provided => ({ ...provided, position: 'initial' }) };

const AddProductsToProject = ({ projects, handleAddProducts }) => {
  if (!projects) return null;

  const initialState = {
    selectedProject: projects[0] || null,
    errorMessage: undefined
  };

  const [state, setState] = useState(initialState);

  const addToProject = () => {
    const { selectedProject } = state;

    if (selectedProject && selectedProject.id) {
      handleAddProducts(selectedProject);
    } else {
      setState({
        ...state,
        errorMessage: 'Please select a project.'
      });
    }
  };

  const { selectedProject, errorMessage } = state;
  const selectOptions = !projects ? [] : projects.map(({ id, name }) => ({ id, name }));

  return (
    <form noValidate>
      <FormGroup>
        <Select
          className="projectList"
          styles={selectCustomStyles}
          options={selectOptions}
          placeholder="Select project..."
          value={selectedProject}
          defaultValue={selectedProject}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          noOptionsMessage={() => 'No project'}
          onChange={project => {
            setState({
              ...state,
              selectedProject: project
            });
          }}
        />
        {!errorMessage ? null : <ErrorMessage>{errorMessage}</ErrorMessage>}
      </FormGroup>
      <FormGroup>
        <Button small noUpperCase rightArrow type="submit" onClick={addToProject}>
          Add to Project
        </Button>
      </FormGroup>
    </form>
  );
};

export default AddProductsToProject;
