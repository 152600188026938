import React from 'react';
import styled from 'styled-components';

import FormLabel from './FormLabel';
import ReduxFormError from './ReduxFormError';

const Container = styled.div`
  margin-bottom: 1.5rem;
  // text-align: left;
  margin-top: ${props => (props.labelTitle ? null : '-1rem')};
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem;
  resize: ${props => (props.resize ? props.resize : null)};
  color: ${props => props.theme.grey6};
  border: 1px solid ${props => props.theme.grey2};
  font-family: inherit;
  font-size: 1.4rem;
  border-radius: 3px;
`;

const ReduxFormTextArea = ({
  className,
  required,
  labelTitle,
  placeholder,
  meta,
  input,
  resize,
  rows
}) => (
  <Container {...{ labelTitle, className }}>
    {labelTitle && <FormLabel {...{ required, labelTitle }} />}
    <TextArea {...input} {...{ placeholder, resize, rows }} />
    <ReduxFormError {...{ meta }} />
  </Container>
);

ReduxFormTextArea.defaultProps = {
  type: 'text',
  rows: 8
};

export default ReduxFormTextArea;
