import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import { reducerUtil, login } from 'base-client';
import { Row, Column } from 'shared-features-client';

import ErrorProjectLogin from 'components/Errors/components/ErrorProjectLogin';
import detailsActions from 'components/ProjectDetails/actions';
import reducerData from './reducerData';
import actions from './actions';
import sortOptions from './sortOptions';
import ProjectCard from './components/ProjectCard';
import AddToProjectButton from './components/AddToProjectButton';
import PoweredConcora from 'shared/PoweredConcora';
import Card from 'shared/Card';
import PageHeader from 'shared/PageHeader';
import SortDropdown from 'shared/SortDropdown';

const NoProjectCard = styled(Card)`
  padding: 10rem;
  text-align: center;
  color: ${props => props.theme.grey3};
`;

const RightActions = styled.div`
  margin-left: auto;
`;

const CardWrapper = styled(Column)`
  flex-flow: column wrap;
`;

const SortColumn = styled(Column)`
  align-self: center;
`;

const StyledHeader = ({ noCta }) => (
  <Column mdUp={12}>
    <PageHeader title="My Projects" justifyContent="flex-start">
      <PoweredConcora margin="0.3rem 0 0" />
      {!noCta && (
        <RightActions>
          <AddToProjectButton>Create a Project</AddToProjectButton>
        </RightActions>
      )}
    </PageHeader>
  </Column>
);

class Projects extends React.Component {
  componentDidMount() {
    this.props.dispatch(actions.getProjects(true));
  }

  componentWillUnmount() {
    let element = document.querySelector('body');
    if (!!element) {
      element.onscroll = undefined;
      element.onresize = undefined;
    }
  }

  componentDidUpdate() {
    let self = this;
    self.isLoading = false;

    if (!this.props.meta) return;

    const {
      projects,
      meta: {
        pagination: { resultCount, limit }
      }
    } = this.props;

    let element = document.querySelector('body');
    if (!!element) {
      element.onscroll = debounce(() => {
        onScrolled();
      }, 50);
    }

    function onScrolled() {
      if (
        document.documentElement.scrollTop > 0.7 * element.offsetHeight &&
        resultCount === limit &&
        self.isLoading === false
      ) {
        self.props.dispatch(actions.getProjects());
        self.isLoading = true;
      }
    }
  }

  render() {
    const { projects, dispatch, token, meta } = this.props;

    const deleteProject = id => {
      dispatch(actions.deleteProject(id));
    };

    const handleSortChange = val => {
      let sort = sortOptions.find(o => o.name === val);
      dispatch(reducerUtil.setSlice(reducerData, reducerData.meta, { ...meta, sort }));
      dispatch(actions.getProjects(true));
    };

    const createSubmittal = id => dispatch(detailsActions.goToSubmittal(id));

    if (!token) {
      return (
        <ErrorProjectLogin>
          Please <Link to="/login">log in</Link> to view projects
        </ErrorProjectLogin>
      );
    }

    if (!projects) return null;

    if (!projects.length)
      return (
        <Row>
          <StyledHeader noCta />
          <Column mdUp={12}>
            <NoProjectCard>
              <p>Create your first project!</p>
              <AddToProjectButton>Create a Project</AddToProjectButton>
            </NoProjectCard>
          </Column>
        </Row>
      );

    return (
      <React.Fragment>
        <Row>
          <StyledHeader />
        </Row>
        <Row>
          <Column mdUp={4}>
            <h4>Showing {projects.length} Projects</h4>
          </Column>
          <SortColumn mdUp={8}>
            <SortDropdown
              {...{ sortOptions, handleChange: handleSortChange }}
              style={{ justifyContent: 'flex-end' }}
            />
          </SortColumn>
        </Row>
        <Row>
          <Column mdUp={12}>
            <Row>
              {projects.map(p => (
                <CardWrapper mdUp={4}>
                  <ProjectCard
                    project={p}
                    key={p.id}
                    onDeleteProject={() => deleteProject(p.id)}
                    onCreateSubmittal={() => createSubmittal(p.id)}
                  />
                </CardWrapper>
              ))}
            </Row>
          </Column>
        </Row>
      </React.Fragment>
    );
  }
}

Projects.defaultProps = {};

const mapStateToProps = state => ({
  token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
  projects: reducerUtil.getSlice(reducerData, reducerData.projects, state),
  meta: reducerUtil.getSlice(reducerData, reducerData.meta, state)
});

export default connect(mapStateToProps)(Projects);
