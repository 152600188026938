import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Container = ({ className, children }) => <Wrapper {...{ className }}>{children}</Wrapper>;

export default Container;
