import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => (props.hasError ? props.theme.red : props.theme.green)};
  padding: 1.5rem 4rem;
  color: white;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.3;
  &.hidden {
    display: none;
  }
`;

const FlashMessage = ({ messageText, hasError, isDisplay }) => (
  <Container {...{ hasError }} className={isDisplay ? '' : 'hidden'}>
    {messageText}
  </Container>
);

export default FlashMessage;
