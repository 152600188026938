import React from 'react';
import styled from 'styled-components';

const Container = styled.h2`
  position: relative;
  padding: 1rem 0 0;
  margin: 0;
  text-align: center;
  font-family: ${props => props.theme.fontAlt};
  }
`;

const Header = ({ headerText }) => <Container>{headerText}</Container>;

export default Header;
