import React from 'react';
import { Field } from 'redux-form';

import projectType from 'utils/staticData/projectType';
import ownerType from 'utils/staticData/ownerType';
import occupantType from 'utils/staticData/occupantType';
import projectScope from 'utils/staticData/projectScope';
import projectPhase from 'utils/staticData/projectPhase';

import { Row, Column } from 'shared-features-client';

import FormGroup from 'shared/FormGroup';
import FormDropdownOption from 'shared/FormDropdownOption';
import ReduxFormDropdown from 'shared/ReduxFormDropdown';
import ReduxFormCheckbox from 'shared/ReduxFormCheckbox';
import NumberCommaInput from './NumberCommaInput';

const TabProjectEdit = ({
  occupant_type,
  owner_type,
  owner_occupied,
  purchase_order,
  project_budget,
  project_size,
  project_type,
  project_scope,
  project_phase,
  ...props
}) => {
  return (
    <React.Fragment>
      <Row>
        <Column span={6}>
          <FormGroup>
            <Field
              name="project_budget"
              type="text"
              component={NumberCommaInput}
              label="project_budget"
              labelTitle="Project Budget"
              prefix="$"
            />
          </FormGroup>
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <FormGroup>
            <Field
              name="project_size"
              type="text"
              component={NumberCommaInput}
              label="project_size"
              labelTitle="Project Size"
              suffix="sq. ft"
            />
          </FormGroup>
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <FormGroup>
            <Field
              name="project_type"
              type="text"
              component={ReduxFormDropdown}
              label="project_type"
              labelTitle="Project Type"
            >
              <FormDropdownOption value={''} optionLabel={'Select a Project Type'} disabled />
              {projectType.map(item => (
                <FormDropdownOption key={item} value={item} optionLabel={item} />
              ))}
            </Field>
          </FormGroup>
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <FormGroup>
            <Field
              name="owner_type"
              type="text"
              component={ReduxFormDropdown}
              label="owner_type"
              labelTitle="Owner Type"
            >
              <FormDropdownOption value={''} optionLabel={'Select a Owner Type'} disabled />
              {ownerType.map(item => (
                <FormDropdownOption key={item} value={item} optionLabel={item} />
              ))}
            </Field>
          </FormGroup>
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <FormGroup>
            <Field
              name="occupant_type"
              type="text"
              component={ReduxFormDropdown}
              label="occupant_type"
              labelTitle="Occupant Type"
            >
              <FormDropdownOption value={''} optionLabel={'Select a Occupant Type'} disabled />
              {occupantType.map(item => (
                <FormDropdownOption key={item} value={item} optionLabel={item} />
              ))}
            </Field>
          </FormGroup>
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <FormGroup>
            <Field
              name="project_scope"
              type="text"
              component={ReduxFormDropdown}
              label="project_scope"
              labelTitle="Project Scope"
            >
              <FormDropdownOption value={''} optionLabel={'Select a Project Scope'} disabled />
              {projectScope.map(item => (
                <FormDropdownOption key={item} value={item} optionLabel={item} />
              ))}
            </Field>
          </FormGroup>
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <FormGroup>
            <Field
              name="project_phase"
              type="text"
              component={ReduxFormDropdown}
              label="project_phase"
              labelTitle="Project Phase"
            >
              <FormDropdownOption value={''} optionLabel={'Select a Project Phase'} disabled />
              {projectPhase.map(item => (
                <FormDropdownOption key={item} value={item} optionLabel={item} />
              ))}
            </Field>
          </FormGroup>
        </Column>
      </Row>
      <Row>
        <Column span={6}>
          <FormGroup>
            <Field
              name="owner_occupied"
              type="checkbox"
              component={ReduxFormCheckbox}
              label="owner_occupied"
              labelTitle="Owner Occupied"
            />
          </FormGroup>
        </Column>
      </Row>
    </React.Fragment>
  );
};

export default TabProjectEdit;
