import React from 'react';
import styled from 'styled-components';

import CheckboxFilter from '../../shared/CheckboxFilter';
import _ from 'lodash';

export const Container = styled.div`
  flex: 0 0 20%;
  padding-right: 2rem;
  font-size: 1.4rem;
  height: 100%;
  &.filter-sticky {
    position: sticky;
    left: 0;
    top: 25px;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    max-height: calc(100vh - 35px);
  }
  .toggleBtn {
    cursor: pointer;
    font-size: 1.3rem;
    color: ${props => props.theme.purple1};
    &:hover,
    &:focus {
      color: ${props => props.theme.purple2};
    }
  }
`;

const Header = styled.h3`
  font-size: 1.4rem;
  margin: 0 0 2rem;
`;

const Filters = ({ filters, selected, defaultFilter, ...props }) => {
  const renderFilter = filters && filters.length > 0;

  const getCheckStatus = ({ attribute, selectedFacets, name, isSingleSelect, defaultFilter }) => {
    if (isSingleSelect && !selectedFacets) {
      return !!defaultFilter.find(
        ({ attribute: defaultAttr, facets }) => defaultAttr === attribute && facets.includes(name)
      );
    }
    return !!(selectedFacets && selectedFacets.find(facet => facet === name));
  };

  return (
    <Container className="tutorial-step-3 filter">
      <Header>Refine By</Header>
      {renderFilter &&
        filters.map(filter => {
          const { name, attribute, facets, single_select_facet: isSingleSelect } = filter;
          const { facets: selectedFacets } =
            selected.find(({ attribute: selectedAttribute }) => selectedAttribute === attribute) ||
            {};

          const filterProps = {
            name,
            attribute,
            isSingleSelect,
            showMore: {
              disabled: false,
              numberShow: 4
            },
            selectedFacets,
            facets: facets.map(({ attribute: name, count }) => ({
              name,
              count,
              checked: getCheckStatus({
                attribute,
                selectedFacets,
                name,
                isSingleSelect,
                defaultFilter
              })
            }))
          };

          return <CheckboxFilter key={attribute} {...filterProps} {...props} />;
        })}
    </Container>
  );
};

export default Filters;
