import uuid from 'uuid/v4';
import { api, history, reducerUtil } from 'base-client';

import searchData from '../reducerData';
import _ from 'lodash';
// import { actions as errorsActions } from 'errors';
import analyticsActions from '../../Analytics/actions';

const getAttributeValue = ({ id, name, list, system }) => {
  const attribute =
    (id && list.find(({ attributeId }) => attributeId === id)) ||
    (name && list.find(({ attributeName }) => attributeName === name)) ||
    {};

  return attribute.attributeValue || system[name];
};

const querySort = [
  { name: 'Relevance', sortBy: '_score', sortDir: 'desc' },
  {
    name: 'Alphabetical (A to Z)',
    sortBy: 'name',
    sortDir: 'asc'
  },
  {
    name: 'Alphabetical (Z to A)',
    sortBy: 'name',
    sortDir: 'desc'
  }
];

/** This sets the search query string.
 */
const setSearch = () => (dispatch, getState) => {
  const queryParams = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
  history.push(`/products?${encodeURIComponent(JSON.stringify(queryParams))}`);
};

/** This performs a product search.
 * @param {bool} [restart] Whether or not to restart the list.
 */
const search = restart => async (dispatch, getState) => {
  let state = getState();

  // get the querystring
  const {
    location: { search: queryJson }
  } = history;

  const query = queryJson ? JSON.parse(decodeURIComponent(trimQuery(queryJson))) : {};

  // get default sorting
  const defaultSort = [...querySort][0];
  const defaultSortQuery = { sortBy: defaultSort.sortBy, sortDir: defaultSort.sortDir };

  // get the page information
  const { pagination } = reducerUtil.getSlice(searchData, searchData.meta, state) || {};
  const page = restart ? 1 : ((pagination && pagination.page) || 0) + 1;
  const limit = 100;

  const fetchId = uuid();
  dispatch(reducerUtil.setSlice(searchData, searchData.fetchId, fetchId));

  const { filters: queryFilter } = query;
  const searchIndex = 'aggregate';

  let apiData = {
    ...defaultSortQuery,
    ...query,
    index: searchIndex
  };

  if (queryFilter) {
    apiData = {
      ...apiData,
      filters: queryFilter
    };
  }
  try {
    const { searchResults: results, searchMetaData: meta } = await dispatch(
      api.actions.post(`search/products?page=${page}&limit=${limit}`, JSON.stringify(apiData))
    );
    const brandedSites = await dispatch(api.actions.get('brandedSites/?limit=500'));
    const brandedSitesMap = _.keyBy(brandedSites, 'tenant_id');
    dispatch(reducerUtil.setSlice(searchData, searchData.brandedSites, brandedSitesMap));
    // check that this is the correct fetch
    state = getState();
    if (fetchId !== reducerUtil.getSlice(searchData, searchData.fetchId, state)) return;

    // get current product info if not restarting
    const list = (!restart && reducerUtil.getSlice(searchData, searchData.list, state)) || [];

    // create the new product list
    const newList = list.concat(dispatch(formatProducts(results, brandedSitesMap)));

    // set the data
    dispatch(reducerUtil.setSlice(searchData, searchData.list, newList));
    dispatch(reducerUtil.setSlice(searchData, searchData.meta, meta));

    // update the search parameters
    dispatch(reducerUtil.setSlice(searchData, searchData.query, query));

    // allow another search
    dispatch(reducerUtil.setSlice(searchData, searchData.fetchId, undefined));

    // send analytics
    const {
      queryString,
      pagination: { totalHits },
      sort: { sortBy, sortDir }
    } = meta;
    dispatch(
      analyticsActions.track('search', {
        queryString,
        totalHits,
        page,
        limit,
        sortBy,
        sortDir
      })
    );
    // dispatch(reducerUtil.setSlice(searchData, searchData.searchId, seachId));
  } catch (error) {
    // dispatch(errorsActions.error(error));
  }
};

const trimQuery = query => {
  if (typeof query !== 'string') return query;
  while (query.charAt(0) === '?') query = query.substr(1);
  return query;
};

const formatProducts = (rawProducts, brandedSites) => (dispatch, getState) => {
  const calloutList = [
    { attributeId: '', displayName: 'Brand', attributeName: 'brand' },
    { attributeId: '', displayName: 'Category', attributeName: 'category' }
  ];
  const calloutMap = calloutList.filter(item => item.attributeName).slice(0, 4);

  const assetList = [
    {
      displayName: 'Revit',
      attributeName: 'revit',
      color: '#0A7BE4'
    },
    {
      displayName: 'CAD',
      attributeName: 'autoCAD',
      color: '#0A7BE4'
    },
    {
      displayName: 'Sketchup',
      attributeName: 'sketchup',
      color: '#BF3838'
    },
    {
      displayName: 'Misc3D',
      attributeName: 'misc3D',
      color: '#5A6678'
    }
  ];

  return (rawProducts || []).map(({ callouts: callData, manufacturer_id, ...product }) => {
    const { id: productId, name: productName, category: productCategory, assets } = product;
    const callouts = callData || [];
    let tenantAssets =
      brandedSites[manufacturer_id] &&
      brandedSites[manufacturer_id].settings &&
      brandedSites[manufacturer_id].settings.assets &&
      brandedSites[manufacturer_id].settings.assets.onSearch;
    tenantAssets = tenantAssets || assetList;
    return {
      ...product,
      manufacturer_id,
      assets: tenantAssets
        .map(({ attributeId, attributeName, displayName, color }) => {
          const url = getAttributeValue({
            id: attributeId,
            name: attributeName,
            list: assets || [],
            system: product
          });
          return {
            id: attributeId,
            color,
            url,
            name: displayName || attributeName,
            meta: {
              product_id: productId,
              productName,
              productCategory,
              attribute_id: attributeId,
              attributeName,
              assetUrl: url,
              tenant_id: manufacturer_id
            }
          };
        })
        .filter(({ name, url }) => name && url),
      callouts: calloutMap.map(({ attributeId: id, attributeName: name, displayName }) => ({
        id,
        name: displayName || name,
        value: getAttributeValue({ id, name, list: callouts, system: product })
      }))
    };
  });
};

const nextProductPage = () => dispatch => dispatch(search());

const setQuery = query => dispatch => {
  dispatch(reducerUtil.setSlice(searchData, searchData.query, query));
  return dispatch(setSearch(true));
};

const setQueryString = queryString => (dispatch, getState) => {
  const {
    location: { pathname }
  } = history;
  if (pathname === '/products') {
    const query = reducerUtil.getSlice(searchData, searchData.query, getState());
    return dispatch(setQuery({ ...query, queryString }));
  }
  return dispatch(setQuery({ queryString }));
};

const setSort = name => (dispatch, getState) => {
  const state = getState();
  const query = reducerUtil.getSlice(searchData, searchData.query, state);
  const sortInfo = querySort.find(item => item.name === name) || {};
  const { sortBy, sortDir } = sortInfo;
  return dispatch(setQuery({ ...query, sortBy, sortDir }));
};

const addFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
  const { filters } = query;

  let newFilters;
  if (!filters) {
    newFilters = [{ attribute, facets }];
  } else {
    const index = filters.findIndex(({ attribute: name }) => name === attribute);
    if (index < 0) {
      newFilters = [...filters, { attribute, facets }];
    } else {
      const { facets: prevFacets } = filters[index];
      newFilters = [...filters];
      newFilters[index] = { attribute, facets: [...prevFacets, ...facets] };
    }
  }

  return dispatch(setQuery({ ...query, filters: newFilters }));
};

const removeFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
  const { filters } = query;

  let newFilters;
  const index = filters.findIndex(({ attribute: name }) => name === attribute);
  const { facets: prevFacets } = filters[index];
  const newFacets = prevFacets.filter(name => name !== facets[0]);
  if (newFacets.length > 0) {
    newFilters = [...filters];
    newFilters[index] = { attribute, facets: newFacets };
  } else if (filters.length > 1) {
    newFilters = filters.filter((item, filterIndex) => filterIndex !== index);
  }

  return dispatch(setQuery({ ...query, filters: newFilters }));
};

const updateFilter = ({ attribute, facets }) => (dispatch, getState) => {
  if (!Array.isArray(facets)) facets = [facets];
  const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
  const { filters = [] } = query;
  let newFilters = [...filters];
  const index = filters.findIndex(({ attribute: name }) => name === attribute);

  if (index < 0) {
    newFilters.push({ attribute, facets });
  } else {
    newFilters[index] = { attribute, facets };
  }
  return dispatch(setQuery({ ...query, filters: newFilters }));
};

const clearAllFilters = () => (dispatch, getState) => {
  const state = getState();
  const query = reducerUtil.getSlice(searchData, searchData.query, getState()) || {};
  return dispatch(
    setQuery({
      ...query,
      filters: []
    })
  );
};

const selectProduct = product => (dispatch, getState) => {
  const state = getState();
  const selectedProducts =
    reducerUtil.getSlice(searchData, searchData.selectedProducts, state) || [];
  return dispatch(
    reducerUtil.setSlice(searchData, searchData.selectedProducts, [...selectedProducts, product])
  );
};

const deselectProduct = product => (dispatch, getState) => {
  const state = getState();
  const selectedProducts =
    reducerUtil.getSlice(searchData, searchData.selectedProducts, state) || [];
  const newList = selectedProducts.filter(({ id }) => id !== product.id);
  return dispatch(
    reducerUtil.setSlice(
      searchData,
      searchData.selectedProducts,
      newList.length > 0 ? newList : undefined
    )
  );
};

const clearSelectedProducts = () => dispatch => {
  return dispatch(reducerUtil.setSlice(searchData, searchData.selectedProducts, undefined));
};

export default {
  search,
  nextProductPage,
  setQueryString,
  setSort,
  addFilter,
  removeFilter,
  updateFilter,
  clearAllFilters,
  formatProducts,
  deselectProduct,
  selectProduct,
  clearSelectedProducts
};
export { querySort };
