import React from 'react';
import styled from 'styled-components';

import HeroArticle from './HeroArticle';
import Navigations from './Navigations';
import SocialProof from './SocialProof';
import MapStats from './MapStats';

const Container = styled.div``;

const LandingPage = {
  heroLink: { url: '/#', displayValue: 'See How It Works' },
  heroImage:
    'https://s3.amazonaws.com/smartbim-library-logos/GDF+STUDIO/Verb-Header-12-0005574.jpg',
  heroTitle: 'Concora Product Information Library',
  heroSubtitle:
    'The single source of truth for manufacturer managed products and supporting documentation',
  heroDescription:
    'With access to over 15,000 commercial products and more than 350,000 documents; Architects, Engineers and Contractors trust Concora to help them find and specify products.',
  navigationTiles: [
    {
      url: '/products',
      header: 'Search For Products',
      description: 'Find BIM, CAD and other technical product information.',
      urlDisplayValue: 'Search for Products'
    },
    {
      url: '/manufacturers',
      header: 'Search For Manufacturers',
      description: 'Search our growing list of Manufacturers for quick links to their content',
      urlDisplayValue: 'Search for Manufacturers'
    },
    {
      url: 'https://concora.com',
      header: 'Learn More',
      description:
        'Check out our corporate site to learn more about Concora and how we are impacting BPMs and AECs.',
      urlDisplayValue: 'Learn More'
    }
  ]
};

const LandingContainer = ({ dispatch, openDemo }) => {
  const {
    heroTitle: title,
    heroSubtitle: subTitle,
    heroDescription: description,
    heroLink: link,
    heroImage: image,
    navigationTiles
  } = LandingPage;

  return (
    <Container>
      <HeroArticle {...{ title, subTitle, description, link, image, onClick: () => openDemo() }} />
      <Navigations {...{ navigationTiles }} />
      <SocialProof></SocialProof>
      <MapStats></MapStats>
    </Container>
  );
};

export default LandingContainer;
