import React from 'react';

import ErrorDisplay from './ErrorDisplay';

const ErrorProjectLogin = ({ children }) => (
  <ErrorDisplay
    header="Oops! Looks like that project is private"
    imageUrl="/contents/project-logged-out.png"
  >
    {children}
  </ErrorDisplay>
);
export default ErrorProjectLogin;
