import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import ErrorHandling from '../Errors';

const DashboardBody = styled.div`
  padding: 1.5rem;
  width: 100%;
  max-width: 128rem;
  margin: 9.5rem auto 0;
  flex: 1;
  min-height: calc(100vh - 387px);
`;

class Dashboard extends Component {
  state = {
    hiddenMenu: false
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrolling);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrolling);
  }

  handleScrolling = () => {
    const scrollOffsetY = window.scrollY;
    if (scrollOffsetY <= 50) {
      return this.setState({ hiddenMenu: false });
    }
    return this.setState({
      hiddenMenu: true
    });
  };

  render() {
    const { children, name } = this.props;
    const { hiddenMenu } = this.state;
    return (
      <React.Fragment>
        <Header className={hiddenMenu ? 'hiddenMenu' : ''} name={name} {...this.props} />
        <DashboardBody className="app-body">
          <ErrorHandling>{children}</ErrorHandling>
        </DashboardBody>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(Dashboard);
