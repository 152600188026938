import React, { useState, Suspense } from 'react';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import Routes from './routes';
import Config from './configurations';
import Library from './components/Library';
import Tour from 'reactour';
import steps from './components/Tutorial/steps';
import analyticsActions from './components/Analytics/actions';

const App = ({ store, history, dispatch }) => {
  const [isDemoOpen, setIsDemoOpen] = useState();
  const [currentDemoStep, setCurrentDemoStep] = useState();

  const onAfterOpen = target => {
    dispatch(analyticsActions.track('demo-start', {}));
    disableBodyScroll(target);
  };
  const onBeforeClose = target => {
    dispatch(analyticsActions.track('demo-end', { step: currentDemoStep }));
    enableBodyScroll(target);
  };

  return (
    <Provider {...{ store }}>
      <Router {...{ history }}>
        <Config>
          <Library>
            <>
              <Routes openDemo={() => setIsDemoOpen(true)} />
              <Suspense fallback={<React.Fragment />}>
                <Tour
                  startAt={0}
                  steps={steps}
                  isOpen={isDemoOpen}
                  getCurrentStep={currentStep => setCurrentDemoStep(currentStep + 1)}
                  onRequestClose={() => setIsDemoOpen(false)}
                  onAfterOpen={target => onAfterOpen(target)}
                  onBeforeClose={target => onBeforeClose(target)}
                  disableDotsNavigation
                />
              </Suspense>
            </>
          </Library>
        </Config>
      </Router>
    </Provider>
  );
};

export default connect()(App);
