export default [
  'Animal Care',
  'Assembly',
  'Civic',
  'College/University',
  'Commercial Office',
  'Corporate Campus',
  'Daycare',
  'Financial and Communication',
  'Healthcare',
  'Higher Education',
  'Hotel/Resort',
  'Hospitality',
  'Infrastructure & Industrial',
  'Interpretive Center',
  'K-12 Education',
  'Laboratory',
  'Landscape',
  'Library,',
  'Military/Base',
  'Multi-unit residential',
  'Multi-use',
  'Museum',
  'Other',
  'Park & Public Space',
  'Performing Arts Center',
  'Public Order and Safety',
  'Recreation',
  'Restaurant',
  'Retail',
  'Single Family Residential',
  'Special Needs Housing',
  'Tall Buildings',
  'Transportation',
  'Workplace Design'
];
