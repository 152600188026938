import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { reducerUtil } from 'base-client';
import HybridViewContainer from 'components/ProductSearch/HybridView';
import detailsData from '../reducerData';
import detailsActions from '../actions';
import ActionBanner from 'components/ProductSearch/ActionBanner';
import SortProduct from './SortProducts';

import { InfiniteList } from 'shared-features-client';

const StyledWrapper = styled.div`
  margin-top: 4rem;
  .page-header {
    margin: 2rem 0 0;
    padding-bottom: 0;
    color: ${props => props.theme.grey4};
  }
`;

const Sectionheader = styled.h2`
  font-size: 2rem;
`;

const StyledSubHeader = styled.div`
  display: flex;
  font-size: 1.4rem;
  width: 100%;
  margin: 1rem 0 1.5rem;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.grey4};
`;

const LeftSubHeader = styled.div`
  font-weight: bold;
  letter-spacing: 0.01em;
`;

const SavedProducts = ({
  fetchId,
  projectId,
  projectName,
  products = [],
  pagination = {},
  order = {},
  selectedProducts = [],
  dispatch
}) => {
  const length = (products && products.length) || 0;

  useEffect(() => {
    dispatch(detailsActions.loadSavedProducts({ projectId, restart: true }));
  }, []);

  const toggleSelectProduct = product => {
    const { checked, id, name } = product;
    if (!checked) {
      return dispatch(detailsActions.selectProduct({ id, name }));
    } else {
      return dispatch(detailsActions.deselectProduct({ id, name }));
    }
  };

  const clearSelectedProducts = () => dispatch(detailsActions.clearSelectedProducts());

  const removeProductsFromProject = () =>
    dispatch(detailsActions.removeProductsFromProject(projectId, projectName));

  const list = products.map(product => {
    return {
      ...product,
      checked: !selectedProducts ? false : !!selectedProducts.find(({ id }) => id === product.id)
    };
  });

  /* Determine if there is more to load based on the metadata */
  let hasNextPage;
  if (pagination) {
    const { page, pageCount } = pagination;
    hasNextPage = page < pageCount;
  }

  const infiniteListProps = {
    list,
    hasNextPage,
    fetching: !!fetchId,
    getNextPage: () => {
      dispatch(detailsActions.loadNextPage({ projectId }));
    }
  };

  const sortProps = {
    onSort: sortName => dispatch(detailsActions.setSort(projectId, sortName)),
    currentSort: order
  };

  return (
    <StyledWrapper className="project-products">
      <Sectionheader>Saved Products</Sectionheader>
      <StyledSubHeader>
        <LeftSubHeader>Showing {length} products</LeftSubHeader>
        <SortProduct {...sortProps} />
      </StyledSubHeader>
      <ActionBanner {...{ selectedProducts, clearSelectedProducts, removeProductsFromProject }} />
      {products && products.length > 0 && (
        <InfiniteList {...infiniteListProps}>
          <HybridViewContainer
            products={list}
            toggleSelectProduct={toggleSelectProduct}
            enableCheckbox
          />
        </InfiniteList>
      )}
    </StyledWrapper>
  );
};
const mapStateToProps = state => ({
  fetchId: reducerUtil.getSlice(detailsData, detailsData.fetchId, state),
  products: reducerUtil.getSlice(detailsData, detailsData.products, state),
  pagination: reducerUtil.getSlice(detailsData, detailsData.pagination, state),
  order: reducerUtil.getSlice(detailsData, detailsData.order, state),
  selectedProducts: reducerUtil.getSlice(detailsData, detailsData.selectedProducts, state)
});

export default connect(mapStateToProps)(SavedProducts);
