import React from 'react';
import styled from 'styled-components';

import FileContainer from './FileContainer';
import Tooltip from './Tooltip';

const StyledFileContainer = styled(FileContainer)`
  position: relative;
`;

const FileType = styled.span`
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  display: inline-block;
  margin-top: 0.3rem;
  border-bottom: ${props => (props.hasImage ? 'none' : `3px solid ${props.color || '#FFF'}`)};
`;

const CustomTooltip = ({ name, canDownload, noLoginRequired }) => (
  <Tooltip>{canDownload || noLoginRequired ? `Download ${name}` : 'Login to download'}</Tooltip>
);

const CustomAsset = ({ name, color, noLoginRequired, ...props }) => (
  <StyledFileContainer {...props} title={name} noLoginRequired={noLoginRequired}>
    <CustomTooltip {...{ name, noLoginRequired }} />
    <FileType {...{ color }}>{name}</FileType>
  </StyledFileContainer>
);

export default CustomAsset;
