import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { config } from 'base-client';
import configMap from '../../configurations/configMap';

import Button from 'shared/Button';
import CloseIcon from 'shared/CloseIcon';

import ComparisonButton from './ComparisonButton';
import AddToProjectButton from 'components/Projects/components/AddToProjectButton';

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.grey5};
  color: ${props => props.theme.white};
  height: 50px;
  border-radius: 4px;
  box-shadow: 0 3px 5px rgba(52, 62, 71, 0.12);
  padding-left: 2rem;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
  button {
    text-transform: none;
    margin-right: 1rem;
    font-weight: 500;
    line-height: 2rem;
    height: 2.5rem;
    padding: 0 1rem;
    border: 1px solid ${props => props.theme.grey3};
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.lightBlack};
  }
  .hasTooltip {
    position: relative;
    &:hover {
      .tooltip {
        opacity: 1;
      }
    }
    &.comparison {
      .tooltip {
        top: -5.5rem;
      }
    }
  }
  .tooltip {
    border: 1px solid ${props => props.theme.grey3};
    font-weight: 500;
    border-radius: 4px;
    &:before {
      border-right: 1px solid ${props => props.theme.grey3};
      border-bottom: 1px solid ${props => props.theme.grey3};
      bottom: -5px;
    }
  }
`;

const SelectedText = styled.span`
  font-size: 1.2rem;
  letter-spacing: 0.2px;
  line-height: 3rem;
  font-weight: 500;
  margin-right: 3rem;
`;

const CloseButton = styled(CloseIcon)`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin-right: 10px;
  &:before,
  &:after {
    background-color: ${props => props.theme.white};
    width: 0.2rem;
  }
`;

const ActionBanner = ({
  dispatch,
  selectedProducts,
  clearSelectedProducts,
  removeProductsFromProject
}) => {
  if (!selectedProducts) return null;

  const initialState = {
    isShow: true
  };

  const [state, setstate] = useState(initialState);
  const [projectsEnabled, setProjectsEnabled] = useState(false);

  useEffect(() => {
    const isShow = selectedProducts && selectedProducts.length > 0 ? true : false;
    if (state.isShow !== isShow) {
      return setstate({
        ...state,
        isShow
      });
    }
    setProjectsEnabled(
      dispatch(config.actions.getData(configMap.projects.name, configMap.projects.enabled.name))
    );
  }, [selectedProducts]);

  const { isShow } = state;

  const onCloseBanner = () => {
    setstate({ ...state, isShow: false });
    clearSelectedProducts();
  };

  return isShow ? (
    <Container className="tutorial-step-6 action-banner">
      <CloseButton onClick={onCloseBanner} height={15} width={15} />
      <SelectedText>{selectedProducts.length} Selected</SelectedText>
      {projectsEnabled &&
        (!removeProductsFromProject ? (
          <AddToProjectButton transparent small products={selectedProducts}>
            Add to a Project
          </AddToProjectButton>
        ) : (
          <Button transparent small onClick={removeProductsFromProject}>
            Remove from Project
          </Button>
        ))}

      <ComparisonButton selectedProducts={selectedProducts}>
        Compare Products {selectedProducts.length}/4
      </ComparisonButton>
    </Container>
  ) : null;
};

ActionBanner.defaultProps = {
  selectedProducts: undefined,
  removeProductsFromProject: undefined
};

export default connect()(ActionBanner);
