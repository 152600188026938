import { api, reducerUtil, config, history } from 'base-client';
import _ from 'lodash';
import Promise from 'bluebird';
import uuid from 'uuid/v4';

import reducerData from '../reducerData';
import analyticsActions from '../../Analytics/actions';
import sortOptions from '../sortOptions';

import projectsData from '../reducerData';

import { projectSubmittal } from '../../Notifications/constants';
import notificationActions from '../../Notifications/actions';

const openModal = products => dispatch => {
  if (!products) {
    dispatch(reducerUtil.setSlice(projectsData, projectsData.products, undefined));
  } else {
    if (products && !Array.isArray(products)) products = [products];
    if (products.length < 1) return;

    dispatch(reducerUtil.setSlice(projectsData, projectsData.products, products));
  }
  dispatch(reducerUtil.setSlice(projectsData, projectsData.modalOpen, true));
};

const closeModal = () => dispatch => {
  dispatch(reducerUtil.setSlice(projectsData, projectsData.products, undefined));
  dispatch(reducerUtil.setSlice(projectsData, projectsData.modalOpen, undefined));
};

const getAllProjects = limit => async (dispatch, getState) => {
  try {
    const { projects } = await dispatch(api.actions.get(`projects?limit=${limit || 100}`, true));
    return projects;
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const checkProjectName = ({ name }) => async (dispatch, getState) => {
  if (!name) return;
  try {
    const { projects } = await dispatch(api.actions.get('projects', true));
    return !projects.find(({ name: pName }) => pName === name);
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const createProject = projectInfo => async (dispatch, getState) => {
  const canCreate = await dispatch(checkProjectName(projectInfo));
  if (!canCreate) return;

  const { name, zipCode } = projectInfo;
  const apiParams = {
    name,
    address: {
      zipcode: zipCode
    }
  };
  try {
    // create the project
    const result = await dispatch(api.actions.post('projects', { ...apiParams }, true));
    if (result) {
      const { id, name } = result;

      // send analytics
      dispatch(analyticsActions.track('projectcreate', { id, name }));

      // add the products to the project
      await dispatch(addProducts({ id, name }));
      return true;
    }
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const addProducts = project => async (dispatch, getState) => {
  const state = getState();

  const userId = dispatch(analyticsActions.getUserId());
  const rawProducts = reducerUtil.getSlice(projectsData, projectsData.products, state);
  if (!rawProducts || (rawProducts && rawProducts.length < 1)) {
    dispatch(showNotification(project));

    const {
      location: { pathname }
    } = history;

    if (pathname === '/projects') {
      dispatch(getProjects(true));
    }

    dispatch(closeModal());
  } else {
    try {
      const tenantProducts = _.groupBy(rawProducts, 'tenant_id');
      const result = await Promise.map(
        Object.keys(tenantProducts),
        async key => {
          const products = tenantProducts[key].map(({ name, id }) => ({ name, id }));
          await dispatch(
            api.actions.patch(
              `projects/${project.id}/products`,
              {
                user_id: userId,
                products: products.map(({ name, id }) => ({ product_id: id, name })),
                tenant_id: key
              },
              true
            )
          );
          // send analytics
          const { id, name } = project;
          dispatch(
            analyticsActions.track('projectaddproduct', { id, name, tenant_id: key, rawProducts })
          );
        },
        { concurrency: 5 }
      );

      if (result) {
        dispatch(showNotification(project, rawProducts));

        dispatch(closeModal());
      }
    } catch (error) {
      dispatch(config.actions.error(error));
    }
  }
};

const showNotification = (project, products) => dispatch => {
  const productsList =
    (products && products.map(({ product_id: id, name }) => ({ id, name }))) || [];
  const { id, name } = project;

  const notification = [
    {
      id: `create-project-${uuid()}`,
      type: projectSubmittal.PRODUCT_ADDED,
      payloads: {
        project: {
          id,
          name
        },
        products: productsList
      }
    }
  ];
  dispatch(notificationActions.sendNotifications(notification));
};

const getProjectProductCounts = (projectId, brandedSites) => async (dispatch, getState) => {
  const apiEndpoint = `projects/${projectId}/products?page=${0}&limit=${100}`;

  const { projectProducts: products = [] } = await dispatch(api.actions.get(apiEndpoint, true));

  const filteredList = products
    .map(({ product = {}, product_id: id, name }) => {
      if (product && product.manufacturer_id) {
        return { ...product };
      } else {
        return {
          id,
          name,
          isDeleted: true
        };
      }
    })
    .filter(product => !!brandedSites[product.manufacturer_id]);
  return filteredList.length;
};

const getProjects = restart => async (dispatch, getState) => {
  let state = getState();
  let { pagination, sort } = reducerUtil.getSlice(reducerData, reducerData.meta, state) || {};
  if (!sort) sort = sortOptions[0];
  const page = restart ? 1 : ((pagination && pagination.page) || 0) + 1;
  const limit = 100;

  const fetchId = uuid();

  dispatch(reducerUtil.setSlice(reducerData, reducerData.fetchId, fetchId));

  try {
    const result = await dispatch(
      api.actions.get(
        `projects?page=${page}
        &limit=${limit}&order[${sort.sortBy}]=${sort.sortDir}`,
        true
      )
    );

    // check that this is the correct fetch
    state = getState();
    if (fetchId !== reducerUtil.getSlice(reducerData, reducerData.fetchId, state)) return;

    // allow another search
    dispatch(reducerUtil.setSlice(reducerData, reducerData.fetchId, undefined));

    // get loaded projects if not restarting
    const loadedProjects =
      (!restart && reducerUtil.getSlice(reducerData, reducerData.projects, state)) || [];

    const brandedSites = await dispatch(api.actions.get('brandedSites/?limit=500'));
    const brandedSitesMap = _.keyBy(brandedSites, 'tenant_id');

    const projects = await Promise.map(result.projects, async project => {
      const productCount = await dispatch(getProjectProductCounts(project.id, brandedSitesMap));
      project['products.count'] = productCount;
      return project;
    });

    const newList = projects ? [...loadedProjects, ...projects] : [...loadedProjects];
    // set the data
    dispatch(reducerUtil.setSlice(reducerData, reducerData.projects, newList));
    dispatch(
      reducerUtil.setSlice(reducerData, reducerData.meta, { pagination: result.pagination, sort })
    );
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const deleteProject = id => async (dispatch, getState) => {
  let state = getState();
  try {
    await dispatch(api.actions.delete(`projects/${id}`, {}, true));

    const {
      location: { pathname }
    } = history;

    if (pathname !== '/projects') {
      history.push('/projects');
    } else {
      dispatch(getProjects(true));
    }

    // send analytics
    const projectList = reducerUtil.getSlice(reducerData, reducerData.projects, state);
    const { name } = projectList.find(({ id: projectId }) => projectId === id) || {};
    dispatch(analyticsActions.track('projectdelete', { id, name }));
  } catch (error) {
    dispatch(config.actions.error(error));
  }
};

const nextProjectsPage = () => dispatch => dispatch(getProjects());

export default {
  openModal,
  closeModal,
  checkProjectName,
  createProject,
  addProducts,
  showNotification,
  getAllProjects,
  getProjects,
  deleteProject,
  nextProjectsPage
};
