import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import configActions from '../actions';

class Config extends React.Component {
  state = {};

  componentDidMount() {
    this.startApp();
    this.fixScroll();
  }

  startApp = async () => {
    const { dispatch } = this.props;
    await dispatch(configActions.start());

    this.setState({ started: true });
  };

  fixScroll = () => {
    const { history } = this.props;
    history.listen(({ pathname }) => {
      const { pathname: lastPath } = this.state;
      if (pathname === lastPath) return;

      window.scrollTo(0, 0);
      this.setState({ pathname });
    });
  };

  render() {
    const { started } = this.state;
    if (!started) return null;

    const { children } = this.props;
    return children;
  }
}

export default withRouter(connect()(Config));
