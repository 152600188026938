import React from 'react';
import Dashboard from '../components/Dashboard';
import ProductSearch from '../components/ProductSearch/index';
import { withRouter } from 'react-router-dom';

const Products = () => {
  return (
    <>
      <ProductSearch />
    </>
  );
};

export default withRouter(Products);
