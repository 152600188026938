export default {
  name: 'search',
  list: 'list',
  meta: 'meta',
  query: 'query',
  fetchId: 'fetchId',
  brandedSites: 'brandedSites',
  selectedProducts: 'selectedProducts'
  // searchId: 'searchId',
};
