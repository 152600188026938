const PRODUCT_HIDDEN_SECTIONS = ['System', 'Custom', 'Hidden'];

const ATTRIBUTE_LINKS = ['url', 'assetselection'];

const truncateNumber = value => {
  if (!value) return value;
  const arrString = value.toString().split(' ');
  const numberVal = parseFloat(arrString[0]);
  if (!numberVal || Math.floor(numberVal) === numberVal || arrString[0].split('.')[1].length <= 3) {
    return value;
  }
  return `${numberVal.toFixed(3).toString()} ${arrString[1] || ''}`;
};

export { PRODUCT_HIDDEN_SECTIONS, ATTRIBUTE_LINKS, truncateNumber };
