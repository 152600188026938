import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';

import ProgressBar from './ProgressBar';
import Button from 'shared/Button';
import { projectSubmittal } from 'components/Notifications/constants';
import notificationActions from 'components/Notifications/actions';

const ButtonStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
`;

const ProjectCompletionStatus = ({
  generalData,
  pristine,
  submitting,
  reset,
  invalid,
  onClick,
  error,
  dispatch
}) => {
  const {
    address,
    project_number,
    estimated_ship_date,
    occupant_type,
    owner_type,
    project_budget,
    project_size,
    project_type,
    project_scope,
    project_phase,
    architect,
    contractor,
    distributor,
    general_contractor
  } = generalData || {};
  const { address_line_1, city, state, zipcode, country } = address || {};
  const projectDetails = {
    project_number,
    address_line_1,
    city,
    state,
    zipcode,
    country,
    estimated_ship_date
  };

  const additionalInformation = {
    occupant_type,
    owner_type,
    project_budget,
    project_size,
    project_type,
    project_scope,
    project_phase
  };

  const getContactProps = (source, name) => {
    const { address, company_name, contact_name, email, phone } = source || {};
    const { city, state, zipcode, country } = address || {};
    const contact = {};
    contact[`${name}company`] = company_name;
    contact[`${name}contact`] = contact_name;
    contact[`${name}email`] = email;
    contact[`${name}phone`] = phone;
    contact[`${name}city`] = city;
    contact[`${name}state`] = state;
    contact[`${name}zip`] = zipcode;
    contact[`${name}country`] = country;
    return contact;
  };

  const contactInformation = {
    ...getContactProps(architect, 'arc'),
    ...getContactProps(contractor, 'con'),
    ...getContactProps(distributor, 'dis'),
    ...getContactProps(general_contractor, 'gen')
  };

  const onDiscard = () => {
    reset && reset();
    dispatch(
      notificationActions.sendNotifications([
        {
          id: `discarded-project-changes-${uuid()}`,
          type: projectSubmittal.GENERAL,
          title: 'Discard Project Changes',
          body: 'Your project changes have been discarded'
        }
      ])
    );
  };

  return (
    <div>
      <ProgressBar title="Project Details" data={projectDetails} />
      <ProgressBar title="Additional Information" data={additionalInformation} />
      <ProgressBar title="Contact Information" data={contactInformation} />
      <ButtonStyle>
        <Button
          disabled={pristine || submitting || invalid}
          small
          bgColor={props => props.theme.purple1}
          {...{ onClick }}
        >
          Save Changes
        </Button>
        <Button
          small
          disabled={pristine || submitting}
          bgColor={props => props.theme.grey7}
          color={props => props.theme.grey5}
          onClick={onDiscard}
        >
          Discard Changes
        </Button>
      </ButtonStyle>
    </div>
  );
};

export default connect()(ProjectCompletionStatus);
