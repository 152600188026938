import React from 'react';
import styled from 'styled-components';

import Input from './Input';
import FormLabel from './FormLabel';

export const Container = styled.div`
  margin-bottom: 2rem;
  text-align: left;
  margin-top: ${props => (props.labelTitle ? null : '-1rem')};
`;

const Error = styled.div`
  color: ${props => 'red'};
  font-size: 1.2rem;
  margin: 0.2rem 0 0;
`;

const ErrorText = styled.p`
  margin: 0;
`;

const FormInput = ({
  type,
  field,
  value,
  name,
  placeholder,
  required,
  labelTitle,
  hasError,
  errorMessage,
  disabled,
  onChange,
  onBlur
}) => (
  <Container {...{ labelTitle }}>
    {labelTitle && <FormLabel {...{ required, labelTitle }} />}
    <Input
      {...{ type, placeholder, hasError, disabled, field, required, value, name }}
      onChange={e => !!onChange && onChange(e, field)}
      onBlur={e => !!onBlur && onBlur(e, field)}
    />
    {hasError && (
      <Error>
        {errorMessage.split('\n').map((i, key) => {
          return (
            <ErrorText style={key > 0 ? { 'margin-left': '20px' } : null} key={key}>
              {i}
            </ErrorText>
          );
        })}
      </Error>
    )}
  </Container>
);

export default FormInput;
