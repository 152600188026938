import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { config } from 'base-client';

import ConcoraLogo from './static/ConcoraLogo';
import configMap from '../configurations/configMap';

const Container = styled.a`
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.grey3};
  margin: ${props => (props.margin ? props.margin : null)};
  & > svg {
    height: 4rem;
    width: auto;
  }
`;

const PoweredConcora = ({ dispatch, margin }) => {
  const website = dispatch(
    config.actions.getData(configMap.agreement.name, configMap.agreement.website.name)
  );
  return (
    <Container href={website} target="_blank" rel="noopener noreferrer" {...{ margin }}>
      Powered by <ConcoraLogo />
    </Container>
  );
};

export default connect()(PoweredConcora);
