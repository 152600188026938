import React from 'react';
import styled from 'styled-components';
import CustomAsset from './CustomAsset';

const Container = styled.div`
  margin-top: 2rem;
  a {
    color: grey;
    opacity: 0.7;
    transition: all 0.3s ease-in;
    padding: 0 0 1rem;
    position: relative;
    margin-right: 1.6rem;
    cursor: pointer;
    &:hover {
      color: darkgrey;
      opacity: 1;
      div {
        opacity: 1;
      }
    }
    > div {
      text-align: center;
      transition: opacity 0.3s ease-in;
      top: -3rem;
    }
  }
`;

const FileList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const HeaderAssets = ({ children, assets, noLoginRequired }) => {
  if (assets.length < 1) return null;
  return (
    <Container className="header-assets">
      {children}
      <FileList>
        {assets.map(asset => (
          <CustomAsset key={asset.id} {...asset} noLoginRequired={noLoginRequired} />
        ))}
      </FileList>
    </Container>
  );
};

export default HeaderAssets;
