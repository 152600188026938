import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Dropdown from '../../shared/Dropdown';
import LinkButton from '../../shared/LinkButton';
import { login, reducerUtil, history } from 'base-client';
import accountActions from '../UserProfile/actions';
import loginActions from '../Login/actions';

const UserMenu = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.2rem 0 0.2rem 0;
  .dropdown {
    padding-right: 1rem;
    .visible {
      top: 4rem;
      right: -0.8rem;
    }
  }
`;

const UserImage = styled.img`
  border-radius: 100%;
  margin: 0 1rem;
`;

const DropdownItem = styled.a`
  display: block;
  padding: 0 1.5rem 0.5rem;
  color: ${props => props.theme.textColor || props.theme.grey3};
  transition: color 0.2s ease;
  cursor: pointer;
  min-width: 11rem;
  &:last-child {
    padding-bottom: 0;
  }
`;

const UserDropdown = ({ dispatch, profile, token }) => {
  if (!profile)
    return (
      <LinkButton small onClick={() => dispatch(login.actions.toLogin())}>
        Log In
      </LinkButton>
    );

  const { name, picture } = profile;
  return (
    <UserMenu>
      <UserImage src={picture} width="30" height="30" alt={name} />
      <Dropdown text="My Account">
        <DropdownItem onClick={() => dispatch(accountActions.toAccount())}>
          Edit Account
        </DropdownItem>
        <DropdownItem onClick={() => dispatch(loginActions.logOut())}>Log Out</DropdownItem>
      </Dropdown>
    </UserMenu>
  );
};

const mapStateToProps = state => {
  const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, state);
  const profile = reducerUtil.getSlice(login.reducerData, login.reducerData.profile, state);
  return { profile, token };
};

export default connect(mapStateToProps)(UserDropdown);
