import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  width: 45%;
  margin: 4rem auto 0;
`;

const ErrorImg = styled.img`
  display: block;
  width: 80%;
  margin: 0 auto;
`;

const ErrorHeader = styled.div`
  font-weight: 600;
  font-size: 1.8rem;
  margin-top: -3rem;
`;

const ErrorContent = styled.div`
  margin-top: 2rem;
  a {
    color: ${props => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
  }
`;

const ErrorDisplay = ({ header, content, imageUrl, children }) => (
  <Container>
    <ErrorImg src={imageUrl} />
    <ErrorHeader>{header}</ErrorHeader>
    {content && <ErrorContent>{content}</ErrorContent>}
    {children && <ErrorContent>{children}</ErrorContent>}
  </Container>
);

export default ErrorDisplay;
