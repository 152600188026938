import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { config } from 'base-client';
import configMap from '../configurations/configMap';

import Manufacturers from '../pages/Manufacturers';
import Products from '../pages/Products';
import LandingPage from '../components/LandingPage';
import Dashboard from '../components/Dashboard';
import UserProfile from '../components/UserProfile';
import Login from '../components/Login';
import ProjectDetails from '../components/ProjectDetails';
import ProjectEdit from '../components/ProjectEdit';
import Projects from '../components/Projects';
import ProductComparison from '../components/ProductComparison';
import GDPRPage from '../components/GDPRPage';
import SmartBIMSpaces from '../components/SmartBIMSpaces';

const Routes = ({ dispatch, openDemo }) => {
  const projectsEnabled = dispatch(
    config.actions.getData(configMap.projects.name, configMap.projects.enabled.name)
  );
  return (
    <Switch>
      <Route path={'/login'} component={Login} />
      <Dashboard>
        <Switch>
          <Route exact path="/">
            <Switch>
              <Route component={props => <LandingPage {...props} openDemo={openDemo} />} />
            </Switch>
          </Route>
          <Route path="/manufacturers">
            <Switch>
              <Route component={Manufacturers} />
            </Switch>
          </Route>
          <Route path="/compare">
            <Switch>
              <Route component={ProductComparison} />
            </Switch>
          </Route>
          <Route path="/products">
            <Switch>
              <Route component={Products} />
            </Switch>
          </Route>
          <Route path={'/account'} component={UserProfile} />
          <Route path={'/gdpr-ccpa'} component={GDPRPage} />
          <Route path="/spaces">
            <Switch>
              <Route component={SmartBIMSpaces} />
            </Switch>
          </Route>
          {projectsEnabled && (
            <Switch>
              <Route path="/projects">
                <Switch>
                  <Route path="/projects/:id/edit" component={ProjectEdit} />
                  <Route path="/projects/:id" component={ProjectDetails} />
                  <Route component={Projects} />
                </Switch>
              </Route>
              <Route path="/projects">
                <Switch>
                  <Route path="/projects/:id" component={ProjectDetails} />
                  <Route component={Projects} />
                </Switch>
              </Route>
            </Switch>
          )}
        </Switch>
      </Dashboard>
    </Switch>
  );
};
export default withRouter(connect()(Routes));
