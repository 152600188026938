const addComma = input => {
  if (!input) return;
  const number = parseInt(input);
  if (!number) return;
  return number.toLocaleString('en-US');
};

const removeComma = input => {
  if (!input) return;
  return parseInt(input.replace(/,/g, ''));
};

export { addComma, removeComma };
