import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-height: 20px;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const Name = styled.h4`
  font-weight: 600;
  margin: 0;
  flex: 0 0 48%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Value = styled.p`
  margin: 0;
  flex: 0 0 48%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
`;

const Callout = ({ name, value }) => {
  const content = (
    <Container className="callout">
      <Name>{name}</Name>
      <Value>{value ? value : ''}</Value>
    </Container>
  );
  return !name && !value ? null : content;
};

export default Callout;
