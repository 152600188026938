import { config, reducerUtil } from 'base-client';

import errorsData from '../reducerData';

const error = error => dispatch => {
  if (error instanceof Error) {
    const { message } = error || {};
    dispatch(reducerUtil.setSlice(errorsData, errorsData.error, message));
  } else {
    const { status } = error || {};
    dispatch(reducerUtil.setSlice(errorsData, errorsData.error, status));
  }
  dispatch(config.actions.error(error));
};

const endError = () => dispatch =>
  dispatch(reducerUtil.setSlice(errorsData, errorsData.error, undefined));

export default {
  error,
  endError
};
