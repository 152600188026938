import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: ${props => props.theme.white};
  border-radius: 4px;
  box-shadow: 0 3px 5px rgba(52, 62, 71, 0.12);
  padding: ${props => props.padding || '0'};
`;

const Card = props => <Container {...props} />;

export default Card;
