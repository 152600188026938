export default {
  auth: {
    name: 'auth',
    domain: {
      name: 'domain',
      key: 'auth0.domain',
      type: 'string'
    },
    clientId: {
      name: 'clientId',
      key: 'library.auth0.client.id',
      type: 'string'
    },
    audience: {
      name: 'audience',
      key: 'auth0.library_api_audience',
      type: 'string'
    }
  },
  api: {
    name: 'api',
    host: {
      name: 'host',
      key: 'api.endpoint.library',
      type: 'string'
    },
    gateway: {
      name: 'gateway',
      key: 'api.endpoint.gateway',
      type: 'string'
    }
  },
  manufacturers: {
    name: 'manufacturers',
    redirect_domain: {
      name: 'redirect_domain',
      key: 'MANUFACTURERS_REDIRECT_DOMAIN',
      type: 'string'
    }
  },
  agreement: {
    name: 'agreement',
    terms: {
      name: 'terms',
      key: 'client.agreement.terms',
      type: 'string'
    },
    privacy: {
      name: 'privacy',
      key: 'client.agreement.privacy',
      type: 'string'
    },
    website: {
      name: 'website',
      key: 'client.agreement.website',
      type: 'string'
    },
    sitename: {
      name: 'sitename',
      key: 'library.agreement.sitename',
      type: 'string'
    }
  },
  analytics: {
    name: 'analytics',
    projectId: {
      name: 'projectId',
      key: 'keen.projectId',
      type: 'string'
    },
    writeKey: {
      name: 'writeKey',
      key: 'keen.writeKey',
      type: 'string'
    },
    segment: {
      name: 'segment',
      key: 'segment.writeKey',
      type: 'string'
    }
  },
  projects: {
    name: 'projects',
    enabled: {
      name: 'enabled',
      key: 'allow-multi-tenant-projects',
      type: 'bool'
    }
  }
};
