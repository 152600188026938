import React from 'react';
import styled from 'styled-components';

import ReduxFormError from './ReduxFormError';
import FormLabel from './FormLabel';

const Container = styled.div`
  margin-bottom: 1.5rem;
  text-align: left;
  margin-top: ${props => (props.labelTitle ? null : '-1rem')};
`;

const ReduxFormCheckbox = ({ type, required, labelTitle, input, meta, className }) => (
  <Container {...{ labelTitle, className }}>
    {labelTitle && <FormLabel {...{ required, labelTitle }} />}
    <input type="checkbox" {...input} /> <span>{input.checked ? 'Yes' : 'No'}</span>
    <ReduxFormError {...{ meta }} />
  </Container>
);

ReduxFormCheckbox.defaultProps = {
  type: 'text'
};

export default ReduxFormCheckbox;
