import { api, history, login, reducerUtil } from 'base-client';

import accountData from '../reducerData';

const toAccount = () => () => history.push('/account');

const getUserAccount = refresh => async (dispatch, getState) => {
  const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, getState());
  if (!token) return;

  if (!refresh) dispatch(reducerUtil.setSlice(accountData, accountData.data, undefined));
  try {
    const result = await dispatch(api.actions.get('users/my/profile'));
    const { firstName, lastName, occupation, industry, profile } = result || {};
    const { companyName, phoneNumber, email } = profile || {};
    const account = {
      firstName,
      lastName,
      occupation,
      industry,
      companyName,
      phoneNumber,
      email
    };
    dispatch(reducerUtil.setSlice(accountData, accountData.data, account));
  } catch (error) {}
};

const setUserAccount = values => async dispatch => {
  try {
    await dispatch(api.actions.patch('users/my/profile', JSON.stringify(values)));
    dispatch(getUserAccount(true));
  } catch (error) {}
};

const fillAccount = () => async (dispatch, getState) => {
  const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, getState());
  if (!token) return;

  await dispatch(getUserAccount());

  const accountInfo = reducerUtil.getSlice(accountData, accountData.data, getState()) || {};
  if (accountInfo.companyName && accountInfo.industry && accountInfo.occupation) return;

  dispatch(toAccount());
};

const clearAccount = () => dispatch =>
  dispatch(reducerUtil.setSlice(accountData, accountData.data, undefined));

export default { toAccount, getUserAccount, setUserAccount, fillAccount, clearAccount };
