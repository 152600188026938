import React, { useState } from 'react';
import styled from 'styled-components';

import FormInput from 'shared/FormInput';
import Button from 'shared/Button';

const FormGroup = styled.div`
  width: 100%;
  padding: 1.5rem 0 2rem;
  &:first-child {
    padding-top: 0;
  }
  &.projectInfor {
    padding-bottom: 0;
    display: flex;
  }
`;

const ProjectField = styled.div`
  width: 36%;
  &:first-child {
    margin-right: 4%;
    width: 60%;
  }
  > div {
    margin-bottom: 1rem;
  }
  input {
    margin: 0.5rem 0 0;
    border-radius: 0.4rem;
    border-color: ${props => props.theme.grey3};
    height: 3.2rem;
    &.error {
      border-color: ${props => props.theme.red};
    }
  }
`;

const ResponseMessage = styled.span`
  color: ${props => props.theme.red};
  margin-top: 0.5rem;
  display: block;
  width: 100%;
  font-size: 1.3rem;
`;

const CreateNewProject = ({ hasProducts, handleCreateProject }) => {
  const defaultState = {
    name: '',
    zipCode: '',
    duplicateMessage: ''
  };

  const [state, setState] = useState(defaultState);

  const handleInputChange = evt => {
    const { name, value } = evt.target;
    setState({
      ...state,
      [name]: value
    });
  };

  const onCreateProject = async () => {
    const { name, zipCode } = state || {};
    if (!name || !zipCode) {
      return setState({
        ...state,
        errorMessage: 'Field is required'
      });
    }
    const projectNameValid = await handleCreateProject({ name, zipCode });
    if (!projectNameValid) {
      setState({
        ...state,
        duplicateMessage: 'Please enter a unique project name'
      });
    }
  };

  const { name, zipCode, duplicateMessage, errorMessage } = state;
  return (
    <form noValidate>
      <FormGroup className="projectInfor">
        <ProjectField>
          <FormInput
            onChange={handleInputChange}
            value={name}
            field="name"
            name="name"
            className="name"
            labelTitle="Project Name"
            placeholder="Project Name"
            type="text"
            hasError={!name && errorMessage}
            errorMessage={errorMessage}
            required
          />
        </ProjectField>
        <ProjectField>
          <FormInput
            onChange={handleInputChange}
            value={zipCode}
            field="zipCode"
            name="zipCode"
            className="zipCode"
            labelTitle="Location - Zip Code"
            placeholder="Zip Code"
            type="text"
            hasError={!zipCode && errorMessage}
            errorMessage={errorMessage}
            required
          />
        </ProjectField>
      </FormGroup>
      {!duplicateMessage ? null : <ResponseMessage>{duplicateMessage}</ResponseMessage>}
      <FormGroup>
        <Button small noUpperCase rightArrow type="submit" onClick={onCreateProject}>
          {hasProducts ? 'Create Project and Add to Project' : 'Create Project'}
        </Button>
      </FormGroup>
    </form>
  );
};

export default CreateNewProject;
