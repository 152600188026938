import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import validator from 'validator';
import { login } from 'base-client';

import loginActions from './actions';
import FormInput from '../../shared/FormInput';
import LinkButton from '../../shared/LinkButton';
import ButtonText from '../../shared/ButtonText';
import PoweredConcora from '../../shared/PoweredConcora';
import Body from './Body';
import FlashMessage from './FlashMessage';
import Header from './Header';
import SocialContainer from './SocialContainer';
import Footer from './Footer';

const LoginForm = styled.form`
  text-align: center;
`;

const HelpText = styled.p`
  font-size: 1.4rem;
  line-height: 1.45;
  margin: 1.5rem 0 3rem;
`;

const SignUp = styled.p`
  font-size: 1.2rem;
  text-align: center;
  button {
    color: ${props => props.theme.blue};
  }
`;

const MainContainer = styled.div`
  &.hidden {
    display: none;
  }
  .privacyPolicy {
    a {
      color: ${props => props.theme.blue};
    }
  }
`;

const BackButton = styled.button`
  position: absolute;
  top: 3.5rem;
  left: 2rem
  padding: 1rem;
  background: ${props => props.theme.grey1};
  border: 1px solid ${props => props.theme.grey2};
  border-radius: 100%;
  transition: background 0.2s ease;
  cursor: pointer;
  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0.6rem;
    width: 0.7rem;
    height: 0.2rem;
    background: ${props => props.theme.grey3};
  }
  &:before {
    top: 1.1rem;
    transform: rotate(45deg);
  }
  &:after {
    top: 0.8rem;
    transform: rotate(135deg);
  }
  &:hover {
    background: ${props => props.theme.grey2};
  }
  &:hover, &:focus, &:visited, &:active {
    outline: none;
  }
  &.hidden{
    display: none;
  }
`;

const LoginButton = styled(LinkButton)`
  width: 100%;
  margin: 0;
`;

export const LOGIN_TEXTS = {
  title: 'Log In',
  flash: {
    warning: 'Please check your inbox for a password reset email.'
  },
  email: {
    labelTitle: 'Email Address',
    placeholder: 'Enter your email address',
    required: 'Please enter an email',
    invalid: 'Please enter a valid email address'
  },
  password: {
    labelTitle: 'Password',
    placeholder: 'Enter your password',
    required: 'Please enter a password'
  }
};

export const RESET_PASSWORD_TEXTS = {
  title: 'Reset Password',
  helpText:
    'Enter your Sign In email address so we can send you steps on how to reset your password',
  email: {
    labelTitle: 'Email Address',
    placeholder: 'Enter your email address',
    required: 'Please enter an email',
    invalid: 'Please enter a valid email address'
  },
  successMessage: 'We have sent you an email for a password reset'
};

class LoginContainer extends Component {
  state = {
    email: '',
    password: '',
    emailError: '',
    passwordError: '',
    errorMessage: '',
    successMessage: '',
    isLogin: true
  };

  validateEmail = val => {
    if (!val) {
      return this.setState({
        emailError: LOGIN_TEXTS.email.required
      });
    } else if (!validator.isEmail(val)) {
      return this.setState({
        emailError: LOGIN_TEXTS.email.invalid
      });
    } else if (this.state.emailError) {
      return this.setState({
        emailError: ''
      });
    }
    return;
  };

  validatePassword = val => {
    if (!val) {
      return this.setState({
        passwordError: LOGIN_TEXTS.password.required
      });
    } else if (val && this.state.passwordError) {
      return this.setState({
        passwordError: ''
      });
    }
    return;
  };

  handleInputChange = (evt, field) => {
    const {
      target: { value }
    } = evt;
    this.setState({
      [field]: value
    });
    return field === 'email' ? this.validateEmail(value) : this.validatePassword(value);
  };

  handleLogin = async () => {
    const { email, password } = this.state;
    !email && this.validateEmail(email);
    !password && this.validatePassword(password);

    const { emailError, passwordError } = this.state;
    if (email && password && !emailError && !passwordError) {
      const { dispatch, history } = this.props;
      const error = await dispatch(loginActions.userLogin(email, password, history));
      if (error) {
        this.setState({ errorMessage: error.description, successMessage: '' });
      }
    }
  };

  handleResetPassword = async () => {
    const { email } = this.state;
    !email && this.validateEmail(email);

    const { emailError } = this.state;
    if (email && !emailError) {
      const { dispatch } = this.props;
      const error = await dispatch(login.actions.changePassword(email));
      if (!error) {
        this.resetState({
          isLogin: true,
          successMessage: RESET_PASSWORD_TEXTS.successMessage
        });
      } else {
        this.setState({ successMessage: '', errorMessage: error.description });
      }
    }
  };

  toggleTypeChange = () => {
    const { isLogin } = this.state;
    return this.resetState({ isLogin: !isLogin });
  };

  resetState = ({ isLogin = true, successMessage = '', errorMessage = '' }) => {
    return this.setState({
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      errorMessage,
      successMessage,
      isLogin
    });
  };

  render() {
    const {
      email,
      password,
      emailError,
      passwordError,
      successMessage,
      errorMessage,
      isLogin
    } = this.state;

    const { toSignUp } = this.props;

    const content = (
      <Fragment>
        <Header headerText={isLogin ? LOGIN_TEXTS.title : RESET_PASSWORD_TEXTS.title} />
        {isLogin && (
          <SignUp>
            Don't have an account? <ButtonText onClick={toSignUp}>Create an account</ButtonText>
          </SignUp>
        )}
        <BackButton
          type="button"
          className={isLogin ? 'hidden' : ''}
          onClick={this.toggleTypeChange}
        />
        <FlashMessage
          messageText={successMessage ? successMessage : errorMessage ? errorMessage : ''}
          hasError={!!errorMessage}
          isDisplay={!!successMessage || !!errorMessage}
        />
        <Body>
          <MainContainer className={isLogin ? 'login' : 'hidden'}>
            <SocialContainer />
            <LoginForm noValidate>
              <FormInput
                onChange={this.handleInputChange}
                value={email}
                field="email"
                labelTitle={LOGIN_TEXTS.email.labelTitle}
                placeholder={LOGIN_TEXTS.email.placeholder}
                type="email"
                hasError={!!emailError || !!errorMessage}
                errorMessage={emailError}
                required
              />
              <FormInput
                onChange={this.handleInputChange}
                value={password}
                field="password"
                labelTitle={LOGIN_TEXTS.password.labelTitle}
                placeholder={LOGIN_TEXTS.password.placeholder}
                type="password"
                hasError={!!passwordError || !!errorMessage}
                errorMessage={passwordError}
                required
              />
              <LoginButton small marginTop="1rem" marginBottom="1rem" onClick={this.handleLogin}>
                Log In
              </LoginButton>
            </LoginForm>
            <Footer>
              <p>
                <ButtonText onClick={this.toggleTypeChange}>Forgot Password?</ButtonText>
              </p>
              <PoweredConcora margin="1rem 0 0" />
            </Footer>
          </MainContainer>
          <MainContainer className={!isLogin ? 'reset-password' : 'hidden'}>
            <HelpText>{RESET_PASSWORD_TEXTS.helpText}</HelpText>
            <LoginForm noValidate>
              <FormInput
                onChange={this.handleInputChange}
                value={email}
                field="email"
                labelTitle={RESET_PASSWORD_TEXTS.email.labelTitle}
                placeholder={RESET_PASSWORD_TEXTS.email.placeholder}
                type="email"
                hasError={!!emailError}
                errorMessage={emailError}
                required
              />
              <LinkButton
                small
                marginTop="2rem"
                marginBottom="2rem"
                onClick={this.handleResetPassword}
              >
                Send Email
              </LinkButton>
              <PoweredConcora margin="1rem 0 0" />
            </LoginForm>
          </MainContainer>
        </Body>
      </Fragment>
    );
    return content;
  }
}

export default withRouter(connect()(LoginContainer));
