import React from 'react';
import styled from 'styled-components';

import Card from '../../shared/Card';
import media from '../../utils/media';

export const CardContainer = styled(Card)`
  width: 93%;
  margin-bottom: 1rem;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
  position: relative;
  height: 95%;
  background: white;
  ${media.small`
    width: 95%;
  `};
`;

export const CardImage = styled.img`
  background: ${props => props.settings && (props.settings.backgroundColor || 'white')}
  max-height: 85%;
  max-width: 100%;
  padding: 0px 10% 0px 10%;
  object-fit: contain;
  margin: 0 auto;
`;

export const CardInfo = styled.div`
  padding: 1rem 1rem 1.5rem;
  background: white;
  position: absolute;
  width: 100%;
  bottom: 0;
  border-top: 1px solid ${props => props.theme.grey1};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: background 0.2s ease;
  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }
`;

export const CardText = styled.div`
  display: block;
  cursor: pointer;
  width: 80%;
`;

export const ManufacturerName = styled.h3`
  font-size: 1.2rem;
  margin: 0.3rem 0 0;
  font-weight: 500;
  color: ${props => props.theme.grey4};
  ${media.medium`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${CardText}:hover & {
      white-space: normal;
    }
  `};
`;

const ManufacturerCard = ({ style, manufacturer }) => {
  const { name, manufacturerLogo, url, settings } = manufacturer;
  return (
    <a {...{ style }} href={url} target="_blank" rel="noopener noreferrer">
      <CardContainer>
        <CardImage src={manufacturerLogo} settings={settings} />
        <CardInfo>
          <CardText>
            <ManufacturerName>{name}</ManufacturerName>
          </CardText>
        </CardInfo>
      </CardContainer>
    </a>
  );
};

export default ManufacturerCard;
