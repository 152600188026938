import React from 'react';
import styled from 'styled-components';

import Container from '../Layout/Container';
import { Screen } from '../../global-styles';
import Armstrong from './armstrong.svg';
import Certainteed from './certainteed.svg';
import Kohler from './kohler.svg';
import HunterDouglas from './hunterdouglas.svg';
import USG from './usg.svg';

const Wrapper = styled.div`
  background: white;
  padding: 4rem 0;
  text-align: center;
  @media ${Screen.mdUp} {
    padding: 3rem 0 4rem;
  }
`;

const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

const Logo = styled.img`
  margin: 1.5rem 1rem;
`;

const Title = styled.h2`
  text-align: center;
  display: inline-block;
  background: #ffffff;
  box-shadow: 1px 1px 20px rgba(45, 87, 147, 0.12), 0px 1px 2px rgba(13, 87, 191, 0.13);
  border-radius: 23px;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  color: ${props => props.theme.blue};
  text-transform: uppercase;
  letter-spacing: 0.1px;
  line-height: 1;
`;

const SocialProof = () => (
  <Wrapper>
    <Container>
      <Title>Trusted Content By</Title>
      <Logos>
        <Logo src={Armstrong} />
        <Logo src={Certainteed} />
        <Logo src={Kohler} />
        <Logo src={HunterDouglas} />
        <Logo src={USG} />
      </Logos>
    </Container>
  </Wrapper>
);

export default SocialProof;
