import React from 'react';

import { throttle } from 'lodash';

const stickyWrapper = WrapperComponent => {
  return class Sticky extends React.Component {
    componentDidMount() {
      this.setState({
        filterBar: document.querySelector('.filter'),
        actionBanner: document.querySelector('.banner'),
        appBody: document.querySelector('.app-body')
      });

      window.addEventListener('scroll', this.handleScrolling);
    }

    componentWillUnmount() {
      if (!this.state) return;
      const { appBody } = this.state;

      window.removeEventListener('scroll', this.handleScrolling);
      appBody.style.marginTop = '';
      window.scrollTo(0, 0);
    }

    handleScrolling = throttle(() => {
      if (!this.state) return;

      const scrollOffsetY = window.scrollY;
      const { filterBar, appBody, actionBanner } = this.state;

      if (scrollOffsetY > 50) {
        if (filterBar) {
          filterBar.classList.add('filter-sticky');
        }
        if (actionBanner) {
          actionBanner.classList.add('banner-sticky');
        }
        if (appBody) {
          appBody.style.marginTop = '60px';
        }
      } else {
        if (filterBar) {
          filterBar.classList.remove('filter-sticky');
        }
        if (actionBanner) {
          actionBanner.classList.remove('banner-sticky');
        }
        if (appBody) {
          appBody.style.marginTop = '';
        }
      }
    }, 100);

    render() {
      return <WrapperComponent {...this.props} />;
    }
  };
};

export default stickyWrapper;
