import React from 'react';
import styled from 'styled-components';
import { history } from 'base-client';
import DisplayLink from '../../shared/DisplayLink';

const PrettyLink = styled(DisplayLink)`
  font-weight: 500;
  &:hover {
    color: ${props => props.theme.blue};
  }
`;

const resetClicks = () => {
  const nextArrow = document.querySelector('[data-tour-elem="right-arrow"]');
  const prevArrow = document.querySelector('[data-tour-elem="left-arrow"]');
  if (nextArrow) nextArrow.onclick = () => {};
  if (prevArrow) prevArrow.onclick = () => {};
};

const setClicks = (direction, fn, args = []) => {
  const arrow = document.querySelector(`[data-tour-elem="${direction}-arrow"]`);
  if (arrow) arrow.onclick = () => fn.apply(null, args);
};

const triggerClickEventOnClick = (direction, element) => {
  const arrow = document.querySelector(`[data-tour-elem="${direction}-arrow"]`);
  if (arrow) arrow.onclick = () => element.click();
};

export default [
  {
    selector: '.tutorial-step-1',
    content: ({ goTo, inDOM }) => {
      resetClicks();
      setClicks('right', history.push, ['/products']);
      return (
        <div>
          You can search for manufacturer products, or manufacturer sites, using the Concora Library
        </div>
      );
    },
    position: 'right',
    action: node => {
      // history.push('/products');
    },
    style: {},
    // Disable interaction for this specific step.
    // Could be enabled passing `true`
    // when `disableInteraction` prop is present in Tour
    stepInteraction: false,
    // Text read to screen reader software for this step's navigation dot
    navDotAriaLabel: 'Go to step 2'
  },
  {
    selector: '.tutorial-step-2',
    content: ({ goTo, inDOM }) => {
      resetClicks();
      setClicks('left', history.push, ['/']);
      return <div>Search by name, category, file types, revit families, or description</div>;
    },
    position: 'right',
    action: node => {
      // by using this, focus trap is temporary disabled
    },
    style: {},
    // Disable interaction for this specific step.
    // Could be enabled passing `true`
    // when `disableInteraction` prop is present in Tour
    stepInteraction: false,
    // Text read to screen reader software for this step's navigation dot
    navDotAriaLabel: 'Go to step 3'
  },
  {
    selector: '.tutorial-step-3',
    content: ({ goTo, inDOM }) => {
      resetClicks();
      setClicks('right', history.push, [
        '/products?%7B"filters"%3A%5B%7B"attribute"%3A"category"%2C"facets"%3A%5B"Ceilings"%5D%7D%5D%7D'
      ]);
      return <div>Select filters to refine your search</div>;
    },
    position: 'right',
    action: node => {
      // by using this, focus trap is temporary disabled
    },
    style: {},
    // Disable interaction for this specific step.
    // Could be enabled passing `true`
    // when `disableInteraction` prop is present in Tour
    stepInteraction: false,
    // Text read to screen reader software for this step's navigation dot
    navDotAriaLabel: 'Go to step 4'
  },
  {
    selector: '.tutorial-step-4',
    content: ({ goTo, inDOM }) => {
      resetClicks();
      return (
        <div>
          Click on the product to go travel to the product's manufacturer site. Quickly download
          Technical Product Content.
        </div>
      );
    },
    position: 'right',
    action: node => {
      // by using this, focus trap is temporary disabled
    },
    style: {},
    // Disable interaction for this specific step.
    // Could be enabled passing `true`
    // when `disableInteraction` prop is present in Tour
    stepInteraction: false,
    // Text read to screen reader software for this step's navigation dot
    navDotAriaLabel: 'Go to step 5'
  },
  {
    selector: '.tutorial-step-5',
    content: ({ goTo, inDOM }) => {
      return <div>Select products to compare or add to a project.</div>;
    },
    position: 'right',
    action: node => {
      resetClicks();
      if (node) {
        const arrow = document.querySelector('[data-tour-elem="right-arrow"]');
        if (arrow && !node.checked) arrow.onclick = () => node.click();
      }
    },
    style: {},
    // Disable interaction for this specific step.
    // Could be enabled passing `true`
    // when `disableInteraction` prop is present in Tour
    stepInteraction: false,
    // Text read to screen reader software for this step's navigation dot
    navDotAriaLabel: 'Go to step 6'
  },
  {
    selector: '.tutorial-step-6',
    content: ({ goTo, inDOM }) => {
      resetClicks();
      return (
        <div>
          Once you've selected a product, you can compare the product with other products (up to
          four), or add that product to a project.
        </div>
      );
    },
    position: 'right',
    action: node => {
      // by using this, focus trap is temporary disabled
    },
    style: {},
    // Disable interaction for this specific step.
    // Could be enabled passing `true`
    // when `disableInteraction` prop is present in Tour
    stepInteraction: false,
    // Text read to screen reader software for this step's navigation dot
    navDotAriaLabel: 'Go to step 7'
  },
  {
    selector: '.tutorial-step-7',
    content: ({ goTo, inDOM }) => {
      resetClicks();
      return (
        <div>
          Congratulations! You've completed the tutorial. For more information about the Concora
          Library, check out our <PrettyLink url="https://concora.com">corporate site</PrettyLink>
        </div>
      );
    },
    position: 'center',
    action: node => {
      // by using this, focus trap is temporary disabled
    },
    style: {},
    // Disable interaction for this specific step.
    // Could be enabled passing `true`
    // when `disableInteraction` prop is present in Tour
    stepInteraction: false,
    // Text read to screen reader software for this step's navigation dot
    navDotAriaLabel: 'Go to step 8'
  }
];
