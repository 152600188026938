import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { history } from 'base-client';

import media from '../../utils/media';
import { required, max50, max100 } from '../../utils/reduxFormValidator';
import buttonStyles from '../../shared/styles/buttonStyles';
import ReduxFormInput from '../../shared/ReduxFormInput';
import PageHeader from '../../shared/PageHeader';
import { submitDataDeletionRequest } from './actions';
import Button from '../../shared/Button';
import Modal from '../../shared/Modal';

export const GDPRForm = styled.div`
  width: 100%;
  margin: 0 auto;
  ${media.medium`
    width: 50%;
  `} ${media.large`
    width: 50%;
  `};
`;

const StyledSubmit = styled.button`
  ${buttonStyles};
`;

const ButtonText = styled.span`
  margin-left: 20px;
  color: ${props => props.theme.blue};
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.blueDarkest};
  }
`;

const Subtitle = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.8rem;
  color: ${props => props.theme.grey5};
  margin-bottom: 2rem;
  margin-top: -2rem;
`;

const NotifyRequestReceiptModal = ({ isOpen, title, message, onClose }) => {
  const StyledContent = styled.div`
    font-size: 1.4rem;
    min-width: 35rem;
    h3 {
      padding-right: 2rem;
    }
    .body {
      font-size: 1.4rem;
      padding: 0 0 2rem;
    }
    .footer {
      padding: 0 0 2rem;
      button {
        &:first-child {
          margin-right: 3rem;
        }
      }
    }
  `;
  return (
    <React.Fragment>
      <Modal {...{ title, open: isOpen, onClose }}>
        <StyledContent>
          <div className="body">{message}</div>
          <div className="footer">
            <Button danger noUpperCase fontWeight="500" onClick={onClose}>
              {'Back To Home Page'}
            </Button>
          </div>
        </StyledContent>
      </Modal>
    </React.Fragment>
  );
};

const GDPRContainer = ({ handleSubmit, pristine, reset, submitting, valid, dispatch }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    history.push('');
  };
  const submitForm = values => {
    dispatch(submitDataDeletionRequest(values));
    setIsOpen(true);
  };
  return (
    <GDPRForm>
      <PageHeader title="Delete My Personal Data"></PageHeader>
      <Subtitle>
        Fill out the form below, or call 877-436-9031 to submit your data deletion request. Concora
        will process the request within 45 days, as required by California Consumer Privacy Act
      </Subtitle>
      <form onSubmit={handleSubmit(values => submitForm(values))}>
        <div>
          <Field
            name="firstName"
            type="text"
            component={ReduxFormInput}
            label="firstName"
            labelTitle="First Name - So We Can Verify Your Request"
            validate={[required, max50]}
            required
          />
          <Field
            name="lastName"
            type="text"
            component={ReduxFormInput}
            label="lastName"
            labelTitle="Last Name - So We Can Verify Your Request"
            validate={[required, max50]}
            required
          />
          <Field
            name="email"
            type="email"
            component={ReduxFormInput}
            label="email"
            labelTitle="Email - So We Can Notify You On Data Deletion"
            validate={[required, max50]}
            required
          />
        </div>
        <StyledSubmit type="submit" disabled={!valid || pristine || submitting}>
          Submit
        </StyledSubmit>
        <ButtonText disabled={!valid || pristine || submitting} onClick={reset}>
          Cancel
        </ButtonText>
        <NotifyRequestReceiptModal
          {...{
            isOpen,
            title: 'We have received your request',
            message: 'We will notify you via email when we have completed your request. Thank you.',
            onClose: () => closeModal()
          }}
        />
      </form>
    </GDPRForm>
  );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'GDPR Personal Data Request',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })(GDPRContainer)
);
