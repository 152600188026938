import { config, login, reducerUtil, history } from 'base-client';
import _ from 'lodash';
import KeenTracking from 'keen-tracking';
import uuid from 'uuid/v4';
import segmentLoader from 'analytics.js-loader';
import configMap from '../../../configurations/configMap';
import userReducerData from '../../UserProfile/reducerData';
import userActions from '../../UserProfile/actions';

const cookieName = 'library-cookie';
const visitorIdName = 'guest_id';
let keen;
let segment;
let extendedSegmentEvent;
const start = keenData => async dispatch => {
  if (keenData.projectId) {
    keen = new KeenTracking({ ...keenData });
    const writeKey = dispatch(
      config.actions.getData(configMap.analytics.name, configMap.analytics.segment.name)
    );
    if (writeKey) {
      segment = segmentLoader({
        writeKey,
        // you can skip the first analytics.page() call if needed, #1
        skipPageCall: true
      });
    }
    dispatch(setVisitorId());
    await dispatch(setData());
    dispatch(trackPageViews());
  }
};

const setVisitorId = () => dispatch => {
  if (!dispatch(getVisitorId())) dispatch(newVisitorId());
};

const newVisitorId = newId => () => {
  const cookie = KeenTracking.utils.cookie(cookieName);
  cookie.set(visitorIdName, newId || KeenTracking.helpers.getUniqueId());
};

const getVisitorId = () => () => {
  const cookie = KeenTracking.utils.cookie(cookieName);
  return cookie.get(visitorIdName);
};

const getUserId = () => (dispatch, getState) => {
  const profile = reducerUtil.getSlice(login.reducerData, login.reducerData.profile, getState());
  const { 'https://permissions': permissions } = profile || {};
  const { user_id: userId } = permissions || {};
  return userId;
};

const getUserProfile = () => (dispatch, getState) => {
  const profile = reducerUtil.getSlice(userReducerData, userReducerData.data, getState());
  return profile;
};

const userVisitorId = () => (dispatch, getState) => {
  const profile = reducerUtil.getSlice(login.reducerData, login.reducerData.profile, getState());
  const { 'https://permissions': permissions } = profile || {};
  const { visitor_id: visitorId } = permissions || {};
  if (visitorId) dispatch(newVisitorId(visitorId));
};

const setData = () => async (dispatch, getState) => {
  keen.extendEvents(() => {
    const tenantId = '00000000-0000-0000-0000-000000000000';
    const userId = dispatch(getUserId());
    return {
      tenant_id: tenantId,
      visitor_id: dispatch(getVisitorId()),
      user_id: userId,
      site: window.location.hostname,
      source: 'library',
      geo: {
        /* Enriched data from the API will be saved here */
        /* https://keen.io/docs/api/?javascript#ip-to-geo-parser */
        // eslint-disable-next-line no-template-curly-in-string
        ip_address: '${keen.ip}'
      },
      page: {
        /* Enriched */
        title: document.title,
        url: document.location.href
      },
      referrer: {
        /* Enriched */
        url: document.referrer
      },
      tech: {
        /* Enriched */
        browser: KeenTracking.helpers.getBrowserProfile(),
        // eslint-disable-next-line no-template-curly-in-string
        user_agent: '${keen.user_agent}'
      },
      keen: {
        addons: [
          {
            name: 'keen:ip_to_geo',
            input: {
              ip: 'geo.ip_address'
            },
            output: 'geo'
          },
          {
            name: 'keen:ua_parser',
            input: {
              ua_string: 'tech.user_agent'
            },
            output: 'tech'
          },
          {
            name: 'keen:url_parser',
            input: {
              url: 'page.url'
            },
            output: 'page'
          },
          {
            name: 'keen:referrer_parser',
            input: {
              referrer_url: 'referrer.url',
              page_url: 'page.url'
            },
            output: 'referrer'
          }
        ]
      }
    };
  });
  if (segment) {
    segment.reset();
    const user_id = dispatch(getUserId());
    let profile;
    if (user_id) {
      await dispatch(userActions.getUserAccount());
      profile = dispatch(getUserProfile());
      segment.identify(user_id, profile || {});
    }
    const tenantId = '00000000-0000-0000-0000-000000000000';
    extendedSegmentEvent = {
      profile,
      tenant_id: tenantId,
      visitor_id: dispatch(getVisitorId()),
      user_id,
      site: window.location.hostname,
      source: 'library',
      page: {
        title: document.title,
        url: document.location.href
      },
      referrer: {
        url: document.referrer
      },
      tech: {
        browser: KeenTracking.helpers.getBrowserProfile()
      }
    };
  }
};

const track = (event, meta = {}) => async dispatch => {
  if (!keen) return;

  const eventId = uuid();
  try {
    const data = { type: event, event_id: eventId, eventMetadata: meta };
    if (meta.tenant_id) data.tenant_id = meta.tenant_id;
    await keen.recordEvent(event, data);
    if (segment) {
      if (event === 'pageview') {
        segment.page(null, _.merge(data, extendedSegmentEvent));
      }
      segment.track(event, _.merge(data, extendedSegmentEvent));
    }
  } catch (error) {
    dispatch(config.actions.error(error));
  }
  return eventId;
};

const trackDownload = meta => dispatch => dispatch(track('download', meta));

const trackPageViews = () => dispatch => {
  dispatch(track('referral'));
  history.listen((location, action) => {
    if (location.pathname === '/products') return;
    dispatch(track('pageview', { action }));
  });
};

export default {
  start,
  track,
  trackDownload,
  newVisitorId,
  getVisitorId,
  getUserId,
  userVisitorId
};
