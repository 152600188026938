import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Grid, WindowScroller } from 'react-virtualized';
import _ from 'lodash';
import ManufacturerCard from './ManufacturerCard';
import media from '../../utils/media';
import { config, login, api } from 'base-client';
import configMap from '../../configurations/configMap';

const manufacturersEndpoint = 'manufacturers?limit=500';
const brandedSitesEndpoint = 'brandedSites/?limit=500';
const columnCount = 4;
const gutter = 24;
const columnWidth = 200 + gutter;
const rowHeight = 200 + gutter;

function ManufacturerSearch(props) {
  const [manufacturers, setManufacturers] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const loadManufacturers = async () => {
      const host = await dispatch(
        config.actions.getData(configMap.api.name, configMap.api.host.name)
      );
      const redirect = process.env.REACT_APP_MANUFACTURERS_REDIRECT_DOMAIN || 'concora.com';
      const brandedSites = await fetch(`${host}/${brandedSitesEndpoint}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(r => r.json());
      let chunks = brandedSites.reduce((acc, brandedSite) => {
        if (!brandedSite.aggregated) return acc;
        let letter = brandedSite.settings.name[0].toLocaleUpperCase();
        brandedSite.url = `https://${brandedSite.domains[0]}.${redirect}`;
        brandedSite.manufacturerLogo = brandedSite.settings.logo;
        brandedSite.name = brandedSite.settings.name;
        if (acc[letter]) {
          acc[letter].push(brandedSite);
        } else {
          acc[letter] = [brandedSite];
        }
        return acc;
      }, {});
      const sortedChunks = {};
      Object.entries(chunks).forEach(([key, values], idx) => {
        sortedChunks[key] = values.sort(function(a, b) {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
      });
      setManufacturers(sortedChunks);
    };
    loadManufacturers();
  }, []);

  const LetterSection = styled.h1`
    font-size: 1.8rem;
    font-weight: 600;
    margin: 3rem 0 0 1rem;
    color: ${props => props.theme.grey6};
    border-top: 1px solid ${props => props.theme.grey1};
    padding: 1rem 0 0;
    ${media.medium`
      font-size: 1.8rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
    &:first-of-type {
      border-top: none;
    }
  `;

  const ManufacturerSectionBody = styled.div`
    min-height: 900px;
  `;

  const GridSection = ({ letter }) => {
    const manufacturerListing = manufacturers[letter];

    const GridCell = ({ columnIndex, rowIndex, style }) => {
      const index = rowIndex * columnCount + columnIndex;
      const targetManufacturer = manufacturerListing[index];

      if (targetManufacturer) {
        return (
          <ManufacturerCard
            style={{
              ...style,
              left: style.left + gutter / 2,
              top: style.top + gutter / 2,
              width: style.width - gutter / 2,
              height: style.height - gutter / 2
            }}
            manufacturer={targetManufacturer}
          />
        );
      }
      return null;
    };

    return (
      <React.Fragment>
        <LetterSection>{letter}</LetterSection>
        <WindowScroller>
          {({ height, isScrolling, onChildScroll, scrollTop }) => (
            <Grid
              autoHeight
              height={height}
              columnCount={columnCount}
              columnWidth={columnWidth}
              rowCount={Math.ceil(manufacturers[letter].length / columnCount)}
              rowHeight={rowHeight}
              width={columnWidth * columnCount + gutter}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              scrollTop={scrollTop}
              cellRenderer={GridCell}
              style={{ overflowY: 'hidden !important', height: '100% !important' }}
            >
              {GridCell}
            </Grid>
          )}
        </WindowScroller>
      </React.Fragment>
    );
  };

  const ManufacturersSection = () => {
    return Object.keys(manufacturers)
      .sort()
      .map(letter => {
        return <GridSection letter={letter} />;
      });
  };

  return (
    <ManufacturerSectionBody>
      <ManufacturersSection />
    </ManufacturerSectionBody>
  );
}

export default ManufacturerSearch;
