import React from 'react';
import styled from 'styled-components';

import ReduxFormError from './ReduxFormError';
import FormDropdown from './FormDropdown';

export const DropdownContainer = styled.div`
  margin-bottom: 1.5rem;
  text-align: left;
  margin-top: ${props => (props.labelTitle ? null : '-1rem')};
  vertical-align: top;
`;

const ReduxFormDropdown = ({ labelTitle, children, input, meta, className, ...props }) => (
  <DropdownContainer {...{ labelTitle, className }}>
    <FormDropdown {...input} {...{ labelTitle, ...props }}>
      {children}
    </FormDropdown>
    <ReduxFormError {...{ meta }} />
  </DropdownContainer>
);

export default ReduxFormDropdown;
