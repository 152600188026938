import React from 'react';
import styled from 'styled-components';

const SelectElm = styled.div`
  display: flex;
  font-size: 1.4rem;
  cursor: pointer;
  width: 10%;
`;

const Label = styled.label`
  margin-left: 0.35rem;
  cursor: pointer;
`;

const Checkbox = ({ id, label, value, checked, onChange, className, ...props }) => (
  <SelectElm {...props} className="select">
    <input
      className={className}
      type="checkbox"
      id={id}
      readOnly
      checked={checked}
      value={value || id}
      onClick={() => onChange({ id, checked })}
    />
    {label ? <Label htmlFor={id}>{label}</Label> : null}
  </SelectElm>
);

Checkbox.defaultProps = {
  checked: false
};

export default Checkbox;
