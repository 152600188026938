import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, AutoSizer, WindowScroller } from 'react-virtualized';
import _ from 'lodash';
import SpaceCard from './SpaceCard';
import HeroArticle from '../LandingPage/HeroArticle';
import { config, login, api } from 'base-client';
import configMap from '../../configurations/configMap';
import spaces from './spaces';
import spacesHero from './spacesHero.jpg';

const gutter = 36;
const GRID_COLUMN_WIDTH = 250 + gutter;
const GRID_ROW_HEIGHT = 250 + gutter * 2;

const CategorySelectionContainer = styled.div`
  margin-left: 0;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
`;

const SpacesMetaData = styled.div`
  font-size: 1.6rem;
  padding: 1rem;
  font-weight: 600;
  margin-left: 2rem;
  margin-top: auto;
  margin-bottom: auto;
  width: 37%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftCategoryButton = styled.div`
  ${props => props.selected && 'background: #0A7BE4;'}
  ${props => props.selected && 'color: #FFFFFF;'}
  text-align: center;
  border: 2px solid #0a7be4;
  border-radius: 10rem 0 0 10rem;
  font-size: 1.6rem;
  padding: 0.8rem 1.5rem 0.8rem 1.5rem;
  font-weight: 600;
  cursor: pointer;
`;

const MiddleCategoryButton = styled.div`
  ${props => props.selected && 'background: #0A7BE4;'}
  ${props => props.selected && 'color: #FFFFFF;'}
  text-align: center;
  border-top: 2px solid #0a7be4;
  border-bottom: 2px solid #0a7be4;
  padding: 0.8rem 1.5rem 0.8rem 1.5rem;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
`;

const RightCategoryButton = styled.div`
  ${props => props.selected && 'background: #0A7BE4;'}
  ${props => props.selected && 'color: #FFFFFF;'}
  text-align: center;
  border: 2px solid #0a7be4;
  border-radius: 0 10rem 10rem 0;
  padding: 0.8rem 1.5rem 0.8rem 1.5rem;
  font-size: 1.6rem;
  font-weight: 600;
  cursor: pointer;
`;

const GridContainer = styled.div`
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const SmartBIMSpaces = props => {
  const [spaceCategory, setSpaceCategory] = useState('healthcare');
  // const SpacesImages

  const SpacesSectionBody = styled.div`
    min-height: 900px;
  `;

  const GridSection = ({}) => {
    return (
      <GridContainer>
        <HeroArticle
          {...{
            title: 'Concora Spaces',
            subTitle: 'Supporting examples of manufacturer products in architectural spaces.',
            description:
              'Search for the space that matches your use case, download an example Revit or CAD file, then create your own project using Projects by Concora. Select manufacturer products for your project and generate submittals from their sites.',
            image: spacesHero
          }}
        />
        <Header>
          <SpacesMetaData>{`We have ${
            spaces[spaceCategory].length
          } spaces under ${spaceCategory[0].toUpperCase() +
            spaceCategory.slice(1)}`}</SpacesMetaData>
          <CategorySelectionContainer>
            <LeftCategoryButton
              selected={spaceCategory === 'healthcare'}
              onClick={() => setSpaceCategory('healthcare')}
            >
              Healthcare
            </LeftCategoryButton>
            <MiddleCategoryButton
              selected={spaceCategory === 'education'}
              onClick={() => setSpaceCategory('education')}
            >
              Education
            </MiddleCategoryButton>
            <RightCategoryButton
              selected={spaceCategory === 'residential'}
              onClick={() => setSpaceCategory('residential')}
            >
              Residential
            </RightCategoryButton>
          </CategorySelectionContainer>
        </Header>
        <WindowScroller>
          {({ height, isScrolling, onChildScroll }) => (
            <AutoSizer disableHeight>
              {({ width }) => {
                const columnCount = Math.max(Math.floor(width / GRID_COLUMN_WIDTH), 1);
                const columnWidth = Math.floor(width / columnCount);
                const rowCount = Math.ceil(spaces[spaceCategory].length / columnCount);
                const rowHeight = Math.round((GRID_ROW_HEIGHT * columnWidth) / GRID_COLUMN_WIDTH);
                const GridCell = ({ columnIndex, rowIndex, style }) => {
                  const index = rowIndex * columnCount + columnIndex;
                  const targetSpace = spaces[spaceCategory][index];
                  console.log({ columnIndex, rowIndex, columnCount, index });
                  if (targetSpace) {
                    return (
                      <SpaceCard
                        key={`SpaceCard-${index}`}
                        style={{
                          ...style,
                          left: style.left + gutter / 2,
                          top: style.top + gutter / 2,
                          width: style.width - gutter / 2,
                          height: style.height - gutter / 2
                        }}
                        space={targetSpace}
                      />
                    );
                  }
                  return null;
                };
                return (
                  <Grid
                    autoHeight
                    height={height}
                    columnCount={columnCount}
                    columnWidth={columnWidth}
                    rowCount={rowCount}
                    rowHeight={rowHeight}
                    width={width}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    cellRenderer={GridCell}
                    style={{
                      overflowY: 'hidden !important',
                      height: '100% !important',
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}
                    containerStyle={{
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}
                  >
                    {GridCell}
                  </Grid>
                );
              }}
            </AutoSizer>
          )}
        </WindowScroller>
      </GridContainer>
    );
  };

  return (
    <SpacesSectionBody>
      <GridSection />
    </SpacesSectionBody>
  );
};

export default SmartBIMSpaces;
