import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { login, reducerUtil } from 'base-client';

import media from '../../utils/media';
import { required, max50, max100 } from '../../utils/reduxFormValidator';
import industries from '../../utils/staticData/industries';
import occupations from '../../utils/staticData/occupations';
import accountActions from './actions';
import accountData from './reducerData';
import buttonStyles from '../../shared/styles/buttonStyles';
import ReduxFormInput from '../../shared/ReduxFormInput';
import FormDropdownOption from '../../shared/FormDropdownOption';
import PageHeader from '../../shared/PageHeader';
import ReduxFormDropdown from '../../shared/ReduxFormDropdown';

export const UserForm = styled.div`
  width: 100%;
  margin: 0 auto;
  ${media.medium`
    width: 50%;
  `} ${media.large`
    width: 33%;
  `};
`;

export const DropdownContainer = styled.div`
  margin-bottom: 2rem;
  text-align: left;
  margin-top: ${props => (props.labelTitle ? null : '-1rem')};
`;

const StyledSubmit = styled.button`
  ${buttonStyles};
`;

const ButtonText = styled.span`
  margin-left: 20px;
  color: ${props => props.theme.blue};
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.blueDarkest};
  }
`;

class UserProfileContainer extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    valid: PropTypes.bool,
    submitting: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    token: PropTypes.string
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(accountActions.getUserAccount());
  }

  render() {
    const { token, handleSubmit, pristine, reset, submitting, valid, dispatch } = this.props;

    if (!token) return <Redirect to="/" />;

    return (
      <UserForm>
        <PageHeader title="My Account" />
        <form onSubmit={handleSubmit(values => dispatch(accountActions.setUserAccount(values)))}>
          <div>
            <Field
              name="firstName"
              type="text"
              component={ReduxFormInput}
              label="firstName"
              labelTitle="First Name"
              validate={[required, max50]}
              required
            />
            <Field
              name="lastName"
              type="text"
              component={ReduxFormInput}
              label="lastName"
              labelTitle="Last Name"
              validate={[required, max50]}
              required
            />
            <Field
              name="companyName"
              type="text"
              component={ReduxFormInput}
              label="company"
              labelTitle="Company"
              validate={[required, max100]}
              required
            />

            <Field
              name="industry"
              type="text"
              component={ReduxFormDropdown}
              label="industry"
              labelTitle="Industry"
              validate={[required]}
              required
            >
              <FormDropdownOption value={''} optionLabel={'Select an Industry'} disabled />
              {industries.map(item => (
                <FormDropdownOption key={item} value={item} optionLabel={item} />
              ))}
            </Field>

            <Field
              name="occupation"
              type="text"
              component={ReduxFormDropdown}
              label="occupation"
              labelTitle="Occupation"
              validate={[required]}
              required
            >
              <FormDropdownOption value={''} optionLabel={'Select an Occupation'} disabled />
              {occupations.map(item => (
                <FormDropdownOption key={item} value={item} optionLabel={item} />
              ))}
            </Field>

            <Field
              name="phoneNumber"
              type="text"
              component={ReduxFormInput}
              label="phone"
              labelTitle="Phone Number"
              validate={[max50]}
            />
          </div>
          <StyledSubmit type="submit" disabled={!valid || pristine || submitting}>
            Submit
          </StyledSubmit>
          <ButtonText disabled={!valid || pristine || submitting} onClick={reset}>
            Cancel
          </ButtonText>
        </form>
      </UserForm>
    );
  }
}

const mapStateToProps = state => ({
  token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
  initialValues: reducerUtil.getSlice(accountData, accountData.data, state)
});

export default connect(mapStateToProps)(
  reduxForm({
    form: accountData.name,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })(UserProfileContainer)
);
