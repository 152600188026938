import React from 'react';
import { connect } from 'react-redux';
import { login, reducerUtil } from 'base-client';

import analyticsActions from '../components/Analytics/actions';

const FileContainer = ({
  canDownload,
  meta,
  title,
  url,
  children,
  dispatch,
  noLoginRequired,
  className
}) => {
  const onClick =
    canDownload || noLoginRequired
      ? () => {
          if (meta) {
            dispatch(analyticsActions.trackDownload(meta));
          }
        }
      : () => dispatch(login.actions.toLogin());

  const href = canDownload || noLoginRequired ? url : undefined;
  return (
    <a className={className} download={title} {...{ onClick, href }}>
      {React.Children.map(children, child => React.cloneElement(child, { canDownload }))}
    </a>
  );
};

const mapStateToProps = state => {
  const token = reducerUtil.getSlice(login.reducerData, login.reducerData.token, state);
  return { canDownload: token };
};

export default connect(mapStateToProps)(FileContainer);
