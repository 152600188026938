import { api, reducerUtil } from 'base-client';
import { chain } from 'lodash';

import reducerData from '../reducerData';
import getAttributeValue from 'utils/getAttributeValue';

const getProducts = ids => async (dispatch, getState) => {
  let results = [];
  Promise.all(
    ids.map(async id => {
      const product = await dispatch(api.actions.get(`products/${id}`));
      let state = getState();
      const assetList = [];

      const assets = assetList
        .map(({ attributeId: id, attributeName: name, displayName, color }) => {
          const { information } = product.productInfo || {};
          const url = getAttributeValue({
            id,
            name,
            list: information || [],
            system: product
          });

          return {
            id,
            color,
            url,
            name: displayName || name,
            meta: {
              product_id: product.id,
              productName: product.name,
              productCategory: product.category,
              attribute_id: id,
              attributeName: name,
              assetUrl: url
            }
          };
        })
        .filter(({ name, url }) => name && url);
      product.assets = assets || [];

      results.push(product);
    })
  )
    .then(() =>
      dispatch(
        reducerUtil.setSlice(
          reducerData,
          reducerData.products,
          chain(results)
            .sortBy('name')
            .value()
        )
      )
    )
    .catch(e => console.log(e));
};

export default { getProducts };
