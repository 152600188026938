import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AutoSizer, InfiniteLoader, WindowScroller } from 'react-virtualized';
import { reducerUtil } from 'base-client';
import HybridView from './HybridView';

import ProductSearch from './ProductSearch';
import searchActions from './actions';
import searchData from './reducerData';

import stickyWrapper from './StickyWrapper';

class ProductSearchContainer extends React.Component {
  static defaultProps = {
    list: [],
    selectedProducts: []
  };

  state = {};

  componentDidMount() {
    const {
      history: {
        location: { search }
      },
      selectedProducts,
      dispatch
    } = this.props;

    if (!search && selectedProducts && selectedProducts.length > 0) {
      dispatch(searchActions.clearSelectedProducts());
    }
    this.handleSearch();
  }

  componentDidUpdate() {
    this.handleSearch();
  }

  handleSearch = () => {
    const { lastSearch } = this.state;
    const {
      history: {
        location: { search }
      }
    } = this.props;

    if (search !== lastSearch) {
      const { dispatch } = this.props;
      this.setState({ lastSearch: search });
      dispatch(searchActions.search(true));
    }
  };

  updateFilter = ({ checked, attribute, facets, isSingleSelect }) => {
    const { dispatch } = this.props;
    if (isSingleSelect) return dispatch(searchActions.updateFilter({ attribute, facets }));

    if (checked) dispatch(searchActions.removeFilter({ attribute, facets }));
    else dispatch(searchActions.addFilter({ attribute, facets }));
  };

  toggleSelectProduct = product => {
    const { dispatch } = this.props;
    const { checked, id, name, manufacturer_id } = product;
    if (!checked) {
      return dispatch(searchActions.selectProduct({ id, name, tenant_id: manufacturer_id }));
    } else {
      return dispatch(searchActions.deselectProduct({ id, name, tenant_id: manufacturer_id }));
    }
  };

  render() {
    const { list, meta, query, fetchId, dispatch, defaultFilter, selectedProducts } = this.props;

    const length = list && list.length;

    //get filters props
    const { filters: baseFilters = [] } = meta || {};
    const { filters: selected = [] } = query || {};
    const filterProps = {
      filters: baseFilters.sort((a, b) => (parseInt(a.priority) > parseInt(b.priority) ? 1 : -1)),
      selected,
      defaultFilter,
      updateFilter: this.updateFilter
    };

    /* Determine if there is more to load based on the metadata */
    let hasNextPage;
    if (meta) {
      const {
        pagination: { page, pageCount }
      } = meta;
      hasNextPage = page < pageCount;
    }

    /* Create react-virtualized view props */
    const rowCount = hasNextPage ? length + 1 : length + 0 || 0;
    const loadMoreRows = fetchId ? () => null : () => dispatch(searchActions.nextProductPage());
    const isRowLoaded = ({ index }) => !hasNextPage || index < length;

    const infiniteProps = { rowCount, loadMoreRows, isRowLoaded };

    const getProducts = () => {
      return list.map(product => {
        return {
          ...product,
          checked: !selectedProducts
            ? false
            : !!selectedProducts.find(({ id }) => id === product.id)
        };
      });
    };

    const viewProps = {
      rowCount,
      products: getProducts(),
      toggleSelectProduct: this.toggleSelectProduct,
      dispatch
    };

    const productSearchProps = {
      filterProps,
      selectedProducts,
      clearSelectedProducts: () => dispatch(searchActions.clearSelectedProducts())
    };

    const view = viewProps => {
      return <HybridView {...viewProps} />;
    };
    return (
      <ProductSearch {...productSearchProps}>
        <InfiniteLoader {...infiniteProps}>
          {({ onRowsRendered, registerChild }) => (
            <WindowScroller>
              {({ height, isScrolling, scrollTop }) => (
                <AutoSizer disableHeight>
                  {({ width }) =>
                    view({
                      ...viewProps,
                      autoHeight: true,
                      height,
                      isScrolling,
                      scrollTop,
                      onRowsRendered,
                      registerChild,
                      width
                    })
                  }
                </AutoSizer>
              )}
            </WindowScroller>
          )}
        </InfiniteLoader>
      </ProductSearch>
    );
  }
}

const mapStateToProps = state => ({
  list: reducerUtil.getSlice(searchData, searchData.list, state),
  meta: reducerUtil.getSlice(searchData, searchData.meta, state),
  fetchId: reducerUtil.getSlice(searchData, searchData.fetchId, state),
  selectedProducts: reducerUtil.getSlice(searchData, searchData.selectedProducts, state) || [],
  query: reducerUtil.getSlice(searchData, searchData.query, state)
});

export default withRouter(stickyWrapper(connect(mapStateToProps)(ProductSearchContainer)));
