import React from 'react';
import styled from 'styled-components';

import Card from '../../shared/Card';
import media from '../../utils/media';
import HeaderAssets from 'shared/HeaderAssets';
export const CardContainer = styled(Card)`
  width: 93%;
  margin-bottom: 1rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: visible;
  position: relative;
  height: 95%;
  background: white;
  ${media.small`
    width: 95%;
  `};
`;

export const CardImage = styled.img`
  background: white;
  height: 60%;
  max-width: 200%;
  padding: 0px 10% 0px 10%;
  object-fit: contain;
  margin: 0 auto;
`;

export const CardInfo = styled.div`
  padding: 1rem 1rem 1.5rem;
  background: white;
  height: 40%
  width: 100%;
  border-top: 1px solid ${props => props.theme.grey1};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  transition: background 0.2s ease;
  &:hover {
    background: rgba(255, 255, 255, 0.9);
  }
`;

export const CardText = styled.div`
  display: block;
  width: 100%;
  margin-bottom: -1.2rem;
`;

export const SpaceName = styled.h3`
  font-size: 1.6rem;
  margin: 0.15rem 0 0;
  font-weight: 600;
  color: ${props => props.theme.grey4};
  ${media.medium`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${CardText}:hover & {
      white-space: normal;
    }
  `};
`;

export const SpaceDescription = styled.h3`
  font-size: 1.3rem;
  margin: 0.15rem 0 0;
  font-weight: 400;
  color: ${props => props.theme.grey4};
  ${media.medium`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    ${CardText}:hover & {
      white-space: normal;
    }
  `};
`;

const AssetsTitle = styled.h3`
  letter-spacing: 0.3px;
  color: ${props => props.theme.grey3};
  position: relative;
  margin-top: 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const SpaceCard = ({ style, space }) => {
  const {
    LayoutName,
    SearchDescription,
    ImageURL,
    RenderingURL,
    RevitURL,
    DwfURL,
    FloorPlanURL
  } = space;
  const assets = [
    { name: 'Revit', url: RevitURL, color: '#77899E', id: `SpaceCard-${LayoutName}-Revit` },
    { name: 'CAD', url: DwfURL, color: '#77899E', id: `SpaceCard-${LayoutName}-CAD` },
    {
      name: 'Floor Plan',
      url: FloorPlanURL,
      color: '#77899E',
      id: `SpaceCard-${LayoutName}-Floor Plan`
    },
    { name: 'Render', url: RenderingURL, color: '#77899E', id: `SpaceCard-${LayoutName}-Render` }
  ];
  return (
    <CardContainer {...{ style }}>
      <CardImage src={ImageURL} />
      <CardInfo>
        <CardText>
          <SpaceName>{LayoutName}</SpaceName>
          <SpaceDescription>{SearchDescription}</SpaceDescription>
        </CardText>
        <HeaderAssets noLoginRequired={true} {...{ assets }}>
          <AssetsTitle>Links</AssetsTitle>
        </HeaderAssets>
      </CardInfo>
    </CardContainer>
  );
};

export default SpaceCard;
