import React from 'react';
import styled from 'styled-components';

import FormDropdown from 'shared/FormDropdown';
import FormDropdownOption from 'shared/FormDropdownOption';

const Container = styled.div`
  display: flex;
  align-items: baseline;
`;

const Label = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 0.3px;
  color: #77899e;
  padding-right: 1rem;
`;

class SortDropdown extends React.Component {
  static defaultProps = {
    queryParams: {},
    sortOptions: []
  };

  render() {
    const { queryParams, sortOptions, handleChange, ...props } = this.props;
    return (
      <Container className="sort-dropdown" {...props}>
        <Label>Sort By</Label>
        <FormDropdown onChange={event => handleChange(event.target.value)}>
          {sortOptions.map(({ name }) => (
            <FormDropdownOption key={name} value={name} optionLabel={name} />
          ))}
        </FormDropdown>
      </Container>
    );
  }
}

export default SortDropdown;
