import { login } from 'base-client';

import analyticsActions from '../../Analytics/actions';
import accountActions from '../../UserProfile/actions';

const userLogin = (username, password) => async dispatch => {
  const visitorId = dispatch(analyticsActions.getVisitorId());
  const error = await dispatch(
    login.actions.userLogin(username, password, { visitor_id: visitorId })
  );

  if (!error) dispatch(accountActions.fillAccount());

  return error;
};

const googleLogin = () => dispatch => {
  const visitorId = dispatch(analyticsActions.getVisitorId());
  return dispatch(login.actions.googleLogin({ visitor_id: visitorId }));
};

const linkedInLogin = () => dispatch => {
  const visitorId = dispatch(analyticsActions.getVisitorId());
  return dispatch(login.actions.linkedInLogin({ visitor_id: visitorId }));
};

const logOut = () => dispatch => {
  // clear data
  dispatch(accountActions.clearAccount());

  // log out
  dispatch(login.actions.logOut());
};

export default {
  userLogin,
  googleLogin,
  linkedInLogin,
  logOut
};
