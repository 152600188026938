import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import { history, reducerUtil, login } from 'base-client';

import { Row, Column } from 'shared-features-client';

import projectsActions from 'components/Projects/actions';
import detailsActions from './actions';
import detailsData from './reducerData';

import ErrorProjectLogin from 'components/Errors/components/ErrorProjectLogin';
import ProjectTools from './components/ProjectTools';
import SavedProducts from './components/SavedProducts';
import ProjectHeader from './components/ProjectHeader';
import ProjectDetailsTabCard from './components/ProjectTabComponent';

const ProjectDetails = ({ token, generalData, match, dispatch }) => {
  const id = match && match.params && match.params.id;

  if (!id) {
    history.push('/projects');
    return null;
  }

  if (!token) {
    return (
      <ErrorProjectLogin>
        Please <Link to="/login">log in</Link> to view this project
      </ErrorProjectLogin>
    );
  }

  useEffect(() => {
    dispatch(detailsActions.getProject(id));
    return () => {
      dispatch(detailsActions.clearProjectsDetailsData());
    };
  }, [id]);

  if (!generalData || generalData.id !== id) return null;

  const { name, created_at: createdDate } = generalData || {};

  const projectToolsProps = {
    id,
    name,
    editProject: () => dispatch(detailsActions.goToProjectEdit(id)),
    deleteProject: () => dispatch(projectsActions.deleteProject(id))
  };

  return (
    <React.Fragment>
      <ProjectHeader {...{ name, createdDate }} />
      <Row>
        <Column mdUp={9} className="leftContent">
          <ProjectDetailsTabCard {...generalData} />
          <SavedProducts projectId={id} projectName={name} />
        </Column>
        <Column mdUp={3} className="rightContent">
          <ProjectTools {...projectToolsProps} />
        </Column>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  generalData: reducerUtil.getSlice(detailsData, detailsData.generalData, state),
  token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state)
});

export default withRouter(connect(mapStateToProps)(ProjectDetails));
