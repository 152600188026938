const sortList = [
  { name: 'Most Recent', sortBy: 'created_at', sortDir: 'desc' },
  {
    name: 'Alphabetical (A to Z)',
    sortBy: 'name',
    sortDir: 'asc'
  },
  {
    name: 'Alphabetical (Z to A)',
    sortBy: 'name',
    sortDir: 'desc'
  }
];

export { sortList };
