import React from 'react';
import styled from 'styled-components';

import { Row, Column } from 'shared-features-client';

import ContactEdit from './ContactEdit';

const VendorBlockEdit = styled(Row)`
  && {
    font-size: 1.4rem;
    margin-bottom: 3rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const TabContactInformationEdit = ({
  architect,
  contractor,
  general_contractor,
  distributor,
  ...props
}) => {
  return (
    <div {...props}>
      <VendorBlockEdit>
        <Column span={6}>
          <strong>Contractor Information</strong>
          <ContactEdit {...(contractor || {})} entity="contractor" />
        </Column>
        <Column span={6}>
          <strong>Distributor Information</strong>
          <ContactEdit {...(distributor || {})} entity="distributor" />
        </Column>
      </VendorBlockEdit>
      <VendorBlockEdit>
        <Column span={6}>
          <strong>Architect Information</strong>
          <ContactEdit {...(architect || {})} entity="architect" />
        </Column>
        <Column span={6}>
          <strong>GC Information</strong>
          <ContactEdit {...(general_contractor || {})} entity="general_contractor" />
        </Column>
      </VendorBlockEdit>
    </div>
  );
};

export default TabContactInformationEdit;
