import React from 'react';
import styled from 'styled-components';

import media from '../../utils/media';
import DisplayLink from '../../shared/DisplayLink';

const Container = styled(DisplayLink)`
  display: flex;
  flex-direction: column;
  margin: 0 2% 2rem;
  background-color: #fff;
  padding: 1.5rem 2rem;
  border-radius: 3px;
  box-shadow: 0 3px 5px rgba(52, 62, 71, 0.12);
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 4px;
    background-color: ${props =>
      props.theme.accentColor ? props.theme.accentColor : props.theme.blue};
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  ${media.small`
    flex: 0 0 23%;
    min-width: 22rem;
  `};
`;

const Title = styled.h4`
  margin: 0;
  font-size: 1.8rem;
`;

const Description = styled.p`
  font-size: 1.4rem;
`;

const Footer = styled.div`
  text-align: right;
  margin-top: auto;
  color: ${props => props.theme.primaryColor};
  font-size: 1.4rem;
  font-weight: 600;
  &:hover {
    color: ${props => props.theme.blue};
  }
`;

const ArrowIcon = styled.i`
  position: relative;
  display: inline-block;
  font-size: 12px;
  width: 0.8rem;
  right: 0.8rem;
  height: 0.8rem;
  border-top: 2px solid
    ${props => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
  border-right: 2px solid
    ${props => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
  transform: rotate(45deg);
  &:after {
    content: '';
    position: absolute;
    border-top: 2px solid
      ${props => (props.theme.primaryColor ? props.theme.primaryColor : props.theme.blue)};
    top: 2px;
    right: -7px;
    width: 14px;
    height: 14px;
    height: 14px;
    transform: rotate(-45deg);
  }
`;

const NavigationCard = ({ header, description, urlDisplayValue, url }) => (
  <Container url={url}>
    <Title>{header}</Title>
    <Description>{description}</Description>
    <Footer>
      <ArrowIcon />
      {urlDisplayValue}
    </Footer>
  </Container>
);

export default NavigationCard;
