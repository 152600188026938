import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { reducerUtil } from 'base-client';
import Modal from 'shared/Modal';
import projectActions from 'components/Projects/actions';
import projectData from 'components/Projects/reducerData';
import AddProductsToProject from './AddProductsToProject';
import CreateNewProject from './CreateNewProject';

const ModalContent = styled.div`
  min-width: 50rem;
  font-size: 1.4rem;
`;

const NoType = styled.div`
  min-width: 50rem;
  &.add-products {
    min-height: 16.2rem;
  }
  &.create-project {
    min-height: 13.2rem;
  }
`;

const FormGroup = styled.div`
  width: 100%;
  padding: 1.5rem 0 2rem;
  &:first-child {
    padding-top: 0;
  }
  &.options {
    label {
      &:first-child {
        margin-right: 2rem;
      }
    }
  }
  &.projectInfor {
    padding-bottom: 0;
    display: flex;
  }
  .projectList {
    display: inline-block;
    width: 80%;
  }
`;

const Label = styled.label`
  cursor: pointer;
  font-weight: 500;
  input {
    margin-right: 0.5rem;
  }
  &.required {
    position: relative;
    &:after {
      position: absolute;
      content: '*';
      top: 0;
      right: -1rem;
      color: ${props => props.theme.red};
    }
  }
`;

const CreateProjectModal = ({ isOpen, products, dispatch }) => {
  const [type, setType] = useState(2);
  const [projects, setProjects] = useState();

  const hasProducts = products && products.length > 0;

  useEffect(() => {
    const getProjects = async () => {
      const result = await dispatch(projectActions.getAllProjects());
      if (result && result.length > 0) {
        const list = result
          .map(({ id, name }) => ({ id, name }))
          .sort(({ name: a }, { name: b }) => a.toLowerCase().localeCompare(b.toLowerCase()));

        setProjects(list);
        setType(0);
      } else {
        setType(1);
      }
    };

    if (hasProducts) {
      getProjects();
    } else {
      setType(1);
    }
  }, [isOpen]);

  const toggleProjectType = evt => {
    const { value } = evt.target;
    setType(parseInt(value));
  };

  const modalProps = {
    title: hasProducts && projects && projects.length > 0 ? 'Select Project' : 'Create Project',
    open: isOpen,
    onClose: () => dispatch(projectActions.closeModal())
  };

  const handleAddProducts = selectedProject =>
    dispatch(projectActions.addProducts(selectedProject));

  const handleCreateProject = async ({ name, zipCode }) => {
    const result = await dispatch(projectActions.createProject({ name, zipCode }));
    return result;
  };

  return (
    <Modal {...modalProps}>
      {type === 2 ? (
        <NoType className={hasProducts ? 'add-products' : 'create-project'} />
      ) : (
        <ModalContent>
          {hasProducts && projects && projects.length > 0 && (
            <FormGroup className="options">
              <Label htmlFor="useExistProject">
                <input
                  type="radio"
                  id="useExistProject"
                  name="Use Existing Project"
                  value={0}
                  checked={type === 0}
                  onClick={e => toggleProjectType(e)}
                  readOnly
                />
                Use Existing Project
              </Label>
              <Label htmlFor="startNewProject">
                <input
                  type="radio"
                  id="startNewProject"
                  name="Start New Project"
                  value={1}
                  checked={type === 1}
                  readOnly
                  onClick={e => toggleProjectType(e)}
                />
                Start New Project
              </Label>
            </FormGroup>
          )}
          {type === 0 && <AddProductsToProject {...{ projects, handleAddProducts }} />}
          {type === 1 && <CreateNewProject {...{ hasProducts, handleCreateProject }} />}
        </ModalContent>
      )}
    </Modal>
  );
};

const mapStateToProps = state => ({
  isOpen: reducerUtil.getSlice(projectData, projectData.modalOpen, state),
  products: reducerUtil.getSlice(projectData, projectData.products, state)
});

export default connect(mapStateToProps)(CreateProjectModal);
