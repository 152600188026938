import React from 'react';
import Dashboard from '../components/Dashboard';
import ManufacturerSearch from '../components/ManufacturerSearch';

const Manufacturers = () => {
  return (
    <>
      <ManufacturerSearch />
    </>
  );
};

export default Manufacturers;
