import emailjs from 'emailjs-com';
const publicIp = require('public-ip');

export const submitDataDeletionRequest = data => async (dispatch, getState) => {
  const ip = await publicIp.v4();
  data.ip = ip;

  try {
    dispatch(emailjs.send('gmail', 'template_6Lb0u5tr', data, 'user_o43Gb2XQogqdCKXSncO7o')).then(
      response => {
        console.log('SUCCESS!', response.status, response.text);
      }
    );
  } catch (e) {
    return false;
  }
  return true;
};
