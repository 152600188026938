import React from 'react';
import validator from 'validator';

const ExternalLink = ({ children, href, ...props }) => {
  href = validator.isURL(href) ? href : `//${href}`;
  return (
    <a target="_blank" rel="noopener noreferrer" {...{ href }} {...props}>
      {children}
    </a>
  );
};

export default ExternalLink;
