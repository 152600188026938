import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { reducerUtil, history } from 'base-client';
import actions from './actions';
import reducerData from './reducerData';
import Product from './components/Product';
import ProductInformation from './components/ProductInformation';
import BimData from './components/BimData';

const StyledProduct = styled(Product)`
  display: inline-block;
  vertical-align: top;
  width: ${props => 100 / props.cols + '%'};
`;

class ProductComparer extends React.Component {
  constructor(props) {
    super(props);

    const {
      location: { search: queryJson }
    } = history;

    if (!queryJson) {
      history.push('/products');
    }

    let indexOfParams = queryJson.indexOf('=') + 1;
    const ids = queryJson.slice(indexOfParams).split('&');

    if (ids.length < 2) {
      history.push('/products');
    }

    props.dispatch(actions.getProducts(ids));
  }

  state = {};

  componentWillUnmount() {
    this.props.dispatch(reducerUtil.setSlice(reducerData, reducerData.products, undefined));
  }

  handleChange = event => {
    this.setState({ familyType: event.target.value });
  };

  render() {
    const { products } = this.props;

    if (!products) return null;

    return (
      <React.Fragment>
        <h3>Compare Products</h3>
        {[...[{}], ...products].map(p => (
          <StyledProduct {...p} cols={products.length + 1} className="product-header" />
        ))}
        {products && products.length > 0 && (
          <ProductInformation
            className="products-information"
            headerText="Product Information"
            {...{ products }}
          />
        )}
        {products && products.length && <BimData className="products-bimdata" {...this.props} />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  products: reducerUtil.getSlice(reducerData, reducerData.products, state)
});

export default connect(mapStateToProps)(ProductComparer);
