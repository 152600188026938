import React from 'react';
import styled from 'styled-components';

import FormLabel from './FormLabel';
import Input from './Input';
import ReduxFormError from './ReduxFormError';

const Container = styled.div`
  margin-bottom: 1.5rem;
  text-align: left;
  margin-top: ${props => (props.labelTitle ? null : '-1rem')};
`;

const ReduxFormInput = ({ type, required, labelTitle, input, placeholder, meta, className }) => (
  <Container {...{ labelTitle, className }}>
    {labelTitle && <FormLabel {...{ required, labelTitle }} />}
    <Input {...input} placeholder={placeholder} type={type} />
    <ReduxFormError {...{ meta }} />
  </Container>
);

ReduxFormInput.defaultProps = {
  type: 'text'
};

export default ReduxFormInput;
