import React from 'react';
import styled from 'styled-components';

import Card from 'shared/Card';

const Header = styled.div`
  border-bottom: 1px solid ${props => props.theme.grey2};
`;

const Title = styled.h2`
  font-size: 2rem;
  margin: 0;
  color: ${props => props.theme.grey5};
`;

const Description = styled.p`
  font-size: 1.4rem;
`;

const Body = styled.div`
  margin-top: 2rem;
  button {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const RightBlock = ({ title, description, children }) => (
  <Card padding="2rem">
    <Header>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </Header>
    <Body>{children}</Body>
  </Card>
);

export default RightBlock;
