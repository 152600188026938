/* Global Styles */
// https://www.styled-components.com/docs/api#injectglobal

const defaultFont = '"IBM Plex Sans", Helvetica Neue, Arial, sans-serif';
const getGlobals = font => `
@import url('${
  font ? font.url : 'https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,700'
}');

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
  }

  body {
    font-family: ${font ? font.family : defaultFont};
    margin: 0;
    color: #343E47;
    background: #F6F7F7;
    font-size: 1.6rem;
    height: 100%;
    &.ReactModal__Body--open{
      overflow: hidden; //disable scrolling when opens modal
    }
    .notification{
      right: 15px;
      top: 90px;
      font-size: 14px;
      z-index: 9999;
      @media(min-width: 1280px) {
        right: calc((100% - 1250px)/2);
      }
      .notification-card-title {
        margin-top: 10px;
      }
      .notification-card-footer{
        margin-bottom: 0;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  p {
    line-height: 1.5;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button{
    &:focus{
      outline: none;
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #77899E;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #77899E;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #77899E;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #77899E;
  }

  .dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    }

  .not-active {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    opacity: 0.5;
  }

  #root{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  .clamp-lines{
    word-break: break-word;
  }

  .ReactVirtualized__Grid, .ReactVirtualized__List, .ReactVirtualized__Masonry{
    outline: none;
  }
  .ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 999;
  }
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    &--after-open {
      opacity: 1;
      z-index: 999;
    }
    &--before-close {
      opacity: 0;
    }
  }
  .pad-15 {
    padding: 15px;
  }
  .pad-right-15 {
    padding-right: 15px;
  }
  .mgn-right-15 {
    margin-right: 15px;
  }
  .mgn-right-25 {
    margin-right: 25px;
  }
  .collapse {
    visibility: collapse;
  }
  .display-none {
    display: none!important;
  }
  .display-inline-block {
    display: inline-block!important;
  }
`;

let sbBranding = {
  grey0: '#F0F3F6',
  grey1: '#DEE5ED',
  grey2: '#C3CDD9',
  grey3: '#77899E',
  grey4: '#5A6678',
  grey5: '#343E47',
  grey6: '#212529',
  grey7: '#F0F3F6',

  blueLight: 'hsl(209, 92%, 61%)',
  blue: '#0A7BE4',
  blueDark: '#096ECC',
  blueDarkest: '#055DAF',
  red: '#E63E1F',
  redDark: '#CF2C42',
  green: '#6ABF43',
  purple: '#7D13F0',
  purple1: '#6175DE',
  purple2: '#5257CD',
  purple3: '#D5DAF5',
  white: '#FFFFFF',
  black: '#000000',
  lightBlack: '#1C1C1C',

  fontBody: defaultFont,
  backgroundColor: '#273b51'
};

const sizes = {
  smEnd: '678px',
  mdStart: '679px',
  mdEnd: '992px',
  lgStart: '993px'
};

const Screen = {
  sm: `(max-width: ${sizes.smEnd})`,
  md: `(min-width: ${sizes.mdStart}) and (max-width: ${sizes.mdEnd})`,
  mdUp: `(min-width: ${sizes.mdStart})`,
  lg: `(min-width: ${sizes.lgStart})`
};

// default branding style here. These values are expected to be overwritten
sbBranding.accentColor = sbBranding.blue;
sbBranding.linkColor = sbBranding.blue;

export { sbBranding, getGlobals, Screen, defaultFont };
