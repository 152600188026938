import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
`;

const FormGroup = ({ children }) => <Container>{children}</Container>;

export default FormGroup;
