import React from 'react';
import styled from 'styled-components';

import ActionsBar from './Filtering/ActionsBar';
import ActionBanner from './ActionBanner';
import PageHeader from '../../shared/PageHeader.js';
import Filters from './Filters';

const FiltersResults = styled.div`
  display: flex;
  position: relative;
`;

const Results = styled.div`
  padding-left: 2rem;
  flex: 0 0 80%;
  position: relative;
`;

const BannerWrapper = styled.div`
  transition: all 0.1s ease-in;
  position: relative;
  &.banner-sticky {
    position: sticky;
    left: 0;
    top: 60px;
    right: 0;
    z-index: 100;
  }
`;

const ProductSearch = ({ children, filterProps, selectedProducts, ...bannerProps }) => {
  return (
    <React.Fragment>
      <PageHeader title="All Products" />
      <ActionsBar />
      <FiltersResults>
        <Filters {...filterProps} />
        <Results>
          <BannerWrapper className="banner">
            {selectedProducts && selectedProducts.length > 0 ? (
              <ActionBanner {...{ selectedProducts, ...bannerProps }} />
            ) : null}
          </BannerWrapper>
          {children}
        </Results>
      </FiltersResults>
    </React.Fragment>
  );
};

export default ProductSearch;
