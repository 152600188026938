import React from 'react';
import styled from 'styled-components';

import Container from '../Layout/Container';
import { Screen } from '../../global-styles';
import MapImg from './map.svg';
import Check from './styledCheck.svg';
import AnimatedNumber from 'react-animated-number';

const Wrapper = styled.div`
  padding: 4rem 0;
  text-align: center;
  height: 650px;
  position: relative;
  @media ${Screen.mdUp} {
    padding: 5rem 0 6rem;
  }
`;

const Map = styled.div`
  position: absolute;
  background: url(${MapImg}) no-repeat left center;
  width: 100%;
  height: 650px;
  top: 0;
  z-index: -1;
  @media ${Screen.mdUp} {
    background-position: center;
  }
`;

// need to figure out positioning this responsively + accurately >_<
const Ping = styled.div`
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 9px;
  background: hsl(279, 85%, 77%);
  top: 100px; // this needs to be responsive
  left: 100px; // this needs to be responsive
  &:before,
  &:after {
    content: '';
    border-radius: 15px;
    position: absolute;
  }
  &:before {
    width: 13px;
    height: 13px;
    left: -4px;
    top: -4px;
    border: 2px solid hsl(279, 85%, 83%);
  }
  &:after {
    width: 21px;
    height: 21px;
    left: -7px;
    top: -7px;
    border: 1px solid hsl(279, 85%, 87%);
  }
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 1px 1px 30px rgba(45, 87, 147, 0.13), 0px 1px 4px rgba(13, 87, 191, 0.15);
  border-radius: 3px;
  width: 100%;
  max-width: 40rem;
  margin: 6rem auto 0;
  padding: 2rem;
`;

const CardTitle = styled.h2`
  color: ${props => props.theme.blueDark};
  font-size: 2.2rem;
  font-weight: 600;
  position: relative;
  margin: 0 0 1rem 3rem;
  &:before {
    content: '';
    background: url(${Check});
    width: 3.5rem;
    height: 3.2rem;
    position: absolute;
    left: 4px;
  }
  @media ${Screen.mdUp} {
    &:before {
      left: 4px;
    }
  }
`;

const CardText = styled.p`
  color: ${props => props.theme.blue};
  font-size: 1.6rem;
  margin: 0;
`;

const Stats = styled.div`
  @media ${Screen.mdUp} {
    display: flex;
    justify-content: space-around;
  }
`;

const StatGroup = styled.div`
  margin: 4rem 2rem 0;
  text-align: center;
`;

const StatTitle = styled.h3`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: 0.2px;
  font-weight: 600;
  text-align: center;
  color: ${props => props.theme.blueDark};
`;

const DigitsContainers = styled.div`
  display: flex;
  flex-direction: row;
`;

const Digits = styled.div`
  text-align: center;
  color: ${props => (props.comma ? props.theme.blueLight : '#ffffff')};
  font-size: 3.5rem;
  background: ${props => (props.comma ? '' : props.theme.blueLight)};
  border-radius: 0.5rem;
  margin-left: ${props => (props.comma ? '-0.6rem' : '0.4rem')};
  margin-right: ${props => (props.comma ? '-0.6rem' : '0.4rem')};
  padding: 0.5rem 0.7rem;
  font-weight: 600;
  position: relative;
  ${props =>
    props.comma
      ? ''
      : `&:before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 1px;
    background: #ffffff;
    content: '';
    width: 100%;
    display: block;`}
  }
`;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const MapStats = () => {
  const startDate = new Date('2020-01-29T21:48:33.460Z');
  const minutesSince = Math.round((new Date() - startDate) / 1000 / 60);
  const starViews = 67897260;
  const startUsers = 44796;
  const technicalDocuments = 352105;
  const viewAvg = Math.round(24254440 / 2 / 365 / 24 / 60);
  const usersAvg = Math.round(startUsers / 2 / 365 / 24 / 60);
  const technicalDocumentsAvg = Math.round(technicalDocuments / 2 / 365 / 24 / 60);
  return (
    <Wrapper>
      <Map>{/* <Ping /> */}</Map>
      <Container>
        <Card>
          <CardTitle>Community That Matters</CardTitle>
          <CardText>
            Trusted manufacturer catalogs. Architects, Engineers & Contractors like you rely on
            Concora to source their technical product content.
          </CardText>
        </Card>
        <Stats>
          <StatGroup>
            <StatTitle>Professional Users</StatTitle>
            <DigitsContainers>
              {numberWithCommas(startUsers + minutesSince * usersAvg)
                .toString()
                .split('')
                .map(char => {
                  return <Digits comma={char === ','}>{char}</Digits>;
                })}
            </DigitsContainers>
          </StatGroup>
          <StatGroup>
            <StatTitle>Products Viewed</StatTitle>
            <DigitsContainers>
              {numberWithCommas(starViews + minutesSince * viewAvg)
                .toString()
                .split('')
                .map(char => {
                  return <Digits comma={char === ','}>{char}</Digits>;
                })}
            </DigitsContainers>
          </StatGroup>
          <StatGroup>
            <StatTitle>Technical Documents</StatTitle>
            <DigitsContainers>
              {numberWithCommas(technicalDocuments + minutesSince * technicalDocumentsAvg)
                .toString()
                .split('')
                .map(char => {
                  return <Digits comma={char === ','}>{char}</Digits>;
                })}
            </DigitsContainers>
          </StatGroup>
        </Stats>
      </Container>
    </Wrapper>
  );
};

export default MapStats;
