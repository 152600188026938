import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DisplayLink from '../../shared/DisplayLink';
import moment from 'moment';

import media from '../../utils/media';
import logo from '../../static/logo-reverse.svg';

const defaultColumnNames = [
  'Design Tools',
  'Company Information',
  'Legal Information',
  'Contact Us'
];

const FooterWrapper = styled.div`
  font-size: 1.4rem;
  position: relative;
  font-size: 1.4rem;
  padding: 3rem 0;
  margin-top: 3rem;
  background-color: ${props => props.theme.backgroundColor || '#ffffff'};
  clip-path: polygon(0 10%, 100% 7%, 100% 100%, 0 100%);
  background-size: cover;
  color: ${props => props.theme.textColor || props.theme.grey1};
  ${media.medium`
    clip-path: polygon(0 10%, 100% 4%, 100% 100%, 0 100%);
  `} ${media.large`
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
  `};
`;

const Container = styled.div`
  padding: 3rem 1.5rem 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 128rem;
  margin: 0 auto;
`;

const Colums = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 5rem;
  ${media.small`
    margin-top: 3rem;
  `} ${media.medium`
    width: 75%;
    margin-top: 0;
  `};
`;

const Column = styled.div`
  flex: 0 0 100%;
  margin-bottom: 2rem;
  ${media.small`
    flex: 0 0 48%;
    margin: 0 1% 2rem;
  `} ${media.large`
    flex: 0 0 23.5%;
    margin: 0 1.5% 0 0;
  `};
`;

const ColumnName = styled.h4`
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 0;
`;

const Link = styled(DisplayLink)`
  padding: 5px 0;
  display: block;
  transition: color 0.5s ease;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.textHoverColor || 'inherit'};
  }
`;

const BrandInfo = styled.div`
  ${media.medium`
    width: 25%;
  `};
`;

const Image = styled.img`
  max-width: 12rem;
`;

const Copyright = styled.p`
  margin-top: 0;
  font-size: 1.2rem;
`;

const footerLinks = [
  {
    links: [
      { url: '/products', displayValue: 'Product Information' },
      {
        url:
          'https://www.figma.com/proto/PSMX4ncSabjtzALhQZRBdcl3/Design-Tools-Speculative?node-id=3240%3A3234&viewport=427%2C110%2C0.108038&scaling=min-zoom',
        displayValue: 'Project Submittal'
      },
      { url: 'https://ecoscorecard.com/', displayValue: 'ecoScorecard' }
    ],
    columnTitle: 'Design Tools'
  },
  {
    links: [
      { url: 'https://concora.com/', displayValue: 'Company Overview' },
      { url: 'https://concora.com/careers', displayValue: 'Careers' }
    ],
    columnTitle: 'Company Information'
  },
  {
    links: [
      {
        url: 'https://concora.com/privacy-policy',
        displayValue: 'Privacy Policy'
      },
      {
        url: 'https://concora.com/terms-of-use',
        displayValue: 'Terms & Conditions'
      },
      {
        url: '/gdpr-ccpa',
        displayValue: 'Delete My Personal Data'
      }
    ],
    columnTitle: 'Legal Information'
  },
  {
    links: [
      { url: 'https://concora.com/contact', displayValue: 'Web Form' },
      { url: 'https://www.linkedin.com/company/smartbimllc/', displayValue: 'LinkedIn' },
      { url: 'https://twitter.com/Concora_', displayValue: 'Twitter' },
      { url: 'https://www.facebook.com/ConcoraLLC', displayValue: 'Facebook' },
      {
        url: 'https://www.youtube.com/channel/UCbotbRb-Ujum5Wj1u2g7Qmw',
        displayValue: 'YouTube'
      }
    ],
    columnTitle: 'Contact Us'
  }
];

const copyrightName = 'Concora';
const name = 'Concora Library';

const FooterContainer = () => {
  const columns = footerLinks.map(({ columnTitle, links }, columnIndex) => {
    const rows = links.map(({ displayValue, url }, rowIndex) => {
      if (columnIndex === 0 && rowIndex === 0) {
        return (
          <Link url={url || '/products'} key={rowIndex}>
            {displayValue || 'Product Information'}
          </Link>
        );
      }
      return <Link {...{ url: url || '' }}>{displayValue}</Link>;
    });

    return (
      <Column key={columnIndex}>
        <ColumnName>{columnTitle || defaultColumnNames[columnIndex]}</ColumnName>
        {rows}
      </Column>
    );
  });

  return (
    <footer>
      <FooterWrapper>
        <Container>
          <Colums>{columns}</Colums>
          <BrandInfo>
            <Image src={logo} alt={copyrightName || name} />
            <Copyright>&copy;{` ${moment().year()} ${copyrightName || name}`}</Copyright>
          </BrandInfo>
        </Container>
      </FooterWrapper>
    </footer>
  );
};

export default connect()(FooterContainer);
