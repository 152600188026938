import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { reduxForm } from 'redux-form';

import { history, reducerUtil, login } from 'base-client';

import Card from 'shared/Card';
import StyledTabs from 'shared/StyledTabs';
import { Row, Column } from 'shared-features-client';

import detailsData from 'components/ProjectDetails/reducerData';
import detailsActions from 'components/ProjectDetails/actions';
import editActions from 'components/ProjectEdit/actions';
import ProjectHeader from 'components/ProjectDetails/components/ProjectHeader';
import ErrorProjectLogin from 'components/Errors/components/ErrorProjectLogin';
import TabProjectEdit from './components/TabProjectEdit';
import TabAdditionalInfomationEdit from './components/TabAdditionalInfomationEdit';
import TabContactInformationEdit from './components/TabContactInformationEdit';
import SideDetails from './components/SideDetails';
import ProjectCompletionStatus from './components/ProjectCompletionStatus';

const TabContent = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 2rem;
  width: 100%;
`;

const ProjectEditContainer = ({
  token,
  generalData,
  match,
  dispatch,
  saveChanges,
  handleSubmit,
  reset,
  pristine,
  submitting,
  hideHeader,
  invalid,
  error,
  valid
}) => {
  const id = match && match.params && match.params.id;
  if (!id) {
    history.push('/projects');
    return null;
  }

  if (!token) {
    return (
      <ErrorProjectLogin>
        Please <Link to="/login">log in</Link> to view this project
      </ErrorProjectLogin>
    );
  }

  useEffect(() => {
    dispatch(detailsActions.getProject(id));
    return () => {
      dispatch(detailsActions.clearProjectsDetailsData());
    };
  }, [id]);

  if (!generalData) return null;

  const onSubmit = () => {
    dispatch(editActions.updateProject());
  };

  const { name, created_date: createdDate } = generalData || {};
  return (
    <form onSubmit={handleSubmit}>
      {!hideHeader && <ProjectHeader {...{ name, createdDate, isEdit: true, id }} />}
      <Row>
        <Column mdUp={4}>
          <SideDetails
            title="Edit Project"
            description="Project details appear on the final Submittal Prep and are mostly optional.
            We recommend filling them all out to provide a comprehensive submittal package."
          >
            <ProjectCompletionStatus
              invalid={invalid || !valid}
              error={error}
              {...{
                generalData,
                saveChanges,
                pristine,
                submitting,
                reset,
                onClick: onSubmit
              }}
            />
          </SideDetails>
        </Column>
        <Column mdUp={8}>
          <Card>
            <StyledTabs>
              <Tabs forceRenderTabPanel={true}>
                <TabList>
                  <Tab key={1}>Project Details</Tab>
                  <Tab key={2}>Additional Information</Tab>
                  <Tab key={3}>Contact Information</Tab>
                </TabList>
                <TabContent className="tab-content">
                  <TabPanel key={1}>
                    <TabProjectEdit {...generalData} />
                  </TabPanel>
                  <TabPanel key={2}>
                    <TabAdditionalInfomationEdit {...generalData} />
                  </TabPanel>
                  <TabPanel key={3}>
                    <TabContactInformationEdit {...generalData} />
                  </TabPanel>
                </TabContent>
              </Tabs>
            </StyledTabs>
          </Card>
        </Column>
      </Row>
    </form>
  );
};

const mapStateToProps = state => ({
  generalData: reducerUtil.getSlice(detailsData, detailsData.generalData, state),
  products: reducerUtil.getSlice(detailsData, detailsData.products, state),
  token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
  initialValues: reducerUtil.getSlice(detailsData, detailsData.generalData, state)
});

const asyncValidate = async (values, dispatch) => {
  const { exists } = await dispatch(editActions.isUnique(values['name']));
  if (exists) {
    throw { name: 'Name already exists' };
  }
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: detailsData.name,
      asyncValidate,
      asyncChangeFields: ['name'],
      enableReinitialize: true,
      keepDirtyOnReinitialize: false
    })(ProjectEditContainer)
  )
);
