import React from 'react';
import styled from 'styled-components';

import FormLabel from 'shared/FormLabel';
import Input from 'shared/Input';
import ReduxFormError from 'shared/ReduxFormError';
import { addComma, removeComma } from 'utils/numbers';

const Container = styled.div`
  margin-bottom: 1.5rem;
  text-align: left;
  margin-top: ${props => (props.labelTitle ? null : '-1rem')};
`;

const Group = styled.span`
  display: flex;
`;

const GroupInput = styled(Input)`
  border-radius: 2px 0 0 2px;
`;

const PrefixSuffix = styled.span`
  color: ${props => props.theme.grey3};
  font-size: 1.4rem;
  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.grey1};
  border: 1px solid ${props => props.theme.grey2};
  white-space: nowrap;
`;

const Prefix = styled(PrefixSuffix)`
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  margin-right: -1px;
  & + ${GroupInput} {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

const Suffix = styled(PrefixSuffix)`
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-left: -1px;
`;

const NumberCommaInput = ({
  required,
  labelTitle,
  input,
  meta,
  className,
  prefix,
  suffix,
  ...props
}) => {
  const { value: formValue, onChange: formChange, ...rest } = input;

  const onChange = event => {
    if (event.target.value === '') formChange(null);
    else if (event.target.value.match(/[\d,]*/g)) formChange(removeComma(event.target.value));
    else formChange(formValue);
  };

  const value = addComma(formValue) || '';
  return (
    <Container {...{ labelTitle, className }}>
      {labelTitle && <FormLabel {...{ required, labelTitle }} />}
      <Group>
        {prefix && <Prefix>{prefix}</Prefix>}
        <GroupInput {...{ value, onChange }} {...rest} onBlur={onChange} {...props} />
        {suffix && <Suffix>{suffix}</Suffix>}
      </Group>
      <ReduxFormError {...{ meta }} />
    </Container>
  );
};

NumberCommaInput.defaultProps = {
  type: 'text'
};

export default NumberCommaInput;
