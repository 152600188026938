import React, { Component } from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import debounce from 'lodash/debounce';
import { login } from 'base-client';

import SignUp from './SignUp';
import loginActions from './actions';

class SignUpContainer extends Component {
  state = {
    isSignUpReady: false,
    isUserAgreementChecked: false,
    errors: {},
    form: {}
  };

  onUserAgreementCheck = checked => {
    this.setState({ isUserAgreementChecked: checked }, () => this.checkSignUpReady());
  };

  updateForm = (value, field) => {
    let form = Object.assign({}, this.state.form);
    form[field] = value;
    this.setState({
      form: form
    });

    this.checkValidation(value, field);
  };

  setErrorState = (field, message) => {
    let error = Object.assign({}, this.state.errors);
    error[field] = {
      hasError: true,
      message: message
    };

    this.setState({
      errors: error
    });
  };

  clearErrorState = field => {
    let errors = Object.assign({}, this.state.errors);
    errors[field] = {
      hasError: false
    };

    this.setState({
      errors: errors
    });
  };

  checkValidation = debounce((value, field) => this.validate(value, field), 500);

  validate = (value, field) => {
    switch (field) {
      case 'email':
        if (!value) {
          this.setErrorState(field, 'Please fill in your email');
          break;
        }

        if (!validator.isEmail(value)) {
          this.setErrorState(field, 'Please use a valid email address, i.e.: myaddress@mydomain');
          break;
        }

        this.clearErrorState(field);
        break;

      case 'password': {
        if (!value) {
          this.setErrorState(field, 'Please fill in your password');
          break;
        }

        if (value.length < 8) {
          this.setErrorState(field, 'Your password must have at least 8 letters in length');
          break;
        }

        let errorMessages = [];

        if (!/[a-z]/.test(value)) {
          errorMessages.push('Your password must have at least one lowercase letter');
        }

        if (!/[A-Z]/.test(value)) {
          errorMessages.push('Your password must have at least one uppercase letter');
        }

        if (!/\d/.test(value)) {
          errorMessages.push('Your password must contain a number (i.e. 0-9)');
        }

        if (!/[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g.test(value)) {
          errorMessages.push('Your password must contain a special character (e.g !@#$%^&*)');
        }

        if (errorMessages.length < 2) {
          this.clearErrorState(field);
        } else {
          this.setErrorState(
            field,
            `Your password must fulfill ${4 - (4 - errorMessages.length) - 1} out of ${4 -
              (4 - errorMessages.length)} the remaining requirements:\n ${errorMessages.join('\n')}`
          );
        }

        break;
      }

      case 'reEnterPassword':
        if (!value) {
          this.setErrorState(field, 'Please re-enter your password');
          break;
        }

        if (value !== this.state.form.password) {
          this.setErrorState(field, 'Your passwords do not match');
          break;
        }

        this.clearErrorState(field);
        break;

      default:
    }
    this.checkSignUpReady();
  };

  checkSignUpReady = () => {
    if (!this.state.form.email || !this.state.form.password || !this.state.form.reEnterPassword) {
      this.setState(prevState => ({ ...prevState, isSignUpReady: false }));
      this.forceUpdate();
      return;
    }

    for (let key in this.state.errors) {
      if (this.state.errors[key].hasError) {
        this.setState(prevState => ({ ...prevState, isSignUpReady: false }));
        this.forceUpdate();
        return;
      }
    }

    this.setState(prevState => ({ ...prevState, isSignUpReady: true }));
    this.forceUpdate();
  };

  onSignup = async () => {
    const { dispatch } = this.props;
    const { form } = this.state;
    const { email, password } = form || {};

    const error = await dispatch(login.actions.userSignUp(email, password));
    if (!error) {
      this.setState(prevState => ({
        ...prevState,
        showSuccess: true,
        showError: false,
        message: 'Please check your email to confirm'
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        showSuccess: false,
        showError: true,
        message: error.description
      }));
    }
  };

  render() {
    const { errors, isSignUpReady, showSuccess, showError, message } = this.state;
    const { dispatch, ...props } = this.props;

    return (
      <SignUp
        {...props}
        headerText="Sign Up"
        errors={errors}
        isSignUpReady={isSignUpReady}
        validate={(event, field) => this.validate(event.target.value, field)}
        updateForm={(event, field) => this.updateForm(event.target.value, field)}
        onUserAgreementCheck={checked => this.onUserAgreementCheck(checked)}
        googleLogin={() => dispatch(loginActions.googleLogin())}
        linkedInLogin={() => dispatch(loginActions.linkedInLogin())}
        onSignup={this.onSignup}
        showSuccess={showSuccess}
        showError={showError}
        message={message}
      />
    );
  }
}

export default connect()(SignUpContainer);
