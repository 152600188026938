import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { Field } from 'redux-form';
import styled from 'styled-components';

import { history } from 'base-client';

import { required } from 'utils/reduxFormValidator';
import PageHeader from 'shared/PageHeader';
import ReduxFormInput from 'shared/ReduxFormInput';

const StyledProjectHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 1.3rem;
  margin: 1rem 0;
  .project-name {
    margin: 0;
    width: 50%;
  }
`;

const StyledCreatedDate = styled.div`
  font-size: 1.2rem;
  color: ${props => props.theme.grey3};
  margin-left: auto;
`;

const StyledLink = styled(NavLink)`
  font-size: 1.2rem;
  position: relative;
  top: 2px;
  color: ${props => props.theme.grey3};
  transition: 0.2s color ease-in-out;
  margin-left: ${props => (props.marginLeft ? props.marginLeft : null)};
  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
    color: ${props => props.theme.grey5};
  }
`;

const ProjectHeader = ({ name, createdDate, isEdit, id }) => {
  return isEdit ? (
    <StyledProjectHeader>
      <Field
        type="text"
        name="name"
        className="project-name"
        component={ReduxFormInput}
        placeholder="Project Name"
        validate={[required]}
      />
      <StyledLink to={`/projects/${id}`} style={{ marginLeft: '2rem' }}>
        Back to Project
      </StyledLink>
      <StyledCreatedDate>Created: {moment(createdDate).format('MMM DD, YYYY')}</StyledCreatedDate>
    </StyledProjectHeader>
  ) : (
    <PageHeader title={name}>
      <StyledLink to="/projects">Back to My Projects</StyledLink>
      <StyledCreatedDate>Created: {moment(createdDate).format('MMM DD, YYYY')}</StyledCreatedDate>
    </PageHeader>
  );
};

ProjectHeader.defaultProps = {
  isEdit: false
};

export default ProjectHeader;
