import React from 'react';
import styled from 'styled-components';

const Error = styled.div`
  color: ${props => props.theme.red};
  font-size: 1.2rem;
  margin: 0.2rem 0 0;
`;

const ErrorText = styled.p`
  margin: 0;
`;

const ReduxFormError = ({ meta: { touched, error } }) => (
  <React.Fragment>
    {touched && error && (
      <Error>
        <ErrorText>{error}</ErrorText>
      </Error>
    )}
  </React.Fragment>
);

export default ReduxFormError;
