import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { login, reducerUtil } from 'base-client';

import CreateProjectModal from './Modal/CreateProjectModal';
import projectData from '../reducerData';
import projectActions from '../actions';

import Tooltip from 'shared/Tooltip';
import Dropdown from 'shared/Dropdown';

const StyledProjectDropdown = styled.div`
  position: relative;
  transition: all 0.1s ease;
  display: flex;
  font-weight: 500;
  .dropdown {
    .visible {
      min-width: 15rem;
    }
    .dropdown-item {
      cursor: pointer;
      padding: 0 1.5rem 0.5rem;
      color: ${props => props.theme.textColor || props.theme.grey3};
      &:hover {
        color: ${props => props.theme.textHoverColor || 'inherit'};
      }
      &.active {
        color: ${props => props.theme.textHoverColor || 'inherit'};
        font-weight: 700;
      }
    }
  }
`;

const DisplayText = styled.div`
  cursor: pointer;
  color: ${props => props.theme.textColor || props.theme.grey3};
  ~ .tooltip {
    bottom: -5.5rem;
    left: -3.5rem;
    z-index: 9999;
    text-align: center;
    max-width: 12.5rem;
  }
  &:hover {
    color: ${props => props.theme.textHoverColor || 'inherit'};
    ~ .tooltip {
      opacity: 1;
    }
  }
`;

const ProjectButton = ({ products, token, activeIndex, dispatch, modalOpen, ...props }) => {
  if (!token)
    return (
      <StyledProjectDropdown>
        <DisplayText>Projects</DisplayText>
        <Tooltip position="bottom">Register to access projects</Tooltip>
      </StyledProjectDropdown>
    );

  return (
    <StyledProjectDropdown>
      <Dropdown text="Projects">
        <div className="dropdown-item" onClick={() => dispatch(projectActions.openModal(products))}>
          Create Project
        </div>
        <Link
          to={'/projects'}
          className={`dropdown-item ${
            activeIndex && activeIndex.startsWith('/projects') ? 'active' : ''
          }`}
        >
          My Projects
        </Link>
      </Dropdown>
      {modalOpen && <CreateProjectModal />}
    </StyledProjectDropdown>
  );
};

const mapStateToProps = state => ({
  token: reducerUtil.getSlice(login.reducerData, login.reducerData.token, state),
  modalOpen: reducerUtil.getSlice(projectData, projectData.modalOpen, state)
});

export default connect(mapStateToProps)(ProjectButton);
