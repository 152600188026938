import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 1.3rem;
  margin-left: 0.8rem;
`;

const Title = styled.h4`
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  margin-bottom: 0.5rem;
  color: #77899e;
  // display: flex;
`;

const FilterSearchForm = styled.form`
  position: relative;
`;

const SideArrow = styled.div`
  margin-left: 1rem;
  align-self: center;
  float: right;
  border-top: 0.15em solid #77899e;
  border-right: 0.15em solid #77899e;
  border-bottom: 0;
  border-left: 0;
  width: 0.75em;
  height: 0.75em;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  transition: all 0.3s ease;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`;

const DownArrow = styled.div`
  margin-left: 1rem;
  align-self: center;
  float: right;
  border-top: 0.15em solid #77899e;
  border-right: 0.15em solid #77899e;
  border-bottom: 0;
  border-left: 0;
  width: 0.75em;
  height: 0.75em;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  transition: all 0.3s ease;
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
`;

const SelectAllElm = styled.button`
  cursor: pointer;
  color: #0a7be4;
  text-transform: uppercase;
  font-size: 1.4rem;
  background: transparent;
  border: none;
  font-weight: 400;
  outline: none;
  padding-right: 0;
  padding-left: 0;
`;

const ToggleElm = styled.div`
  cursor: pointer;
  color: rgb(10, 123, 228);
`;

const Facet = styled.div`
  margin-bottom: 0.5rem;
`;

const FacetLabel = styled.label`
  display: flex;
  cursor: pointer;
  input {
    margin-right: 0.7rem;
  }
`;

const defaultShowMore = {
  disabled: true,
  numberShow: 5
};

const defaultSelectAll = {
  disabled: true,
  checkedAll: false
};

const CheckboxFilter = ({
  name,
  attribute,
  facets,
  updateFilter,
  showMore,
  selectedFacets,
  selectAll,
  ...props
}) => {
  const [showAll, setShowAll] = useState(false);
  const [collapseAll, setCollapseAll] = useState(true);
  useEffect(() => {
    if (selectedFacets && selectedFacets.length > 0) {
      setCollapseAll(false);
    }
  }, []);

  if (!facets || (facets && facets.length < 1)) return null;
  
  const { disabled: hideShowMore, numberShow } = { ...defaultShowMore, ...showMore };
  const { disabled: hideSelectAll, checkedAll } = { ...defaultSelectAll, ...selectAll };

  const displayFacets = collapseAll
    ? []
    : facets.length > numberShow && !showAll
    ? facets.slice(0, numberShow)
    : facets;

  return (
    <Container>
      <ToggleElm className="toggleBtn" onClick={() => setCollapseAll(!collapseAll)}>
        <Title>
          {`${name} (${facets.length})`}
          {collapseAll ? <SideArrow /> : <DownArrow />}
        </Title>
      </ToggleElm>
      <FilterSearchForm />
      {!hideSelectAll && (
        <SelectAllElm
          type="button"
          className="toggleBtn"
          onClick={() =>
            updateFilter({
              checked: !checkedAll,
              attribute,
              facets: facets.map(({ name }) => name)
            })
          }
        >
          {checkedAll ? 'Deselect All' : 'Select All'}
        </SelectAllElm>
      )}
      {displayFacets.map(({ checked, name, count }) => (
        <Facet key={name}>
          <FacetLabel>
            <input
              type="checkbox"
              id={name}
              readOnly
              {...{ name, checked }}
              onClick={() => updateFilter({ checked, attribute, facets: [name] })}
            />
            {count ? `${name} (${count})` : name}
          </FacetLabel>
        </Facet>
      ))}
      {!hideShowMore && !collapseAll && facets.length > numberShow && (
        <ToggleElm className="toggleBtn" onClick={() => setShowAll(!showAll)}>
          {showAll ? 'Show less...' : 'Show more...'}
        </ToggleElm>
      )}
    </Container>
  );
};

export default CheckboxFilter;
