import { config, login, api } from 'base-client';

import configMap from './configMap';
import accountActions from '../components/UserProfile/actions';
import analyticsActions from '../components/Analytics/actions';

const start = server => async dispatch => {
  await dispatch(config.actions.start(configMap));

  const host = dispatch(config.actions.getData(configMap.api.name, configMap.api.host.name));
  const gateway = dispatch(config.actions.getData(configMap.api.name, configMap.api.gateway.name));
  dispatch(api.actions.start(host, gateway));

  if (server) return;

  const {
    [configMap.auth.domain.name]: domain,
    [configMap.auth.clientId.name]: clientID,
    [configMap.auth.audience.name]: audience
  } = dispatch(config.actions.getData(configMap.auth.name)) || {};
  dispatch(login.actions.start({ domain, clientID, audience }));

  await dispatch(login.actions.checkLogin());

  const {
    [configMap.analytics.projectId.name]: projectId,
    [configMap.analytics.writeKey.name]: writeKey
  } = dispatch(config.actions.getData(configMap.analytics.name)) || {};
  await dispatch(analyticsActions.start({ projectId, writeKey }));
  dispatch(analyticsActions.userVisitorId());

  await dispatch(accountActions.fillAccount());
};

export default { start };
